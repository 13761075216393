import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { LoadingController, AlertController, NavController } from '@ionic/angular';
import { MessagesService } from './messages.service';
import { throwError, BehaviorSubject } from 'rxjs';
import { StorageService } from './storage/storage.service';
import { AvailableResult, BiometryType, NativeBiometric } from 'capacitor-native-biometric';
import { FlagIonic } from './drupal7/models/flag';
import { LoginCredentials, SystemConnection, User, ViewOptions } from './drupal7/models';
import { LoginCredentialsClass } from './drupal7/models/user';
import { UserService, FlagService, EntityService, ViewService } from './drupal7/drupal7-services.module';
import { Router } from '@angular/router';
import { UserFlagging } from '../models/models';
import { MyCustomApiService } from './drupal7/my_custom_api/my_custom_api.service';
import { PasswordCode, PasswordResetSession } from './drupal7/models/system';

@Injectable({
  providedIn: 'root'
})
export class UserServiceCustom {

  requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  userSession = new BehaviorSubject<SystemConnection>(null);
  biometrics = new BehaviorSubject<any>(null);
  currentSession = this.userSession.asObservable();
  currentBiometrics = this.biometrics.asObservable();

  userFlaggings = new BehaviorSubject<UserFlagging>(null);
  currentUserFlagging = this.userFlaggings.asObservable();

  constructor(
    private http: HttpClient,
    private flag: FlagService,
    private userService: UserService,
    private myCustomApiService: MyCustomApiService,
    private viewService: ViewService,
    private entityService: EntityService,
    private router: Router,
    private messagesService: MessagesService,
    public storageService: StorageService,
    public nav: NavController,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController) {
      this.getSession();
      setTimeout(() => {
        this.getUserFlaggings();
      }, 1200);
     }

    changeSession(session: any) {
      this.userSession.next(session);
    }

    changeBiometrics(biometrics: boolean) {
      this.biometrics.next(biometrics);
    }

    async getSession(opts?: {showLoading?: boolean}) {
      if (opts?.showLoading) {
        this.messagesService.showLoading('Getting Account Details...', false, 1500);
      }

      const session: SystemConnection = await this.storageService.get('session');
      if (session && session.user.uid) {
        const userProfile = await this.userService.getUserById(session.user.uid);
        session.user = Object.assign(session.user, userProfile);
        this.setRoles(session);
        await this.setFlagAccess(session, environment.checkInType.entityType, environment.checkInType.bundle);
      }
      this.storageService.set('session', session);
      this.changeSession(session);
    }

    async getBiometrics() {
      this.changeBiometrics(await this.biometricHasCredentials());
    }

    async getUserFlaggings() {
      return false;
      // const userFlaggings = await this.storageService.get('user_flaggings');
      // if (userFlaggings) {
      //   this.changeUserFlaggings(userFlaggings);
      // } else {
      //   this.refreshUserFlaggings();
      // }
    }

    changeUserFlaggings(flagging: any) {
      this.userFlaggings.next(flagging);
    }

    async refreshUserFlaggings() {
      const session: SystemConnection = await this.storageService.get('session');
      const options: ViewOptions = {
        display_id: 'api_mywcbc_user_flags',
        args: [session.user.uid]
      }
      this.viewService.getView('mywcbc/user/flags', options).then(res => {
        if (res?.results.length) {
          this.storageService.set('user_flaggings', res.results[0]);
          this.changeUserFlaggings(res.results[0]);
        }
      });
    }

    async getAppUser() {
      return await this.storageService.get('appUser').then(async (res) => {
        if (res) {
          const appUser = await this.getAPIUser('appUser', res);
          if (!appUser) {
            return this.login('appUser', environment.appSettings.AppUser, false, '', '');
          } else {
            return res;
          }
        } else {
          return this.login('appUser', environment.appSettings.AppUser, false, '', '');
        }
      }, err => this.login('appUser', environment.appSettings.AppUser, false, '', ''));
    }

    async loginForm(storageKey: string, entityType: string, bundle: string, navigateURL?: string) {
      const alert = await this.alertCtrl.create({
        header: 'Login to continue',
        inputs: [{
            name: 'username',
            type: 'text',
            id: 'username',
            placeholder: 'Username'
        }, {
            name: 'password',
            type: 'password',
            id: 'password',
            placeholder: 'Password'
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'danger',
          },
          {
            text: 'Login',
          }
        ]
      });
      await alert.present();
      return await alert.onDidDismiss().then(async (res: any) => {
        console.log(res);
        if (res.role !== 'cancel') {
          const loginBody = new LoginCredentialsClass(res.data.values.username, res.data.values.password);
          let result: SystemConnection;
          await this.login(storageKey, loginBody, true, entityType, bundle).then(async (data: SystemConnection) => {
            if (navigateURL) {
              this.nav.navigateForward(navigateURL);
            }
            return result = data;
          }, (err) => {
            this.messagesService.presentAlert('Invalid', 'Error logging in. Error Code: ' + err.message);
          });
          return result;
        }
      }, err => throwError(err).toPromise());
    }

    async biometricVerification(){
      const result = await NativeBiometric.isAvailable();
      if(!result.isAvailable) {return;}

      const verified = await NativeBiometric.verifyIdentity({
        reason: environment.biometricReason,
      })
        .then(() => true)
        .catch(() => false);

      if(!verified) {return;}

      const credentials = await NativeBiometric.getCredentials({
        server: environment.appSite,
      });
      const res = {result, credentials};
      return res;
    }

    async login(storageKey: string, body: LoginCredentials,
        showLoading: boolean, entityType: string, bundle: string): Promise<SystemConnection> {
      if (showLoading) {
        this.messagesService.showLoading('Logging in...', false, 1500);
      }
      return this.userService.login(body).then(async (loggedInUser: SystemConnection) => {
        if (loggedInUser) {
          this.setRoles(loggedInUser);
          await this.setFlagAccess(loggedInUser, entityType, bundle);
          this.changeSession(loggedInUser);
          this.storageService.set(storageKey, loggedInUser);
          return loggedInUser;
          // const userFromAPI = await this.getAPIUser(storageKey, loggedInUser);
          // return new SessionWithFields(loggedInUser, userFromAPI);
        }
      });
    }

    async biometricAskCredentials(biometricType: BiometryType) {
      const alert = await this.alertCtrl.create({
        header: 'Verify to continue',
        inputs: [{
            name: 'name',
            type: 'text',
            id: 'name',
            placeholder: 'Username'
        }, {
            name: 'password',
            type: 'password',
            id: 'password',
            placeholder: 'Password'
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'danger',
          },
          {
            text: 'Submit',
            role: 'submit'
          }
        ]
      });
      await alert.present();
      return await alert.onDidDismiss().then(async (res: any) => {
        if (res.role !== 'cancel') {
          return await this.http.post<any>(environment.user.user_authenticate, JSON.stringify(res.data.values), this.requestOptions).toPromise().then(uid => {
            if (!uid) {return;}
            const body = new LoginCredentialsClass(res.data.values.name, res.data.values.password);
            return this.biometricSetCredentials(body, biometricType);
          }).catch(error => {
            console.log(error);
            return error;
          });
        }
      }, err => err);
    }

    biometricSetCredentials(loginCredentials: LoginCredentials, biometricType: BiometryType) {
      return NativeBiometric.setCredentials({
        username: loginCredentials.username,
        password: loginCredentials.password,
        server: environment.appSite,
      }).then(() => {
        // Some Android devices have multiple biometric options
        if (biometricType === BiometryType.MULTIPLE) {
          this.storageService.set(`login_BiometryType_${BiometryType.FINGERPRINT}`, true);
          this.storageService.set(`login_BiometryType_${BiometryType.FACE_AUTHENTICATION}`, true);
          this.storageService.set(`login_BiometryType_${BiometryType.IRIS_AUTHENTICATION}`, true);
        } else {
          this.storageService.set('login_BiometryType_' + biometricType, true);
        }
        this.changeBiometrics(true);
      }, err => err);
    }

    async biometricHasCredentials() {
      return await NativeBiometric.getCredentials({
        server: environment.appSite,
      }).then(res => true, err => false);
    }

    async biometricGetType() {
      const result: AvailableResult = await NativeBiometric.isAvailable().then(res => res).catch(err => err);
      if(!result.isAvailable) {return;}
      console.log(result);
      return result.biometryType;
    }

    async biometricDeleteCredentials() {
      return NativeBiometric.deleteCredentials({
        server: environment.appSite,
      }).then(() => {
        this.changeBiometrics(false);
        return true;
      }, () => false);
    }

    async getAPIUser(storageKey: string, session: SystemConnection) {
      if (session) {
        return await this.userService.getUserById(session.user.uid).then(async (res: any) => {
          if (res.status === 401) {
            if (storageKey === 'appUser') {
              return this.login(storageKey, environment.appSettings.AppUser, false, '', '');
            } else {
              return throwError(res).toPromise();
            }
          } else {
            session.user = await Object.assign(session.user, res);
            this.storageService.set(storageKey, session);
            if (storageKey === 'session') {
              this.changeSession(session);
            }
            return session;
          }
        }, (err: any) => {
          return err;
        }).catch(err => throwError(err).toPromise());
      } else {
        return throwError({status: 400, message: 'No session found'}).toPromise();
      }
    }

    setRoles(session: SystemConnection) {
      session.user.role_types = {};
      Object.keys(environment.user.roleTypes).map(key => {
        Object.values(session.user.roles).map(role => session.user.role_types[key] = environment.user.roleTypes[key].indexOf(role) !== -1 ? true : false);
        return session;
      });
      return session;
    }

    setFlagTypes(flags: FlagIonic[]) {
      const res: any = {};
      Object.keys(environment.flag.flagTypes).map(key => {
        res[key] = [];
        Object.values(flags).map(flag => {
          if (environment.flag.flagTypes[key].indexOf(flag.name) !== -1) {
            res[key].push(flag);
          }
        });
        return res;
      });
      this.storageService.set('flags_by_type', res);
      return res;
    }

    setFlagsDisabled(flags: FlagIonic[]) {
      Object.keys(flags).map(key => {
        if(key === 'checked_in') {
          flags[key].disabled = [{name: 'delegate_paid', value: false}, {name: 'not_attending', value: true}];
        }
        if(key === 'not_attending') {
          flags[key].disabled = [{name: 'checked_in', value: true}];
        }
        if (key === 'delegate_paid') {
          flags[key].disabled = [{name: 'not_attending', value: true}, {name: 'checked_in', value: true}];
        }
        if (environment.flag.flagTypes.disabledIfPaid.indexOf(key) !== -1) {
          flags[key].disabled = [{name: 'delegate_paid', value: true}];
        }
      });
      return flags;
    }

    async setFlagAccess(session: SystemConnection, entityType: string, bundle: string, storageKey?: string) {
      const flagAccess = await this.flag.getAllFlags();
      session.user.flag_access = {flag: {}, unflag: {}};
      const flagsArray = [];
      const userFlagsArray = [];
      flagAccess.map((flag) => {
        if (bundle) {
          if (flag.types.indexOf(bundle) !== -1) {
            this.setFlagsDisabled(flagAccess);
            flagsArray.push(flag);
          }
        } else {
          flagsArray.push(flag);
        }
        if (flag.entity_type === 'user') {
          userFlagsArray.push(flag);
        }
        Object.keys(session.user.roles).map(rid => {
          session.user.flag_access.flag[flag.name] = flag.roles.flag[rid] ? true : false;
          session.user.flag_access.unflag[flag.name] = flag.roles.unflag[rid] ? true : false;
          return session;
        });
      });
      this.setFlagTypes(flagAccess);
      if (bundle) {
        this.storageService.set('flags_'+bundle, flagsArray);
      } else {
        this.storageService.set('flags', flagsArray);
      }
      this.storageService.set('flags_user', userFlagsArray);
      if (storageKey) {
        this.storageService.set(storageKey, session);
        this.changeSession(session);
      }
      return {flags: flagsArray, session};
    }

    register(body: any) {
      this.messagesService.showLoading('Registering your account...', false, 500);
      return this.entityService.createEntity('user/register', body).then(data => {
          return data;
        }, error => {
          console.error('Error Message: ', error);
          const message = error.statusText.substring(17).replace('The name', 'The username')
          .replace(' Have you forgotten your password?', '');
          this.messagesService.presentAlert('Error', message);
        });
    }

    async delete(uid: number, session: any) {
      this.messagesService.presentToast('Deleting the account for ' + session.user.name, 2500);
      return this.userService.deleteUser(uid).then(res => {
        return res;
      });
    }

    async cancel(uid: number, session: any) {
      this.messagesService.presentToast('Deleting the account for ' + session.user.name, 2500);
      return this.userService.cancelUser(uid).then(res => {
        this.messagesService.presentAlert('Account removed for ' + session.user.name, 'Your account has been cancelled.');
        this.logout();
        return res;
      });
    }

    updatePassword(uid: number, field: any, token: string, session: SystemConnection) {
      return this.userService.userPasswordResetToken(uid, field, token, session).then(res => {
        this.messagesService.presentToast('Password updated', 2000);
        return res;
      }, error => {
        console.log(error);
        this.messagesService.presentAlert('Error', 'There was a problem. Error Code: ' + error.message);
      });
    }

    passwordResetNew(body: any): Promise<PasswordCode> {
      return this.myCustomApiService.requestNewPassword(body).then(res => {
        return res;
      }, error => {
        this.messagesService.presentAlert('Error', 'There was a problem. Error Code: ' + error.message);
        return error;
      });
    }
    
    confirmPasswordReset(body: any): Promise<PasswordResetSession> {
      return this.myCustomApiService.userPasswordReset(body).then(res => {
        return res;
      }, error => {
        this.messagesService.presentAlert('Error', 'There was a problem. Error Code: ' + error.message);
        return error;
      });
    }

    async logout() {
      this.messagesService.showLoading('Logging out', false, 2500);
      return this.userService.logout().then(res => {
        console.log(res);
        this.storageService.clear();
        this.changeSession(null);
        this.router.navigateByUrl('/login', {replaceUrl: true});
        return res;
      }, err => {
        this.storageService.clear();
        this.router.navigateByUrl('/login', {replaceUrl: true});
        this.changeSession(null);
      });
    }







/** OLD METHODS */
// oldUpdateUserNormal(uid: string | number, field: any, session: any) {
//   const options = this.auth.setupRequestOptions('csrf', session);
//   return this.http.put(environment.user.baseURL + uid + '.json', JSON.stringify(field), options)
//   .pipe(map(async () => {
//     this.message.presentToast('User updated', 2000);
//     return await this.getAPIUser('session', session);
//   }, error => {
//     console.log(error);
//     this.message.presentAlert('Error', 'There was a problem. Error Code: ' + error.message);
//   }));
// }

// oldUpdateUser(uid: string | number, field: any, session: any) {
//   const options = this.auth.setupRequestOptions('csrf', session);
//   return this.http.put(environment.user.entity_user + uid + '.json', JSON.stringify(field), options)
//   .pipe(map(res => {
//     this.message.presentToast('User updated', 2000);
//     return res;
//   }, error => {
//     console.log(error);
//     this.message.presentAlert('Error', 'There was a problem. Error Code: ' + error.message);
//   }));
// }

  }

