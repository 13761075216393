import { Component, Input, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { DrupalFormControlObject, EntityService, FormFromJSON, SystemConnection } from 'src/app/services/drupal7/public_api';
import { FormsService } from 'src/app/services/drupal-forms/forms.service';
import { ConditionalFields, DrupalEntityBundleInfoWrapper, FormFieldOptions } from 'src/app/services/drupal7/models/form-controls';
import { format, parseISO } from 'date-fns';
import { FormFieldComponent } from '../form-field/form-field.component';
@Component({
  selector: 'app-form-inline',
  templateUrl: 'form-inline.component.html',
  styleUrls: ['form-inline.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, FormFieldComponent]
})
export class FormInlineComponent implements OnInit {

  private fb = inject(FormBuilder);
  private modalCtrl = inject(ModalController);
  private formsService = inject(FormsService);
  private loadingCtrl = inject(LoadingController);
  private entityService = inject(EntityService);
  private toastCtrl = inject(ToastController);

  @Input() entityType: string;
  @Input() entityBundle: string;
  @Input() viewMode: string = 'form';
  @Input() fieldOpts: FormFieldOptions;
  @Input() loadedEntity: any;
  @Input() formTitle: string;
  @Input() userSession: SystemConnection;
  @Input() showSegment: boolean = false;
  @Input() formSubmitLabel: string = 'Submit';
  @Input() loadedEntityReference: any = {};
  @Input() formCallback: 'getExternalForm' | 'getInternalForm' = 'getInternalForm';

  form: FormGroup;
  bundleInfo: DrupalEntityBundleInfoWrapper;
  formFields: DrupalFormControlObject[] = [];
  nestedFieldGroups: DrupalFormControlObject[] = [];
  conditionalFields: ConditionalFields = {};
  activeIndex: number;
  isFieldGroup = false;

  administrativeAreas: any;

  minDate = '1900';
  maxDate = (new Date().getFullYear() + 3).toString();

  loading: HTMLIonLoadingElement;

  constructor() {}

  get errorControl() {
    return this.form.controls;
  }

  ngOnInit() {
    this.getForm(this.entityType, this.entityBundle, this.viewMode, this.fieldOpts, this.formCallback);
  }

  async getForm(entityType: string, entityBundle: string, viewMode: string = 'form', fieldOpts: FormFieldOptions, formCallback: string) {
    await this.showLoading('Loading form...');

    const res = await this.formsService[formCallback](this.userSession, entityType, entityBundle, viewMode, fieldOpts)
    if (res) {
      console.log(res);
      this.activeIndex = res.activeIndex;
      this.formFields = res.form_fields;
      this.form = res.formGroup;
      this.nestedFieldGroups = res.nestedFieldGroups;
      this.entityBundle = res.entityBundle;
      this.bundleInfo = res.bundle_info;
      this.entityType = res.entityType;
      if (!this.formSubmitLabel) {
        this.formSubmitLabel = res.formSubmitLabel;
      }
      this.formTitle = res.bundle_info?.bundle_info?.name ? res.bundle_info?.bundle_info?.name : res.bundle_info?.bundle_info?.label;
      this.administrativeAreas = res.administrativeAreas;
      this.conditionalFields = res.conditional_fields;
      this.isFieldGroup = res.isFieldGroup;
      
      if (this.loadedEntity) {
        this.formsService.loadEntity(this.loadedEntity, this.form, this.formFields, this.nestedFieldGroups, this.administrativeAreas);
      }
    }
    await this.dismissLoading();
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(()=>resolve(), ms));
  }

  async showLoading(message: string) {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        message,
        backdropDismiss: false,
      });
      await this.loading.present();
    }
  }

  async dismissLoading() {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }

  goToFieldGroup(i: number) {
    console.log(this.form.value);
    this.formsService.goToFieldGroup(i, this.form, this.formFields);
  }

  async submit() {

    if (this.loadedEntity) {
      const body = this.formsService.prepareFormValues(this.formFields, this.form);

    } else {
      console.log('no entity loaded');
      const body = this.formsService.prepareFormValues(this.formFields, this.form);

      body.author = this.userSession.user.uid;
      body.type = this.entityBundle;
      body.created = Math.round((new Date()).getTime() / 1000);
      if (this.entityType === 'entityform_type') {
        this.entityType = 'entityform';
      }

      if (this.loadedEntityReference) {
        Object.keys(this.loadedEntityReference).map(k => {
          body[k]= this.loadedEntityReference[k];
        });
      }

      this.create(body);
    }
  }

  async create(body: any) {
    await this.showLoading('Submitting form...');

    this.entityService.createEntity(this.entityType, body).then(async res => {
      await this.dismissLoading();
      if (res) {
        const toast = await this.toastCtrl.create({
          message: 'Success',
          duration: 1200,
          color: 'primary'
        });
        await toast.present();
        this.modalCtrl.dismiss(res, 'success');
      }
    });
  }

  resetForm() {
    this.form = this.fb.group({});
    this.formFields = null;
  }

  delete() {
    console.log(this.form);
  }

  cancelModal() {
    this.modalCtrl.dismiss();
  }

}
