import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { DrupalConstants } from '../application/drupal-constants';
import { SystemConnection } from '../models';


/**
 * facebook oauth service
 *
 * @see https://www.drupal.org/project/services_fboauth
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class FacebookOAuthService extends MainService {
  override entityType = 'fboauth';

  /**
   * if there is already a token in the browser cookies and it is not expired it returns
   * this will fetch a new token before trying to connect
   *
   * @param accessToken string of the recieved access token
   * @return observable of the connect method
   * the subscription data is an object of SystemConnection interface
   */
  async connect(accessToken: string): Promise<SystemConnection> {

    if (this.isConnectionExpired()) {
      this.removeSession();
    }

    const body = {
      access_token: accessToken
    };

    if (!DrupalConstants.Connection || !DrupalConstants.Connection.token) {
      return this.getToken().then(token => this.post(body, 'connect').then(connection => this.getToken().then(newToken => {
            connection.token = newToken;
            this.saveSessionToken(connection);
            return connection;
          })));
    }

    return this.post(body, 'connect').then(connection => {
      this.saveSessionToken(connection);
      return connection;
    });
  }
}
