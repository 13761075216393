// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ion-full-width.addressfield,
.ion-full-width.array,
.ion-full-width.enddate_get,
.ion-full-width.date {
  margin-top: 1.5rem;
}

div.radio ion-list ion-item {
  margin: 0;
}

ion-segment-button.false,
ion-card.false {
  display: none;
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h,
.label-floating.sc-ion-label-ios-h,
.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h,
.item-input.sc-ion-label-md-h,
.item-input .sc-ion-label-md-h {
  max-width: -moz-fit-content;
  max-width: fit-content;
  overflow: initial;
}

.table {
  --ion-grid-column-padding: 10px;
  border-collapse: collapse;
  border-style: hidden;
}
.table ion-row:first-child ion-col {
  background-color: var(--ion-color-primary);
  color: white;
  font-weight: bold;
}
.table ion-row ion-col {
  background-color: var(--ion-color-light);
}`, "",{"version":3,"sources":["webpack://./src/app/form/form.component.scss"],"names":[],"mappings":"AAAA;;;;EAII,kBAAA;AACJ;;AAEA;EACI,SAAA;AACJ;;AAEA;;EAEI,aAAA;AACJ;;AAEA;;;;;;;EAOI,2BAAA;EAAA,sBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,+BAAA;EACA,yBAAA;EACA,oBAAA;AACJ;AACI;EACE,0CAAA;EACA,YAAA;EACA,iBAAA;AACN;AACI;EACI,wCAAA;AACR","sourcesContent":[".ion-full-width.addressfield,\n.ion-full-width.array,\n.ion-full-width.enddate_get,\n.ion-full-width.date {\n    margin-top: 1.5rem;\n}\n\ndiv.radio ion-list ion-item {\n    margin: 0;\n}\n\nion-segment-button.false,\nion-card.false {\n    display: none;\n}\n\n.item.sc-ion-label-ios-h,\n.item .sc-ion-label-ios-h,\n.label-floating.sc-ion-label-ios-h,\n.item.sc-ion-label-md-h,\n.item .sc-ion-label-md-h,\n.item-input.sc-ion-label-md-h,\n.item-input .sc-ion-label-md-h {\n    max-width: fit-content;\n    overflow: initial;\n}\n\n.table {\n    --ion-grid-column-padding: 10px;\n    border-collapse: collapse;\n    border-style: hidden;\n    \n    ion-row:first-child ion-col {\n      background-color: var(--ion-color-primary);\n      color: white;\n      font-weight: bold;\n    }\n    ion-row ion-col {\n        background-color: var(--ion-color-light);\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
