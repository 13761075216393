import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PaymentService, Payment, PaymentMethod, CommerceProduct, PaymentMethodOptions } from '../../services/payment.service';
import USStates from '../../../assets/address/us_states.json';
import PaymentMethods from '../../../assets/commerce/payment_methods.json';
import { AlertController, IonInput, IonicModule, ModalController, NavParams, Platform } from '@ionic/angular';
import { DrupalFormControlObject, Options } from '../../services/entity.service';
import { MessagesService } from '../../services/messages.service';
import { APICommerceOrder, CommerceOrder, CommerceOrderStatus, CommercePaymentTransactionStatus, CommerceService } from '../../services/commerce.service';
import { ElavonResponse, ElavonService, ElavonTransaction } from '../../services/elavon.service';
import { CommonModule, formatCurrency } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormsService } from '../../services/forms.service';
import AdministrativeArea from '../../../assets/address/administrative_area.json';
import { AuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment.prod';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { CardSwipeService } from '../../services/cardswipe.service';
import { FlagItem } from '../../services/menu.service';
import { FlagService } from '../../services/flag.service';
import { StorageService } from '../../services/storage/storage.service';
import { SystemConnection, UserService } from '../../services/drupal7/public_api';
import { UserServiceCustom } from '../../services/user.service';

@Component({
  selector: 'app-payment',
  templateUrl: 'payment.page.html',
  styleUrls: ['payment.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class PaymentPage implements OnInit {

  @Input() order: APICommerceOrder = null;
  @Input() appUser: object;
  @Input() requestOptions: any;

  session: SystemConnection;
  paymentMethods: Array<PaymentMethod>;
  billingControls: FormGroup;
  paymentControls: FormGroup;
  billingForm: Array<DrupalFormControlObject>;
  paymentJSON: Array<DrupalFormControlObject>;
  maxDate = (new Date().getFullYear() + 7).toString();
  minDate = (new Date().getFullYear()).toString();

  states: Array<Options>;
  administrativeArea = AdministrativeArea;
  dynamicAdministrativeArea: any;

  payment: Payment;
  products: Array<CommerceProduct>;
  showProducts: boolean;
  swipe_buffer = '';
  flags: Array<FlagItem>;
  userFlags: Array<FlagItem>;
  flagsByType: Array<FlagItem>;
  orderStatus = CommerceOrderStatus;


  constructor(
    private message: MessagesService,
    private elavon: ElavonService,
    private cardSwipe: CardSwipeService,
    private flag: FlagService,
    private platform: Platform,
    private barcodeScanner: BarcodeScanner,
    private auth: AuthService,
    private user: UserServiceCustom,
    private userService: UserService,
    private commerce: CommerceService,
    private paymentService: PaymentService,
    private forms: FormsService,
    private alertCtrl: AlertController,
    private modalController: ModalController,
    public storage: StorageService
    ) {
    this.states = USStates;
    this.dynamicAdministrativeArea = USStates;
    this.paymentMethods = this.paymentService.getActivePaymentMethods('access');
    this.products = this.commerce.getActiveProducts();
    this.payment = new Payment();
    this.getBillingForm();
    this.getPaymentForm();
    console.log('listening for card swipe');
    document.addEventListener('keypress', (evt: KeyboardEvent) => {
      this.swipe(evt);
    });
  }

  async ngOnInit() {
    this.user.currentSession.subscribe(session => this.session = session);
    console.log(this.session);
    console.log(this.requestOptions);
    console.log(this.order);
    await this.getBillingForm();
    await this.getPaymentForm();
    this.flags = await this.storage.get('flags_'+environment.checkInType.bundle);
    this.userFlags = await this.storage.get('flags_user');
    this.flagsByType = await this.storage.get('flags_by_type').then(res => res.priceFlags);
    console.log(this.flagsByType);
    this.buildOrder(this.order);
  }

  checkFlagDisabled(flag: any, entity: any) {
    return this.flag.checkFlagDisabled(flag, entity);
  }

  async getBillingForm() {
    const reactiveForm = await this.forms.getForm(this.session, 'commerce', 'billing');
    console.log(reactiveForm);
    this.billingControls = reactiveForm.formGroup;
    this.billingForm = reactiveForm.formFields;
  }

  async getPaymentForm() {
    const reactiveForm = await this.forms.getForm(this.session, 'commerce', 'elavon_direct');
    this.paymentControls = reactiveForm.formGroup;
    this.paymentJSON = reactiveForm.formFields;
  }

  async swipe(event) {
    // add global variable to check listener
    const res = await this.cardSwipe.cardSwipe(event);
    if (res) {
      const dateFormat = `${res.cardExpYear}-${res.cardExpMonth}-01T00:00:00-07:00`;
      this.paymentControls.controls.exp_month.setValue(dateFormat);
      this.paymentControls.controls.exp_year.setValue(dateFormat);
      // Show the expiration date
      this.showDatePicked(this.paymentJSON[0].fields[1]);
      this.showDatePicked(this.paymentJSON[0].fields[2]);

      this.paymentControls.controls.card_number.setValue(res.cardNum);
      this.billingControls.controls.commerce_customer_address['controls'].first_name.setValue(res.cardFirst);
      this.billingControls.controls.commerce_customer_address['controls'].last_name.setValue(res.cardLast);
    }
  }

  segmentChanged(paymentType) {
    this.payment.payment_method = paymentType.value;
    for (const paymentMethod of this.paymentMethods) {
      paymentMethod.fill = paymentMethod.value === paymentType.value ? 'solid' : 'outline';
    }
    console.log(this.payment);
  }

  updateAdministrativeAreas(country, options, addressField) {
    options = this.administrativeArea.filter(administrativeArea => administrativeArea.key === country.detail.value);
    if (options[0].value) {
      let newOptions = {};
      newOptions = Object.keys(options[0].value)
      .map(key => {
        const value = options[0].value[key];
        newOptions = {key, value};
        return newOptions;
      });
      this.dynamicAdministrativeArea = newOptions;
    } else {
      this.dynamicAdministrativeArea = null;
    }
  }

  showDatePicked(field) {
      this.paymentControls.get(field.field_name)['formattedValue'] = this.forms.formatDate(this.paymentControls, field);
  }

  async switchRoles(order) {
    if (order.order_owner[0].hasRegularRole) {
      await this.message.showLoading('Switching order LBC church member pricing', true, 2100);
      const updatedUser = await this.userService.updateUser({mail: order.order_owner[0].mail, roles: [environment.user.churchMemberRole]});
      console.log(updatedUser);
      if (updatedUser) {
        return await this.reloadOrder();
      }
    }
    if (order.order_owner[0].hasChurchRole) {
      await this.message.showLoading('Switching order to regular delegate pricing ', true, 2100);
      const updatedUser = await this.userService.updateUser({mail: order.order_owner[0].mail, roles: [environment.user.regularUserRole]});
      console.log(updatedUser);
      if (updatedUser) {
        return await this.reloadOrder();
      }

    }
    if (!order.order_owner[0].hasRegularRole && !order.order_owner[0].hasChurchRole) {
      this.message.presentAlert('Access Denied', 'The role for this order owner cannot be modified.');
    }
  }

  async addProductToCart(product) {
    this.commerce.addProductToCart(product, this.requestOptions, this.order).subscribe(async lineItem => {
      await this.reloadOrder();
    });
    // let order = {};
    // // const options = this.auth.setupRequestOptions('csrf', this.session);
    // this.commerce.getCommerceCart(this.requestOptions).subscribe(async res => {
    //   order = res;
    //   this.commerce.addProductToCart(product, this.requestOptions, this.order).subscribe(async lineItem => {
    //     await this.reloadOrder();
    //   });
    // });
  }

  async deleteLineItem(lineItem) {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Remove',
      message: 'Are you sure you want to remove ' + lineItem.line_item_title + ' from Order ' + this.order.order_id + '? This cannot be undone.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'DELETE',
          cssClass: 'primary',
          handler: async () => {
            await this.commerce.deleteLineItem(lineItem.line_item_id, this.requestOptions).toPromise();
            await this.reloadOrder();
          }
        }
      ]

    });
    await alert.present();
  }

  overrideAmountConfirm() {
    console.log(this.payment);
    this.paymentService.overrideAmountConfirm(this.payment, this.order);
  }

  async reloadOrder() {
    this.message.showLoading('Refreshing the order... ', false, 2000);
    this.payment = new Payment();
    this.order = await this.auth.get(environment.commerce.commerce_order.api_registration_fetch + this.order.order_id, this.requestOptions).toPromise().then(res => res.orders[0]);
    return this.buildOrder(this.order);
  }

  showPayment() {
    console.log(this.payment);
  }

  buildOrder(res: APICommerceOrder) {
      // Setup the line item total
      if (res.commerce_line_items?.length) {
        let lineItemsTotal = 0;
        for (const lineItem of res.commerce_line_items) {
            lineItemsTotal += lineItem.commerce_total;
        }
        res.commerce_line_items_amount = lineItemsTotal;
        res.commerce_line_items_amount_formatted = formatCurrency(parseFloat(res.commerce_line_items_amount.toFixed(2)) / 100, 'en-US', '$');
      }

      // Setup the billing info
      if (res.commerce_customer_profiles?.length) {
        res.commerce_customer_profiles = this.forms.convertObjectToArray(res.commerce_customer_profiles);
        console.log(this.billingForm);
        const customerAddressFields = this.billingForm[0].fields[3].fields;
        for (const field of customerAddressFields) {
          const val = res.commerce_customer_profiles[0][field.field_name];
          this.billingControls.controls.commerce_customer_address['controls'][field.field_name].setValue(val);
        }
      }
      // Setup the payment transactions
      if (res.commerce_payment_transactions?.length) {
        let transactionsTotal = 0;
        for (const transaction of res.commerce_payment_transactions) {
          if (transaction.status === CommercePaymentTransactionStatus.success) {
            const amount = Number(transaction.amount.toString().replace(/[^0-9.-]+/g,''));
            transactionsTotal += amount;
          }
        }
        res.commerce_order_payments_amount = +transactionsTotal.toFixed(2).replace('.', '');
        res.commerce_order_payments_amount_formatted = formatCurrency(parseFloat(res.commerce_order_payments_amount.toFixed(2)) / 100, 'en-US', '$');
      }

      if (res.order_owner?.length) {
        for (const owner of res.order_owner) {
          if (owner.rid) {
            const roles = owner.rid.split(',');
            Object.keys(roles).map(key => {
              owner.hasRegularRole = environment.user.roleTypes.RegularRoles.indexOf(roles[key]) !== -1 ? true : false;
            });
            Object.keys(roles).map(key => {
              owner.hasChurchRole = environment.user.roleTypes.ChurchRoles.indexOf(roles[key]) !== -1 ? true : false;
            });
          }
        }
      }

    if (res.commerce_order_total !== 0) {
      this.payment.amount = res.balance;
    } else {
      this.payment.amount = 0;
    }
    this.payment.uid = this.session.user.uid;
    this.payment.order_id = res.order_id;
    // this.payment.receipt_email = res.mail;

    this.order = res;
    console.log(this.order);
    return this.order;
  }

  submitBilling() {
    // Add additional properties to the body
    console.log(this.billingControls.value);
    const firstName = this.billingControls.value.commerce_customer_address.first_name;
    const lastName = this.billingControls.value.commerce_customer_address.last_name;
    this.billingControls.value.commerce_customer_address.name_line = firstName !== null && lastName !== null ? firstName + ' ' + lastName : null;
    if (this.order.commerce_customer_billing === '' || this.order.commerce_customer_billing === null) {
      // Create new customer profile if none exists on the order
      this.commerce.createCommerceCustomerProfile(this.billingControls.value, this.requestOptions, 'commerce_customer_profile', 'billing').subscribe(customerProfile => {
        const order_id = this.payment.order_id;
        const body = {
          // mail: this.session.user.mail,
          commerce_customer_billing: customerProfile.profile_id
        };
        // Attach customer profile to the order
        this.commerce.updateCommerceOrder(order_id, body, this.requestOptions).subscribe(commerceOrder => {});
      });
    } else {
      this.commerce.updateCommerceCustomerProfile(this.order, this.billingControls.value, this.requestOptions).subscribe(customerProfile => customerProfile);
    }
  }

  sendPayment() {
    console.log(this.payment);
    console.log(this.session);
    // console.log(this.appUser);
    console.log(this.order);
    console.log(this.billingControls.value);
    console.log(this.paymentControls.value);

    if (this.payment.payment_method === PaymentMethodOptions.elavon_direct) {

      if (this.billingControls.valid) {
        this.submitBilling();
      }

      this.elavon.elavonPaymentTransactionProcess(this.billingControls.value.commerce_customer_address, this.paymentControls.value, this.payment, this.session, this.session).subscribe(async (res: ElavonTransaction) => {
        console.log(res);
        const errorCode = res.elavon_response.xml.errorCode;
        const status = res.elavon_response.status;
        if (!status) {
           return this.message.presentAlert('Error', res.elavon_response.msg);
        }
        if (errorCode) {
          const errorName = res.elavon_response.xml.errorName;
          const errorMessage = res.elavon_response.xml.errorMessage;
          this.message.presentAlert(errorName, errorMessage);
        }
        const Amount = +res.elavon_response.xml.ssl_amount.replace('.', '');
        const formattedAmount = formatCurrency(Amount / 100, 'en-US', '$');
        console.log(formattedAmount);
        const order = await this.reloadOrder();
        if (Amount === this.order.commerce_order_better_balance) {
          this.modalController.dismiss({
            payment: res,
            order
          }, 'submit');
        } else {
          this.message.presentAlert('Payment Received', 'A payment in the amount of ' + formattedAmount +
          ' has been added to order ' + this.order.order_id + '. It looks like this order still has a balance of ' +
          this.order.commerce_order_better_balance_formatted + '. Please complete payment for this order.');
        }
      });
    }
    if (
      this.payment.payment_method === PaymentMethodOptions.commerce_payment_example ||
      this.payment.payment_method === PaymentMethodOptions.cash ||
      this.payment.payment_method === PaymentMethodOptions.slc_app) {
      this.payment.status = CommercePaymentTransactionStatus.success;
      this.paymentService.createDrupalPayment(this.payment, this.session).subscribe(async (res) => {
        console.log(res);
        const Amount = formatCurrency(res.amount / 100, 'en-US', '$');
        if (res.amount === this.order.commerce_order_better_balance) {
          const order = await this.reloadOrder();
          this.modalController.dismiss({
            order
          }, 'submit');
        } else {
          await this.reloadOrder();
          this.message.presentAlert('Payment Received', 'A payment in the amount of ' + Amount +
          ' has been added to order ' + this.order.order_id + '. It looks like this order still has a balance of ' +
          this.order.commerce_order_better_balance_formatted + '. Please complete payment for this order.');
        }
      });
    }

  }

  cancelModal() {
    this.modalController.dismiss({submit: false}, 'cancel');
  }

}
