export interface PostalAddress {
    country: string; // List(text)
    organisation_name?: string;
    name_line?: string;
    first_name?: string;
    last_name?: string;
    thoroughfare: string; // Address
    premise?: string; // Address 2
    sub_premise?: string;
    locality: string; // City
    dependent_locality?: string;
    administrative_area: string; // List(text) state
    sub_administrative_area?: string;
    postal_code: string;
    data?: string;
  }

  export class PostalAddress {
    country: string; // List(text)
    organisation_name?: string;
    name_line?: string;
    first_name?: string;
    last_name?: string;
    thoroughfare: string; // Address
    premise?: string; // Address 2
    sub_premise?: string;
    locality: string; // City
    dependent_locality?: string;
    administrative_area: string; // List(text) state
    sub_administrative_area?: string;
    postal_code: string;
    data?: string;
  
    constructor() {
      this.country = 'US';
      this.first_name = '';
      this.last_name = '';
      this.thoroughfare = '';
      this.premise = '';
      this.locality = '';
      this.administrative_area = '';
      this.postal_code = '';
    }
  }
