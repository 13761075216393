// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.danger {
  color: var(--ion-color-danger);
}

span.success {
  color: var(--ion-color-success);
}

div.passMatch {
  min-height: 23px;
}`, "",{"version":3,"sources":["webpack://./src/app/account/password-reset/password-reset.page.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;AACJ;;AAEA;EACI,+BAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":["span.danger {\n    color: var(--ion-color-danger);\n}\n\nspan.success {\n    color: var(--ion-color-success);\n}\n\ndiv.passMatch {\n    min-height: 23px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
