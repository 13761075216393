import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { Storage } from '@ionic/storage-angular';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { SystemConnection } from '../drupal7/public_api';


export interface StoredRequest {
  url: string;
  type: string;
  data: any;
  time: number;
  reqID: string;
  id: string;
  entityType: string;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage,
    private http: HttpClient) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  set(key: string, value: any) {
    this._storage.set(key, value);
  }

  getEach() {
    this._storage?.forEach((value, key, index) => {
      this.get(key).then(val => {
        console.log(val);
      });
    });
  }

  async get(key: string) {
    return await this.storage?.get(key);
  }

  getAll(key: string): Observable<any> {
    return from(this._storage.get(key)).pipe(
      map(storedOperations => {
        const storedObj = JSON.parse(storedOperations);
        if (storedObj && storedObj.length > 0) {
          console.log(storedObj);
          return storedObj;
        } else {
          console.log('no local data for type: ' + key);
          return of(false).toPromise();
        }
      })
    );
  }

  async storeNotification(storageKey: string, notification: PushNotificationSchema) {
    let newEntity = [];
    await this._storage.get(storageKey).then((storedObj: PushNotificationSchema[]) => {
      if (storedObj) {
        const index = storedObj.map((o: PushNotificationSchema) => o.id).indexOf(notification.id);
        if (index !== -1) {
          storedObj[index] = notification;
        } else {
          storedObj.unshift(notification);
        }
      } else {
          storedObj = [notification];
        }
      this._storage.set(storageKey, storedObj);
      return newEntity = storedObj;
    });
    return newEntity;
  }

  async deleteNotification(storageKey: string, notification: PushNotificationSchema) {
    const notifications: PushNotificationSchema[] = await this._storage.get(storageKey);
    console.log('the stored notifications.', notifications);
    const storedObj = notifications.filter(obj => obj.id !== notification.id);
    return await this._storage.set(storageKey, storedObj);
  }

  async subscribeTopic(storageKey: string, topic: string) {
    let newEntity = [];
    await this._storage.get(storageKey).then(storedObj => {
      if (storedObj) {
        const index = storedObj.map((o) => o.id).indexOf(topic);
        if (index !== -1) {
          storedObj[index] = topic;
        } else {
          storedObj.push(topic);
        }
      } else {
          storedObj = [topic];
        }
      this._storage.set(storageKey, storedObj);
      return newEntity = storedObj;
    });
    return newEntity;
  }

  async unsubscribeTopic(storageKey: string, topic: string) {
    const entities: Array<string> = await this._storage.get(storageKey);
    const storedObj = entities.filter(k => k !== topic);
    return this._storage.set(storageKey, storedObj);
  }

  async storeEntity(storageKey: string, entity: any) {
    let newEntity = [];
    await this._storage.get(storageKey).then(storedObj => {
      if (storedObj) {
        const index = storedObj.map((o) => o.nid).indexOf(entity.nid);
        if (index !== -1) {
          storedObj[index] = entity;
        } else {
          storedObj.push(entity);
        }
      } else {
          storedObj = [entity];
        }
      this._storage.set(storageKey, storedObj);
      return newEntity = storedObj;
    });
    return newEntity;
  }

  async storeWebformSubmission(storageKey: string, entity: any) {
    let newEntity = [];
    await this._storage.get(storageKey).then(storedObj => {
      if (storedObj) {
        const index = storedObj.map((o) => o.id).indexOf(entity.id);
        if (index !== -1) {
          storedObj[index] = entity;
        } else {
          storedObj.push(entity);
        }
      } else {
          storedObj = [entity];
        }
      this._storage.set(storageKey, storedObj);
      return newEntity = storedObj;
    });
    return newEntity;
  }


  async clear() {
    this._storage.forEach((v, k, i) => {
      if (!k.startsWith('login_')) {
        this._storage.remove(k);
      }
    });
  }

  async clearBiometrics() {
    this._storage.forEach((v, k, i) => {
      if (k.startsWith('login_BiometryType')) {
        this._storage.remove(k);
      }
    });
  }

  async remove(storageKey: string) {
    return this._storage.remove(storageKey).then(res => res).catch(() => false);
  }

  async loginServiceWorker(): Promise<Observable<SystemConnection>> {
    const requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return await this.http.post<any>(environment.user.login, JSON.stringify(environment.appSettings.AppUser),
    requestOptions).toPromise().then(async loggedInUser => {
      const currentRoles = Object.keys(loggedInUser.user.roles).map(key => loggedInUser.user.roles[key]);
      const newRoles = [];
      for (const role of currentRoles) {newRoles.push(role);}
      loggedInUser.user.user_roles = newRoles;
      await this._storage.set('serviceWorker', loggedInUser);
      return loggedInUser;
    });
  }


}



