import { Routes } from '@angular/router';
import { TabsPage } from './system/tabs/tabs.page';
import { EventsPage } from './events/events.page';
import { MediaPage } from './media/media.page';
import { MediaPlayerComponent } from './media-player/media-player.component';
import { TeamMembersPage } from './info-guide/team-members/team-members.page';
import { ClassPage } from './info-guide/classes/class/class.page';
import { ClassesPage } from './info-guide/classes/classes.page';
import { PrayerListPage } from './home/prayer-list/prayer-list.page';
import { EventPage } from './events/event/event.page';
import { CheckoutPage } from './commerce/checkout/checkout.page';
import { PaymentMethodsPage } from './commerce/payment-methods/payment-methods.page';
import { AccountPage } from './account/account.page';
import { ConfirmationPage } from './commerce/confirmation/confirmation.page';
import { DailyDevotionsPage } from './home/daily-devotions/daily-devotions.page';
import { DailyDevotionalPage } from './home/daily-devotions/daily-devotional/daily-devotional.page';
import { SupportPage } from './support/support.page';
import { CheckoutFormPage } from './commerce/checkout-form/checkout-form.page';
import { CartPage } from './commerce/cart/cart.page';
import { NotificationCenterPage } from './home/notification-center/notification-center.page';
import { HomePage } from './home/home.page';
import { InfoGuidePage } from './info-guide/info-guide.page';
import { OrdersPage } from './commerce/orders/orders.page';
import { OrderDetailsPage } from './commerce/order-details/order-details.page';
import { inject } from '@angular/core';
import { AuthGuard } from './auth.guard';
import { LoginPage } from './account/login/login.page';
import { PasswordResetPage } from './account/password-reset/password-reset.page';
import { RegistrationPage } from './account/registration/registration.page';

export const routes: Routes = [
  {path: '', component: TabsPage,
    children: [
      {path: 'home', children: [
        {path: '', component: HomePage}, 
        {path: 'notification-center', component: NotificationCenterPage},
        {path: 'prayer-list', component: PrayerListPage},
        {path: 'daily-devotions', component: DailyDevotionsPage},
        {path: 'daily-devotions/:id', component: DailyDevotionalPage},
      ]},
      {path: 'info-guide', children: [
        {path: '', component: InfoGuidePage},
        {path: 'classes/:id', component: ClassesPage},
        {path: 'classes/:id/:id', component: ClassPage},
        {path: 'team-members', component: TeamMembersPage},
      ]},
      {path: 'media', component: MediaPage, children: []},
      {path: 'media/:id', component: MediaPlayerComponent},
      {path: 'media-player', component: MediaPlayerComponent},
      {path: 'account', children: [
        {path: '', component: AccountPage},
        // {path: 'edit', component: FormInlineComponent},
        {path: 'orders', component: OrdersPage},
        {path: 'orders/:id', component: OrderDetailsPage},
      ]},
      {path: 'events', component: EventsPage},
      {path: 'events/:id', component: EventPage},
      {path: '',
        redirectTo: 'home', pathMatch: 'full'
      }
    ]},
  {path: 'checkout', component: CheckoutPage},
  {path: 'payment-methods', component: PaymentMethodsPage},
  {path: 'account', component: AccountPage},
  {path: 'confirmation', component: ConfirmationPage},
  // {path: 'login', component: RegisterPage},
  // {path: 'register', component: RegisterPage},
  // {path: 'password', component: RegisterPage},
  // {path: 'password_reset_confirm', component: RegisterPage},
  {path: 'support', component: SupportPage},
  {path: 'checkout-form', component: CheckoutFormPage},
  {path: 'cart', component: CartPage},

  { path: 'login', canActivate: [() => inject(AuthGuard).init()], component: LoginPage},
  { path: 'password-reset', canActivate: [() => inject(AuthGuard).isNotLoggedIn()], component: PasswordResetPage},
  { path: 'register', canActivate: [() => inject(AuthGuard).canRegister()], component: RegistrationPage},

];
