import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Navigation, Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.page.html',
  styleUrls: ['./confirmation.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class ConfirmationPage implements OnInit {

  @Input() event: any;
  @Input() webformSubmissionData: any;
  @Input() orderDetails: any;
  @Input() webform: any;

  constructor(
    public router: Router) {
      const nav: Navigation = this.router.getCurrentNavigation();
      if (nav?.extras && nav.extras.state) {
        this.event = nav.extras.state.event;
        this.webformSubmissionData = nav.extras.state.webformSubmissionData;
        this.webform = nav.extras.state.webform;
        this.orderDetails = nav.extras.state.orderDetails;
      }
     }

  ngOnInit() {
  }

  navigateToPage(url: string) {
    this.router.navigateByUrl(url, {replaceUrl: true, skipLocationChange: true});
  }

}
