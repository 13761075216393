import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment.prod';


@Component({
  selector: 'app-logo-header',
  styles: [`.logo-header {
    display: flex;
    gap: 10px;
    margin: 5px 0;
}`],
  template: `
  <div class="logo-header">
  <picture class="ion-margin-start">
      <source srcset="../../../assets/imgs/lbc-logo-loader-logo-sm.svg" media="(prefers-color-scheme:dark)">
      <img src="../../../assets/imgs/lbc-logo-loader-logo-sm.svg" style="width:3rem;">
  </picture>
  <p>${environment.siteName}</p>
</div>`,
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class LogoHeaderComponent {}