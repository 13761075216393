/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
const baseURL = 'https://www.lancasterbaptist.org/';

export const environment = {
  production: true,
  siteName: 'Lancaster Baptist Church',
  biometricReason: 'Login to Lancaster Baptist Church',
  appSite: 'https://app.lancasterbaptist.org',
  version: '2.2.0',
  firebase: {
    apiKey: 'AIzaSyAFF_Bzs3Dxk5Zc_8UfhsLLUBSn6eSOT_0',
    serverKey: 'AAAAaBBZFqg:APA91bGkl6F0UYHKwoiAMhzFvkgYlQAEhWO2B3R5TlHhHlCqbosuDY8rRyXK86l4yVEpbIwGiILIJTv40TiKqBRuHO-jborhhsua0_pmS746sQ8vjOObduK1wpRd50HRIMNpmbDjtpJp',
    authDomain: 'lbc-app-2018.firebaseapp.com',
    databaseURL: 'https://lbc-app-2018.firebaseio.com',
    projectId: 'lbc-app-2018',
    storageBucket: 'lbc-app-2018.appspot.com',
    messagingSenderId: '446950872744'
  },
  ApiURL: baseURL + 'api/',
  FormSchemaURL: baseURL + 'api/ionic/',
  ServicesSchema: baseURL + 'services_schema/',
  field_collection_item: {
    create: baseURL + 'api/entity_field_collection_item.json',
    get: baseURL + 'api/entity_field_collection_item/'
  },
  commerce: {
    payment_methods: {
      church_app: {
        instance_id: 'church_app|commerce_payment_church_app',
        id: 'church_app'
      },
      elavon: {
        api_url: baseURL + 'api/commerce_elavon/process',
        production_url: 'https://api.convergepay.com/VirtualMerchant/processxml.do',
        test_url: 'https://api.demo.convergepay.com/VirtualMerchantDemo/processxml.do',
        ssl_test_mode: 'false',
        ssl_transaction_type: 'ccsale'
      },
      elavonProduction: {
        instance_id: 'elavon_direct|commerce_payment_elavon_direct',
        settings: {
          accountid: '706651',
          userid: 'LBCwebsite',
          pin: 'PYKSNFWABCFYK5AU74L33OSB1T304HVCW5EOSEPBQ6K8X6KB5JMJIY3RSOSRXRM7',
          txn_mode: 'production',
          log: {
            request: 'request',
            response: 'response'
          },
        },
      },
      elavonDemo: {
        instance_id: 'elavon_direct|commerce_payment_elavon_direct',
        settings: {
          accountid: '010996',
          userid: 'webpage',
          pin: 'RKDSJY17GBZI69ZWL6DPLBQO3O78M4DUVFIFM8Q4BDSH3F5TAVLEJYVV8L053F1B',
          txn_mode: 'test',
          log: {
            request: 'request',
            response: 'response'
          },
        },
      },
      stripe: {
        merchantID: 'merchant.org.lancasterbaptist.app',
        stripePKKey: 'stripe_publishable_key',
        stripeSKKey: 'stripe'
      },
    },
    commerce_line_item: {
      entity_fetch: baseURL + 'api/entity_commerce_line_item/',
      create: baseURL + 'api/line-item.json',
      fetch: baseURL + 'api/line-item/',
    },
    commerce_customer_profile: {
      entityType: 'commerce_customer_profile',
      entityBundle: 'billing',
      create: baseURL + 'api/entity_commerce_customer_profile.json',
      fetch: baseURL + 'api/entity_commerce_customer_profile/',
    },
    cart: baseURL + 'api/cart.json',
    commerce_payment_transaction: {
      create: baseURL + 'api/entity_commerce_payment_transaction.json',
    },
    commerce_order: {
      order: baseURL + 'api/order/',
      fetch: baseURL + 'api/entity_commerce_order/',
      api_fetch: baseURL + 'api/commerce-order/',
      api_registration_fetch: baseURL + 'api/registration/commerce-order/',
      api_fetch_by_user: baseURL + 'api/user/',
      api_fetch_by_email: baseURL + 'api/entity_commerce_order.json?parameters[mail]=',
    },
    commerce_product: {
      get: baseURL + 'api/entity_commerce_product/'
    },
  },
  webform: {
    create: baseURL + 'api/submission.json',
    get: baseURL + 'api/webform/',
  },
  user: {
    baseURL: baseURL + 'api/user/',
    indexUsers: baseURL + 'api/user.json',
    login: baseURL + 'api/user/login.json',
    logout: baseURL + 'api/user/logout.json',
    register: baseURL + 'api/user/register.json',
    token: baseURL + 'api/user/token.json',
    services_token: baseURL + 'services/session/token',
    password_reset: baseURL + 'api/user/request_new_password.json',
    password_reset_new: baseURL + 'api/my_custom_api/request_new_password',
    password_reset_confirm: baseURL + 'api/my_custom_api/token',
    user_authenticate: baseURL + 'api/my_custom_api/user_authenticate',
    get: baseURL + 'api/entity_user/',
    entity_user: baseURL + 'api/entity_user/',
    entity_user_register: baseURL + 'api/entity_user/register.json',
    findUser: baseURL + 'api/users?name=',
    findUserByEmail: baseURL + 'api/entity_user.json?parameters[mail]=',
    churchMemberRole: '15',
    regularUserRole: '2',
    roleTypes: {
      UnverifiedRoles: ['unverified user'],
      ChurchRoles: ['church member'],
      RegularRoles: ['regular user'],
      BadgesRoles: ['badge admin'],
      AuthorizedRoles: ['app', 'administrator', 'staff', 'conference admin', 'volunteer', 'registration', 'check-in', 'webform-viewer'],
      AdminRoles: ['app', 'administrator', 'staff', 'conference admin'],
    },
  },
  node: {
    baseURL: baseURL + 'node/',
    apiBaseURL: baseURL + 'api/node/',
    create: baseURL + 'api/entity_node.json',
    index: baseURL + 'api/entity_node.json?parameters',
    query: baseURL + 'api/entity_node.json?',
    get: baseURL + 'api/entity_node/',
    relationships: {
      referenced_delegate: '/commerce_line_items_referenced_delegate.json'
    },
    delegate: baseURL + 'api/entity_node.json?parameters[register_last_name]=%lar%&options[parameters_op][register_last_name]=like'
  },
  file: {
    create: baseURL + 'api/file.json',
    get: baseURL + 'api/file/',
    attach: baseURL + 'api/file_attach.json'
  },
  entityform: {
    create: baseURL + 'api/entity_entityform.json',
    get: baseURL + 'api/entity_entityform/'
  },
  flag: {
    flagURL: baseURL + 'api/flag/flag.json',
    unflag: baseURL + 'api/unflag/',
    permissions: baseURL + 'api/ionic/flags/null.json',
    flagTypes: {
      badgeFlags: ['printed', 'badge_printed'],
      confirmFlags: ['delegate_paid', 'not_attending'],
      disabledIfPaid: ['wcbc_alumni_discount', 'spouse_of_alumni', 'special_group_discount', 'charge_dorm_fee'],
      checkInOutFlags: ['conference_check_in'],
      selfCheckInOutFlags: ['conference_check_in'],
      priceFlags: ['ten_dollars_off', 'twenty_dollars_off',
      'forty_dollars_off', 'ten_percent_off', 'fifty_percent_off',
      'free_registration_delegate', 'free_dorms_delegate', 'free_childcare',
      'free_activity', 'charge_dorm_fee', 'delegate_sponsored', 'wcbc_alumni_discount',
      'spouse_of_alumni', 'early_registration', 'early_registration_alumni',
      'special_group_discount', 'church_planter', 'free_registration']
    }
  },
  mimemail: {
    apiURL: baseURL + 'api/send-email.json',
    key: 'slc',
    to: 'slcsupport@lancasterbaptist.org',
    cc: '',
    bcc: '',
    from_name: '',
    from_mail: '',
    reply_to: '',
    subject: '',
    body: '',
    plaintext: '',
    attachments: '',
    langcode: ''
  },
  views: {
    events: baseURL + 'api/events',
    attendeeEvents: baseURL + 'api/attendee-events',
    webformSubmissions: baseURL + 'api/webform_submissions/',
    speakers: baseURL + 'api/speakers',
    campus: baseURL + 'api/campus',
    childcare: baseURL + 'childcare-locations',
    analytics: baseURL + 'api/analytics/',
    analyticsDelegates: baseURL + 'api/analytics/delegates',
    analyticsChurches: baseURL + 'api/analytics/churches',
    delegate: baseURL + 'api/delegate/',
    church: baseURL + 'api/churches',
    nestedChurch: baseURL + 'api/nested-church/',
    user_delegate: baseURL + 'api/delegates-by-user/',
    badgeCue: baseURL + 'api/badge-print-cue',
    checkInURL: baseURL + 'api/registration',
    printBadges: baseURL + 'api/registration/badges',
    showcaseSlots: baseURL + 'api/slots',
    showcaseSlot: baseURL + 'api/slot/',
    findShowcaseSlot: baseURL + 'api/slots/find?slot_name=',
  },
  entity_get: {
    user: baseURL + 'api/entity_user.json?parameters[uid]=',
    nodes: baseURL + 'api/entity_node.json?parameters[uid]=',
    commerce_orders: baseURL + 'api/entity_commerce_order.json?parameters[uid]=',
    commerce_customer_profiles: baseURL + 'api/entity_commerce_customer_profile.json?parameters[uid]=',
    commerce_payments: baseURL + 'api/entity_commerce_payment_transaction.json?parameters[order_id]=',
  },
  appSettings: {
    AppUser: {username: 'app', password: 'password' },
    AppUserUID: 3327,
    linkDevicePass: 426598,
  },
  checkInType: {
    entityType: 'user',
    bundle: 'user_d7'
  },
  churchURLS: {
    SermonUrl: baseURL + 'api/sermons/',
    Speakers: baseURL + 'api/speakers',
    SermonSeries: baseURL + 'api/sermon-series',
    SermonsInSeries: baseURL + 'api/sermon-series/sermons/',
    ServiceScheduleUrl: baseURL + 'api/service-schedule',
    AllClasses: baseURL + 'api/connection-group-categories-nested/',
    ConnectionGroupCategoryUrl: baseURL + 'api/connection-group-categories',
    ConnectionGroupUrl: baseURL + 'api/connection-group',
    ConnectionGroupsUrl: baseURL + 'api/connection-groups',
    ConnectionGroupsOptions: baseURL + 'api/connection-groups-options',
    ClassTimesUrl:  baseURL + 'api/class-times/',
    ChildrenUrl: baseURL + 'api/children/',
    YouthUrl: baseURL + 'api/youth/',
    EventUrl: baseURL + 'api/events',
    StaffUrl: baseURL + 'api/staff',
    WebformEventsUrl:  baseURL + 'api/app-events',
    PromotedEvents: baseURL + 'api/app-events/promoted',
    Support: {
      URL: baseURL + 'api/app-event/18946',
      nid: '18946'
    },
    PrayerListUrl: baseURL + 'api/prayer-list-category',
    MissionaryUrl: baseURL + 'api/missionary-of-the-week',
    FirebaseTopics: baseURL + 'api/topics',
    DevotionalUrl: 'https://devo.paulchappell.com/api/devotion',
    BibleReadingUrl:  'https://devo.paulchappell.com/api/bible',
    ProverbsReadingUrl:  'https://devo.paulchappell.com/api/proverbs',
    ConnectionGroupsOptionsUrl: 'https://account.lancasterbaptist.org/connection_group_rest',
    NotificationsUrl: 'https://account.lancasterbaptist.org/api/notifications',
    PrayerRequestsUrl: 'https://account.lancasterbaptist.org/api/prayer-requests',
  },
  badgeTemplate: 'https://slconference.com/sites/default/files/2022-delegate-badge-template.jpg',
  arrayFields: ['childcare_information', 'author', 'church', 'spouse_childcare', 'seat_reservation']
};

