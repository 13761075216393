/* eslint-disable @typescript-eslint/naming-convention */
import { formatCurrency } from '@angular/common';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { MessagesService } from './messages.service';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { catchError, map } from 'rxjs/operators';
import { APICommerceOrder, CommerceOrder, CommercePaymentTransactionStatus } from './commerce.service';
import PaymentMethods from '../../assets/commerce/payment_methods.json';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {


  constructor(
    private auth: AuthService,
    public http: HttpClient,
    private alertCtrl: AlertController,
    private message: MessagesService,
  ) {
  }

  getActivePaymentMethods(prop) {
    const activePaymentMethods = [];
    for (const paymentMethod of PaymentMethods) {
      if (paymentMethod[prop]) {
        activePaymentMethods.push(paymentMethod);
      }
    }
    return activePaymentMethods;
  }
  /*
    See commerce_payment_entity_property_info()
    in sites/all/modules/commerce/modules/payment/commerce_payment.info.inc in Drupal 7
    Make sure to set the setter callback property otherwise the API won't support writing to the fields and will return an error.
    Property to add: 'setter callback' => 'entity_property_verbatim_set',
    Objects to add this property: 'remote_id', 'amount', 'currency_code', 'message'
    After adding this property, you'll need to flush your caches on the site and/or run cron and run updates for the changes to take effect.
  */
  createDrupalPayment(payment: Payment, appUser: any, showLoading: boolean = true): Observable<any> {
    // Make sure the amount is properly formatted
    payment.amount = parseInt(payment.amount.toString().replace('.', ''), 10);
    if (showLoading) {
      this.message.showLoading('Submitting your payment...', true, 3000);
    }
    console.log(payment);
    const options = this.auth.setupRequestOptions('csrf', appUser);
    return this.http.post(environment.commerce.commerce_payment_transaction.create, payment, options).pipe(
    map(ChargeData => {
      console.log(ChargeData);
      return ChargeData;
    }),
    catchError(err => {
        console.log('error: ', err);
        if (err.status === 200) {
          return err;
        } else {
          return throwError(err);
        }
    }));
  }

  async overrideAmountConfirm(payment, order) {
    const alert = await this.alertCtrl.create({
      header: 'Override Payment',
      message: 'Enter the password to continue.',
      backdropDismiss: false,
      inputs: [
        {
          name: 'password',
          type: 'password',
          id: 'password',
          placeholder: 'Password'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'OK',
          handler: form => {
            if (+form.password === environment.appSettings.linkDevicePass) {
              this.overrideAmount(payment, order);
            } else {
              this.message.presentAlert('Invalid password', 'The password you have entered is incorrect');
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async overrideAmount(payment: Payment, order: APICommerceOrder) {
    const alert = await this.alertCtrl.create({
      header: 'Enter new payment amount.',
      backdropDismiss: false,
      inputs: [
        {
          name: 'amount',
          type: 'number',
          id: 'amount',
          placeholder: 'Amount',
          value: payment.amount
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'OK',
          handler: form => {
            const newAmount = parseFloat(form.amount);
            const formattedAmount = formatCurrency(newAmount / 100, 'en-US', '$');
            payment.amount = newAmount;
            order.commerce_order_total = newAmount;
            order.balance = newAmount;
            order.balance_formatted = formattedAmount;
            order.commerce_order_total_formatted = formattedAmount;
          }
        }
      ]
    });
    await alert.present();
  }


}
export interface CommerceProduct {
  sku: string;
  id: number;
  title: string;
  commerce_price: number;
  commerce_total_formatted: string;
  status: boolean;
}

export enum PaymentMethodOptions {
  elavon_direct = 'elavon_direct',
  commerce_payment_example = 'commerce_payment_example',
  cash = 'cash',
  credit_card_machine = 'credit_card_machine',
  slc_app = 'slc_app'
}
export interface PaymentMethod {
  type: string;
  value: string;
  label: string;
  icon: string;
  checked: boolean;
  fill: string;
  access: boolean;
  available_offline: boolean;
  available_online: boolean;
}

export class Payment {
  payment_method: string;
  status: CommercePaymentTransactionStatus;
  uid: number | string;
  order_id: number | string;
  amount: number;
  formattedAmount?: string;
  currency_code: string;
  message: string;
  remote_id: string;
  remote_status: string;
  created: string;

  constructor() {
    this.payment_method = 'elavon_direct';
    this.status = CommercePaymentTransactionStatus.pending;
    this.uid = null;
    this.order_id = null;
    this.amount = 0;
    this.currency_code = 'USD';
    this.message = '';
    this.remote_id = 'slc_app';
    this.remote_status = '';
  }
}

export class AltPayment {
  payload: Array<string>;
  check_number: string;
  receipt_email: string;
  constructor(payload, checkNumber, receiptEmail) {
    this.payload = payload;
    this.check_number = checkNumber;
    this.receipt_email = receiptEmail;
  }
}
export class CreditCard {
  card_number: string;
  exp_month: string;
  exp_year: string;
  exp_date: string;
  code: string;

  constructor() {
    this.card_number = '';
    this.exp_month = '';
    this.exp_year = '';
    this.exp_date = '';
    this.code = '';
  }
}
