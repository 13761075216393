import { CommonModule, formatCurrency } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ElavonService, ElavonTransaction } from '../../services/elavon.service';
import { EventsService } from '../../services/events.service';
import { StorageService } from '../../services/storage/storage.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UserServiceCustom } from '../../services/user.service';
import { SystemConnection } from '../../services/drupal7/public_api';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.page.html',
  styleUrls: ['./cart.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
  ],
})
export class CartPage implements OnInit {

  @Input() events: any;
  @Input() webformSubmissionData: any;
  @Input() webform: any;
  orderDetails: any;
  customer: any;
  eventCost: any;
  appUser: any;

  constructor(
    public auth: AuthService,
    public user: UserServiceCustom,
    private router: Router,
    public storage: StorageService,
    public eventService: EventsService,
    public elavon: ElavonService,
    public elavonService: ElavonService) {

    const nav: Navigation = this.router.getCurrentNavigation();
    if (nav?.extras && nav.extras.state) {
      this.events = nav.extras.state.events;
      this.webformSubmissionData = nav.extras.state.webformSubmission;
      this.webform = nav.extras.state.webform;
      this.customer = nav.extras.state.customer;
      this.eventCost = formatCurrency(parseFloat(this.webformSubmissionData.event_cost) / 100, 'en-US', '$');
    }
  }

  async ngOnInit() {
    this.appUser = await this.user.getAppUser();
  }

  navigateToPage(url: string, extras: any = null) {
    this.router.navigate([url], {state: {
      events: this.events,
      webform: this.webform,
      webformSubmission: this.webformSubmissionData,
      customer: this.customer,
      result: extras
    }});
  }

checkoutElavaon(drupalTransaction: any, session: SystemConnection) {
  const cardDetails: any = {};
  const payment: any = {};
  this.elavon.elavonPaymentTransactionProcess(drupalTransaction, cardDetails, payment, session, session)
  .subscribe(async (transaction: ElavonTransaction) => transaction);
}

}
