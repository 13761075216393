/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { formatCurrency } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import { Observable, throwError } from 'rxjs';
import { MessagesService } from './messages.service';
import { Payment } from './payment.service';
import CommerceProducts from '../../assets/commerce/commerce_products.json';
import { AuthService } from './auth.service';
import { StorageService } from './storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class CommerceService {

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    public message: MessagesService,
    public storage: StorageService) { }

    /*
     See commerce_services_cart_create() line 54 in
     in sites/all/modules/commerce_services/resources/cart.inc in Drupal 7
     The module is designed for the current API user to create a new order for themselves.
     However, the appUser needs to create an order for another user who won't be logged in.
     We override this by checking the role of the API user to allow them to
     create a new order for another user.
     Example code to replace: $uid = array_key_exists(16, $user->roles) ? $data['uid'] : $user->uid;

     The body in the request is an optional parameter.
     The format for the "body" parameter is {uid: number}.
    */
    createCommerceCart(options, body?): Observable<any> {
      return this.http.post(environment.commerce.cart, body, options).pipe(
      map(data => data,
      error => {
        console.log('Error Message: ', error);
      }));
    }

    getActiveProducts() {
      const activeProducts = [];
      for (const product of CommerceProducts) {
        if (product.status) {
          activeProducts.push(product);
        }
      }
      return activeProducts;
    }

    getCommerceCart(options): Observable<any> {
      return this.http.get<Array<[]>>(environment.commerce.cart, options).pipe(
      map(data => {
        const cart = Object.keys(data).map(orderID => data[orderID]);
        if (cart.length) {
          for (const transaction of cart[0].commerce_payment_transactions) {
            transaction.created = new Date(Number(transaction.created) * 1000).toLocaleString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true
            });
            transaction.amount = formatCurrency(parseFloat(transaction.amount) / 100, 'en-US', '$');
          }
        }
        return cart[0];
      },
      error => {
        console.log('Error Message: ', error);
      }));
    }

    getCommerceOrder(orderId, options): Observable<any> {
      return this.http.get(environment.commerce.commerce_order.fetch + orderId, options).pipe(
      map(data => data,
      error => {
          console.log('error: ', error);
      }));
    }

    async findOrder(searchTerm, options, session) {
      const order: any = await this.http.get(environment.commerce.commerce_order.api_fetch + searchTerm, options).toPromise();
      const res = this.auth.cleanObject(order.orders[0]);
      console.log(res);
      if (res.order_owner.rid) {
        const roles = res.order_owner.rid.split(',');
        console.log(roles);
        Object.keys(roles).map(key => {
          res.order_owner.hasRegularRole = environment.user.roleTypes.RegularRoles.indexOf(roles[key]) !== -1 ? true : false;
        });
        Object.keys(roles).map(key => {
          res.order_owner.hasChurchRole = environment.user.roleTypes.ChurchRoles.indexOf(roles[key]) !== -1 ? true : false;
        });
      }
      console.log(res);
      return res;
    }

    addProductToCart(product, options, order): Observable<any> {
      this.message.showLoading('Adding the ' + product.title + ' to order ' + order.order_id, true, 3000);
      const body = {
        order_id: order.order_id,
        commerce_product: product.id,
        type: 'product',
        quantity: '1'
      };
      return this.http.post(environment.commerce.commerce_line_item.create, body, options).pipe(
      map(data => data,
      error => {
        console.log('Error Message: ', error);
      }));
    }

    deleteLineItem(id, options) {
      this.message.showLoading('Removing from cart...', true, 2000);
      return this.http.delete(environment.commerce.commerce_line_item.fetch + id, options).pipe(
        map(data => data,
        error => {
          console.log('error: ', error);
      }));
    }

    createCommerceCustomerProfile(profile: CommerceCustomerProfile, options, entityType, entityBundle): Observable<any> {
      console.log(profile);
      return this.http.post(environment.commerce.commerce_customer_profile.create, profile, options).pipe(
        map(data => {
          console.log(data);
          return data;
        }),
        catchError(err => {
          console.error('Error Message: ', err);
          return throwError(err);
        })
      );
    }

    updateCommerceCustomerProfile(order, body, options): Observable<any> {
      return this.http.put(environment.commerce.commerce_customer_profile.fetch +
        order.commerce_customer_billing, body, options).pipe(
        map(data => {
          console.log(data);
          return data;
        },
        error => {
            console.log('error: ', error);
        }));
    }

    updateCommerceOrder(orderId, body, options): Observable<any> {
      return this.http.put(environment.commerce.commerce_order.fetch + orderId, body, options).pipe(
      map(data => data,
      error => {
          console.log('error: ', error);
      }));
    }

}

export enum CommerceOrderStatus {
  canceled = 'Canceled',
  cart = 'Cart',
  checkout_checkout = 'Checkout: Checkout',
  checkout_review = 'Checkout: Review',
  checkout_payment = 'Checkout: Payment',
  checkout_complete = 'Checkout: Complete',
  pending = 'Pending',
  processing = 'Processing',
  invoiced = 'Invoiced'
}

export enum CommercePaymentTransactionStatus {
  canceled = 'canceled',
  pending = 'pending',
  success = 'success',
  failure = 'failure'
}

export enum CommerceComponentsState {
  base_price = 'base_price',
  discount = 'discount',
  fee = 'fee'
}

export class CommerceComponents {
  name: CommerceComponentsState;
  price: {
    amount: string | number;
    currency_code: string;
    data: {
      components: Array<object>;
    };
  };
  included: boolean;

  constructor(amount) {
    this.name = CommerceComponentsState.base_price;
    this.price = {
      amount,
      currency_code: 'USD',
      data: {components: []}
    };
    this.included = true;

  }
}

export class CommercePrice {
  amount: number;
  currency_code: string;
  data: {
    components: Array<CommerceComponents>;
  };

  constructor(amount) {
    this.amount = parseFloat(amount);
    this.currency_code = 'USD';
    this.data = {
      components: [new CommerceComponents(amount)]
    };
  }
}

export class CommerceLineItemDataContext {
  context: {
    button_text: string;
    product_ids: Array<number>;
    add_to_cart_combine: number;
    view: {
      view_name: string;
      display_name: string;
      human_name: string;
      page: string;
    };
  };

  constructor() {
    this.context = {
      button_text: '',
      product_ids: [],
      add_to_cart_combine: 0,
      view: {
        view_name: '',
        display_name: '',
        human_name: '',
        page: ''
      }
    };
  }
}

export class CommerceLineItemEntity {
  line_item_id: string;
  order_id: string;
  type: string;
  line_item_label: string;
  quantity: string;
  created: string;
  changed: string;
  data: CommerceLineItemDataContext;
  commerce_unit_price: CommercePrice;
  commerce_total: CommercePrice;
  commerce_product: string;
  commerce_display_path: string;
  rdf_mapping: Array<string>;
  commerce_pricing_attributes: string;
  line_item_title: string;
  commerce_unit_price_formatted: string;
  commerce_total_formatted: string;

  constructor(orderID, type, productID, quantity, sku, amount, commerceTotalFormatted, title) {
    this.line_item_id = Math.floor((Math.random() * 10000) + 1).toString();
    this.order_id = orderID;
    this.type = type;
    this.line_item_label = sku;
    this.quantity = quantity;
    this.created = new Date().valueOf().toString().substring(0, 10);
    this.changed = new Date().valueOf().toString().substring(0, 10);
    this.data = new CommerceLineItemDataContext();
    this.commerce_unit_price = new CommercePrice(amount);
    this.commerce_total = new CommercePrice(amount);
    this.commerce_product = productID;
    this.commerce_display_path = '';
    this.rdf_mapping = [];
    this.commerce_pricing_attributes = '';
    this.line_item_title = title;
    this.commerce_unit_price_formatted = commerceTotalFormatted;
    this.commerce_total_formatted = commerceTotalFormatted;
  }
}

export class CommerceCustomerAddress {
  country: string; // List(text)
  organisation_name?: string;
  name_line?: string;
  first_name?: string;
  last_name?: string;
  thoroughfare: string; // Address
  premise?: string; // Address 2
  sub_premise?: string;
  locality: string; // City
  dependent_locality?: string;
  administrative_area: string; // List(text) state
  sub_administrative_area?: string;
  postal_code: string;
  data?: string;

  constructor() {
    this.country = 'US';
    this.first_name = '';
    this.last_name = '';
    this.thoroughfare = '';
    this.premise = '';
    this.locality = '';
    this.administrative_area = '';
    this.postal_code = '';
  }
}


export class CommerceCustomerProfile {
  revision_id: string;
  revision_uid: string;
  status: string;
  log: string;
  revision_timestamp: string;
  data: boolean;
  profile_id: string;
  type: string;
  uid: string;
  created: string;
  changed: string;
  commerce_customer_address: CommerceCustomerAddress;
  rdf_mapping: Array<string>;

  constructor(uid) {
    // const profileID = Math.floor((Math.random() * 10000) +1).toString();
    this.status = '1';
    this.type = 'billing';
    this.uid = uid;
    this.created = new Date().valueOf().toString().substring(0, 10);
    this.changed = new Date().valueOf().toString().substring(0, 10);
    this.commerce_customer_address = new CommerceCustomerAddress();
  }
}

export class CommercePaymentTransaction extends Payment {
  transaction_id: string;
  revision_id: string;
  instance_id: string;
  message_variables: string;
  payload?: string;
  created: string;
  changed: string;
  data?: string;

  constructor() {
    super();
    const transactionID = Math.floor((Math.random() * 10000) +1).toString();
    this.transaction_id = transactionID;
    this.revision_id = transactionID;
    this.instance_id = ''; // Instance ID: ex. 'method|commerce_payment_method'
    this.message_variables = '';
    this.payload = null;
    this.created = new Date().valueOf().toString().substring(0, 10);
    this.changed = new Date().valueOf().toString().substring(0, 10);
    this.data = null;
  }
}

export class CommerceOrderData {
  last_cart_refresh: string;
  payment_method: string;
  commerce_payment_order_paid_in_full_invoked: string;

  constructor() {
    this.last_cart_refresh = new Date().valueOf().toString().substring(0, 10);
    this.payment_method = ''; // Instance ID: ex. 'method|commerce_payment_method'
    this.commerce_payment_order_paid_in_full_invoked = null;
  }
}

export class CommerceOrder {
  order_number: string;
  revision_id: string;
  revision_uid: string;
  mail: string;
  status: CommerceOrderStatus;
  placed: string;
  log: string;
  revision_timestamp: number;
  revision_hostname: string;
  data: CommerceOrderData;
  order_id: string;
  type: string;
  uid: number;
  created: number;
  changed: number;
  hostname: string;
  commerce_line_items: Array<number>;
  commerce_order_total: CommercePrice;
  commerce_customer_billing: string;
  field_commerce_billy_cancel_date: string;
  field_commerce_billy_i_date: string;
  commerce_discounts: Array<object>;
  commerce_coupons: Array<object>;
  rdf_mapping: Array<string>;
  commerce_order_total_formatted: string;
  commerce_line_items_entities: Array<CommerceLineItemEntity>;
  commerce_customer_billing_entities: Array<CommerceCustomerProfile>;
  commerce_payment_transactions: Array<CommercePaymentTransaction>;
  commerce_order_balance: number;
  commerce_order_payments_amount: number;
  commerce_order_payments_amount_formatted: string;
  commerce_order_balance_formatted: string;


  constructor(user) {
    const orderID = Math.floor((Math.random() * 10000) +1).toString();
    this.order_number = orderID;
    this.revision_id = '';
    this.revision_uid = user.uid;
    this.mail = user.mail;
    this.status = CommerceOrderStatus.cart;
    this.placed = '';
    this.log = 'Generated as a new shopping cart order from the app.';
    this.revision_timestamp = parseInt(new Date().valueOf().toString().substring(0, 10), 10);
    this.revision_hostname = '';
    this.data = new CommerceOrderData();
    this.order_id = orderID;
    this.type = 'commerce_order';
    this.uid = user.uid;
    this.created = parseInt(new Date().valueOf().toString().substring(0, 10), 10);
    this.changed = null;
    this.hostname = '';
    this.commerce_line_items = [];
    this.commerce_order_total = new CommercePrice(0);
    this.commerce_customer_billing = null;
    this.field_commerce_billy_cancel_date = null;
    this.field_commerce_billy_i_date = null;
    this.commerce_discounts = [];
    this.commerce_coupons = [];
    this.rdf_mapping = [];
    this.commerce_order_total_formatted = '$0.00';
    this.commerce_line_items_entities = [];
    this.commerce_customer_billing_entities = [];
    this.commerce_payment_transactions = [];
    this.commerce_order_balance = 0;
    this.commerce_order_payments_amount = 0;
    this.commerce_order_payments_amount_formatted = '$0.00';
    this.commerce_order_balance_formatted = '$0.00';
  }

}

export class APICommerceOrder {
  order_id: number;
  order_number: number;
  uid: number;
  mail: string;
  commerce_order_total: number;
  commerce_order_total_formatted: string;
  balance: number;
  balance_formatted: string;
  commerce_order_better_balance: number;
  commerce_order_better_balance_formatted: string;
  status: CommerceOrderStatus;
  state: CommerceOrderStatus;
  created: string;
  changed: string;
  placed: string;
  field_commerce_billy_i_date: string;
  field_commerce_billy_cancel_date: string;
  commerce_coupons: string;
  commerce_discounts: string;
  type: string;
  flag_early_order_registration: boolean;
  flag_check_in_by_order: boolean;
  flag_scanned_order_id: boolean;
  commerce_customer_billing: string;
  commerce_customer_profiles: Array<CommerceCustomerProfile>;
  commerce_payment_transactions: Array<CommercePaymentTransaction>;
  commerce_order_payments_amount: number;
  commerce_order_payments_amount_formatted: string;
  commerce_line_items_amount: number;
  commerce_line_items_amount_formatted: string;
  order_owner: Array<any>;
  commerce_line_items: any;
  commerce_line_items_grouped: any;
  conference_delegates: object;
  custom_order_message: string;
  custom_early_order_message: string;
  constructor(user) {
    const orderID = Math.floor((Math.random() * 10000) +1);
    this.order_id = orderID;
    this.order_number = orderID;
    this.uid = user.uid;
    this.mail = user.mail;
    this.commerce_order_total = 0;
    this.commerce_order_total_formatted = '$0.00';
    this.balance = 0;
    this.balance_formatted = '$0.00';
    this.commerce_order_better_balance = 0;
    this.commerce_order_better_balance_formatted = '$0.00';
    this.status = CommerceOrderStatus.cart;
    this.state = CommerceOrderStatus.cart;
    this.created = new Date().valueOf().toString().substring(0, 10);
    this.changed = null;
    this.placed = '';
    this.field_commerce_billy_i_date = null;
    this.field_commerce_billy_cancel_date = null;
    this.commerce_coupons = '';
    this.commerce_discounts = '';
    this.type = 'commerce_order';
    this.commerce_customer_profiles = [];
    this.commerce_payment_transactions = [];
    this.commerce_line_items = [];
  }

}
