// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.devotion, .reading, .principle {
  text-align: left;
  font-size: 120%;
  line-height: 180%;
}`, "",{"version":3,"sources":["webpack://./src/app/home/daily-devotions/daily-devotional/daily-devotional.page.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ","sourcesContent":[".devotion, .reading, .principle {\n    text-align: left;\n    font-size: 120%;\n    line-height: 180%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
