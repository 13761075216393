import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Md5 } from 'ts-md5';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import axios from 'axios';


@Injectable({
  providedIn: 'root'
})
export class ArenaService {

    url = 'https://www.lancasterbaptist.org/v1/phone-numbers/all';
    arenaLoginURL = 'https://arena.lancasterbaptist.org/api.svc/login';
    arenaAPI = 'https://arena.lancasterbaptist.org/api.svc/';
    // TWILIO_ACCOUNT_SID='AC98a775b2f4b0de7d45b9ddaeea260cb5';
    // TWILIO_AUTH_TOKEN='dff73f2ef4600888adc544880799d4a9';
    // TWILIO_PHONE_NUMBER='+16619464663';
    // TWILIO_CHURCH_PHONE_NUMBER='+16619464663';
    // TWILIO_SLC_PHONE_NUMBER='+16615797753';
    // TWILIO_NOTIFY_SID='ISb00cdef882f42eabf1cd994e1b9b8b6a';
    ARENA_API_KEY='500e8112-fd5e-487d-9f7e-20ee5d7afb0c';
    ARENA_API_SECRET='ed563c4c-3421-4123-89d1-a915da773dc1';
    currentSession = '5b23c12b-1533-4256-92b9-fa30ea7bafad';
    
    SFO_KEY = 'e29d85e8-3808-6847-47ec-8c18d619fb48';
    SFO_SECRET = '5f581593-9165-1d55-0086-e5a6117890be';

    CONTRIB_SECRET = '34721ad1-f438-4ed8-8911-76ba79428593';

    PUBLIC_KEY = this.ARENA_API_KEY;
    SECRET_KEY = this.ARENA_API_SECRET;

    constructor(private http: HttpClient) {

    }

    generateMD5(blob) {
        const md5 = new Md5();

        // Append incrementally your file or other input
        // Methods are chainable
        md5.appendStr('somestring')
            .appendAsciiStr('a different string')
            .appendByteArray(blob);

        // Generate the MD5 hex string
        md5.end();
    }

    async createArenaSig(url, apiSession, hasParams: boolean = false) {

        const lowerUrl = url.toLowerCase();
        let apiSessionParam = '?api_session='+apiSession.toLowerCase();
        if (hasParams) {
            apiSessionParam = '&api_session='+apiSession.toLowerCase();
        }

        // Calculate the MD5 hash for the request api_sig parameter
        const combinedURL = `${this.SECRET_KEY}_${lowerUrl}${apiSessionParam}`;
        const hash = new Md5();
        hash.appendStr(combinedURL);

        const apiSigParam = 'api_sig='+hash.end();
        const result = this.arenaAPI + url + apiSessionParam + '&' + apiSigParam;
        return result;
    }

    async login(credentials: ArenaCredentials) {
        const headers = {'Content-Type': 'application/x-www-form-urlencoded',};
        const options = { headers: new HttpHeaders(headers) };
        const body = `username=${credentials.username}&password=${credentials.password}&api_key=${this.PUBLIC_KEY}`;
        const arenaRes = await lastValueFrom(this.http.post(this.arenaLoginURL, body, options));
        console.log(arenaRes);
            // .then(async (response: any) => {
            // console.log(response);
            // return response;
            // })
            // .catch(error => {
            // console.log(error);
            // return error;
            // });
            // const jsonData = JSON.parse(arenaRes);
            // console.log(jsonData);
            // return jsonData;
    }

    async axiosLogin(credentials: ArenaCredentials) {
      const headers = {'Content-Type': 'application/x-www-form-urlencoded',};
      const options = { headers: new HttpHeaders(headers) };
      const body = `username=${credentials.username}&password=${credentials.password}&api_key=${this.PUBLIC_KEY}`;
      const arenaRes = await axios.post(this.arenaLoginURL, body, {headers});
      console.log(arenaRes);
    }

    async getPerson(session: any) {
          // Setup the request to get the people
          const sessionID = session.ApiSession.SessionID._text;
          let params = '?fields=FirstName,LastName,CellPhone&limit=200';
          params += '&MemberStatus=958';
          const url = await this.createArenaSig('person/list'+params, sessionID, true);
        //   const url = await createArenaSig('attribute/list', sessionID, false);
          const persons = await firstValueFrom(this.http.get(url)).then((data: any) => {
            console.log(data);
            const jsonConvert = JSON.parse(data);
            return jsonConvert;
          }).catch(err => {
            console.log(err);
            return JSON.parse(err);
          });
          const jsonPersons = JSON.parse(persons);
          const personArray = jsonPersons.PersonListResult.Persons.Person;
          const cleanPersonArray = [];
          personArray.map(obj => {
            console.log(obj);
            if (obj.CellPhone?._text) {
                const cleanNumber = obj.CellPhone?._text.replace(/[() -.]/g, '');
                const newObj = {
                    address: cleanNumber,
                    title: obj.FirstName._text + ' ' + obj.LastName._text
                };
                cleanPersonArray.push(newObj);
            }
          });
          console.log(cleanPersonArray);
          return cleanPersonArray;
        }
}


export class ArenaCredentials {
    username: string;
    password: string;
    constructor(userName: string, pass: string) {
        this.username = userName;
        this.password = pass;
    }
}

export const ArenaEndpoints = {
  // Working
  login: 'login',
  me: 'me',
  person: 'person/487',
  groups: 'group/list',
  myGroup: 'group/295',
  groupMembers: 'group/295/member/list',
  profileList: 'profile/list',
  profile: 'profile/1682',
  profile1454: 'profile/1454',
  profileIdList: 'profile/1682/list',
  profileMemberList: 'profile/1693/member/list',
  myFamily: 'family/44220',
  persons: 'person/list',
  attributes: 'person/{PersonGUID}/attribute/list',
  personByGUID: 'person/{PersonGUID}',
  notes: 'person/{PersonGUID}/note/list',
  familyID: 'family/{FamilyID}',

  // Unavailable with API
  funds: 'fund/list',
  contributions: 'contribution/list',
  contributionsId: 'contribution/92',
  batch: 'batch/list',
  batchId: 'batch/55911',
  
  // Testing
  blob: 'https =//arena.lancasterbaptist.org/CachedBlob.aspx?guid={GUID}',
  

}

export interface ArenaProfile {
  Active: boolean;
  ActiveBranch: boolean;
  BlobLink: string;
  CampusID: number;
  CampusName: string;
  CreatedBy: string;
  DateCreated: Date;
  DateModified: Date;
  EndTime: Date;
  Guid: string;
  HierarchLevel: number;
  ModifiedBy: string;
  Name: string;
  Notes: string;
  OrganizationID: number;
  OwnerID: number;
  OwnerLink: string;
  ParentProfileID: number;
  ProfileID: number;
  ProfileTypeID: number;
  ProfileTypeValue: string;
  QualifierID: number;
  QualifierValue: string;
  StartTime: Date;
  Summary: string;
  Title: string;
}
