import { Component, Input, OnInit } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UserServiceCustom } from '../../services/user.service';
import { SystemConnection } from '../../services/drupal7/public_api';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.page.html',
  styleUrls: ['./order-details.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class OrderDetailsPage implements OnInit {

  @Input() order: any;
  userSession: SystemConnection;
  constructor(private router: Router, private user: UserServiceCustom) {
    const nav: Navigation = this.router.getCurrentNavigation();
    if (nav?.extras && nav.extras.state) {
      this.order = nav.extras.state.extras;
    }
   }

  ngOnInit() {
    this.user.currentSession.subscribe(session => {
      if (session) {
        this.userSession = session;
      }
    });
  }


}
