// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.class-logo {
  max-width: 180px;
  margin: 1rem auto;
}

ion-img.room {
  min-width: 100%;
}

ion-card {
  border-top: 1rem solid var(--ion-color-primary);
}

ion-card-header ion-avatar {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
  width: 250px;
  max-width: 250px;
  border-radius: 50%;
  margin: 1.5rem auto;
  border: 3px solid var(--ion-color-primary);
}

.class-description {
  color: var(--ion-color-medium);
  line-height: 1.5rem;
  font-size: 1.1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

a {
  text-decoration: none;
  color: var(--ion-color-medium);
}`, "",{"version":3,"sources":["webpack://./src/app/info-guide/classes/class/class.page.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;AACJ;;AACA;EACI,eAAA;AAEJ;;AAAA;EACI,+CAAA;AAGJ;;AAAA;EACI,4BAAA;EACA,sBAAA;EACA,2BAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,0CAAA;AAGJ;;AAAA;EACI,8BAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AAGJ;;AAAA;EACI,qBAAA;EACA,8BAAA;AAGJ","sourcesContent":[".class-logo {\n    max-width: 180px;\n    margin: 1rem auto;\n}\nion-img.room {\n    min-width: 100%;\n}\nion-card {\n    border-top: 1rem solid var(--ion-color-primary);\n}\n\nion-card-header ion-avatar {\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n    height: 250px;\n    width: 250px;\n    max-width: 250px;\n    border-radius: 50%;\n    margin: 1.5rem auto;\n    border: 3px solid var(--ion-color-primary);\n}\n\n.class-description {\n    color: var(--ion-color-medium);\n    line-height: 1.5rem;\n    font-size: 1.1rem;\n    margin-top: 1rem;\n    margin-bottom: 2rem;\n}\n\na {\n    text-decoration: none;\n    color: var(--ion-color-medium);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
