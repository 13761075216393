import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { IonicModule, Platform } from '@ionic/angular';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UserService } from 'src/app/services/drupal7/drupal7-services.module';
import { LogoHeaderComponent } from 'src/app/system/logo-header/logo-header.component';
import { FormsService } from 'src/app/services/drupal-forms/forms.service';
import { DrupalFormControlObject, LoginCredentials, User } from 'src/app/services/drupal7/public_api';
import { FormFieldComponent } from 'src/app/system/form-field/form-field.component';
import { addHours, format, getUnixTime, parseISO } from 'date-fns';
import { UserServiceCustom } from 'src/app/services/user.service';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.page.html',
  styleUrls: ['./registration.page.scss'],
  standalone: true,
  imports: [IonicModule, RouterModule, CommonModule, FormsModule, ReactiveFormsModule, LogoHeaderComponent, FormFieldComponent]
})
export class RegistrationPage {
  private formsService = inject(FormsService);
  private userService = inject(UserServiceCustom);

  form: FormGroup;
  formFields: DrupalFormControlObject[];

  constructor(
    private fb: FormBuilder,
    public router: Router,
    public platform: Platform,
  ) {
    this.getRegisterForm();
  }

  logout() {
    this.userService.logout();
  }

  async register() {
    
    const cleanedValues: any = this.formsService.prepareFormValues(this.formFields, this.form);
    const registerValues = this.prepareFormValues(this.formFields, cleanedValues);
    const newUser: User = {mail: registerValues.mail};
    Object.keys(registerValues).map(k => {
      newUser[k] = registerValues[k];

      // Set the username as the email address
      if (k === 'mail') {
        newUser['name'] = registerValues[k];
      }
    });
    console.log(newUser);
    const newAccount = await this.userService.register(newUser);
    if (newAccount) {
      const loginBody: LoginCredentials = {username: newUser.name, password: newUser.pass};
      const newLogin = await this.userService.login('session', loginBody, true, null, null);
      if (newLogin.user.uid) {
        this.router.navigate(['']);
        this.resetForm();
      }
    }
  }

  prepareFormValues(fields: DrupalFormControlObject[], result: any): any {
    for (const field of fields) {
      if (field.ionic_type === 'field_group') {
        for (const subfield of field.fields) {
          this.formatFormFieldValuesForDrupal(subfield, result);
        }
      } else {
        this.formatFormFieldValuesForDrupal(field, result);
      }
    }
    return result;
  }

  formatFormFieldValuesForDrupal(field: DrupalFormControlObject, result: any) {
    switch(field.ionic_type) {
      case 'account':
        break;
      case 'list':
        if (field.widget?.type === 'select_or_other') {
          result[field.field_name] = {und: {select: result[field.field_name]}};
        } else {
          result[field.field_name] = {und: result[field.field_name]};
        }
        break;
      case 'date':
        // Convert the time to unix and add 7 hours for the timezone offset
        let unixTime = getUnixTime(new Date(result[field.field_name]));
        if (result[field.field_name].endsWith('Z')) {
          unixTime = getUnixTime(addHours(new Date(result[field.field_name]), 7));
        }
        result[field.field_name] = {und: [{value: {date: unixTime}}]};
        break;
      case 'enddate_get':
        Object.keys(result[field.field_name])
        .map(key => {
          // Convert the time to unix and add 7 hours for the timezone offset
          let nestedUnixTime = getUnixTime(new Date(result[field.field_name][key]));
          if (result[field.field_name].endsWith('Z')) {
            nestedUnixTime = getUnixTime(addHours(new Date(result[field.field_name][key]), 7));
          }
          result[field.field_name][key] = nestedUnixTime;
        });
        break;
      default:
        result[field.field_name] = {und: [{value: result[field.field_name]}]};
        break;
    }
    return result;
  }

  getRegisterForm() {
    this.formsService.getInternalForm(null, 'user', 'register', 'form', {}).then(res => {
      this.formFields = res.form_fields;
      this.form = res.formGroup;
    });
  }

  resetForm() {
    this.form = this.fb.group({});
    this.formFields = null;
    this.getRegisterForm();
  }

  }

  export class UserAccount {
    [key: string]: any;
  
    created: string;
    name: string;
    mail: string;
    pass: string;
    visit_first_name: {und: [{value: string}]};
    visit_last_name: {und: [{value: string}]};
    person_phone_number: {und: [{value: string}]};
    gender: {und: string};
    marital_status: {und: {select: string}};
    birthday: {und: [{value: {date: string}}]};
    connection_group: {und: string};
    person_job: {und: [{value: string}]};
    field_stripe?: {und: [{value: string}]};
  
    constructor(name, mail, pass, firstName, lastName, phone, connectionGroup, gender, maritalStatus, job, bDay) {
      this.created = getUnixTime(addHours(new Date(), 8)).toString();
      this.name = name;
      this.mail = mail;
      this.pass = pass;
      this.visit_first_name = {und: [{value: firstName}]};
      this.visit_last_name = {und: [{value: lastName}]};
      this.person_phone_number = {und: [{value: phone}]};
      // this.connection_group = {und: connectionGroup};
      // this.gender = {und: gender};
      // this.marital_status = {und: {select: maritalStatus}};
      // this.person_job = {und: [{value: job}]};
      // this.birthday = {und: [{value: {date: bDay.toString()}}]};
  
    }
    set(key, value) {
      UserAccount[key] = value;
    }
  }