// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation hr {
  width: 100%;
  height: 2px;
  background-color: var(--ion-color-dark);
  margin: 2rem 0;
}
.confirmation .overview ion-avatar {
  width: 240px;
  height: 240px;
  margin: 0 auto;
}
.confirmation .overview h1 {
  margin-bottom: 0;
}
.confirmation .overview h4 {
  color: var(--ion-color-medium);
  font-weight: 400;
  font-size: 18px;
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/commerce/confirmation/confirmation.page.scss"],"names":[],"mappings":"AACI;EACI,WAAA;EACA,WAAA;EACA,uCAAA;EACA,cAAA;AAAR;AAGQ;EACI,YAAA;EACA,aAAA;EACA,cAAA;AADZ;AAGQ;EACI,gBAAA;AADZ;AAGQ;EACI,8BAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;AADZ","sourcesContent":[".confirmation {\n    hr {\n        width: 100%;\n        height: 2px;\n        background-color: var(--ion-color-dark);\n        margin: 2rem 0;\n    }\n    .overview {\n        ion-avatar {\n            width: 240px;\n            height: 240px;\n            margin: 0 auto;\n        }\n        h1 {\n            margin-bottom: 0;\n        }\n        h4 {\n            color: var(--ion-color-medium);\n            font-weight: 400;\n            font-size: 18px;\n            margin-top: 5px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
