import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseCloudMessagesService } from './services/firebase-cloud-messages.service';
import { StorageService } from './services/storage/storage.service';
import { Capacitor } from '@capacitor/core';
import { IonicModule } from '@ionic/angular';

import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule],
})
export class AppComponent {

  private storageService = inject(StorageService);
  private fcmService = inject(FirebaseCloudMessagesService);
  public router = inject(Router);
  
  constructor() {
    this.init();
  }

  async init() {
    // Trigger the push setup
    if (Capacitor.getPlatform() !== 'web') {
      this.fcmService.initPush();
    }
    const loggedIn = await this.storageService.get('loggedIn');
    if (loggedIn === null) {
      this.router.navigateByUrl('login');
    }
  }




}
