import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { Menu } from '../models';

/**
 * menu service for drupal
 *
 * @see https://www.drupal.org/project/services_menu for more details
 */
@Injectable()
export class MenuService extends MainService {
  override entityType = 'menu';

  /**
   * implement menu retrieve
   *
   * @param menuMachineName menu machine name
   * @return menu witl all children links
   */
  getMenu(menuMachineName: string): Promise<Menu> {
    return this.get(menuMachineName);
  }
}
