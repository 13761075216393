import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { FlagAction, FlagIonic, CustomEntityOptions, FlaggedEntity } from '../models';

/**
 * flag service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class FlagService extends MainService {
  override entityType = 'flag';

  /**
   * impelement index resource
   *
   * @param options the optional entity type
   * @return array of flags
   */
   getAllFlags(options?: CustomEntityOptions): Promise<FlagIonic[]> {
    if (options) {
      const filters = this.getArgs(options.parameters);
      return this.get(filters);
    } else {
      return this.get();
    }
  }

  /**
   * implement retrieve resource
   *
   * @param flagName flag name to retrieve
   * @return object of the flag entity
   */
  getFlagByName(flagName: string): Promise<FlagIonic> {
    return this.get(flagName);
  }

  /**
   * Service wrapper to flag a content.
   *
   * @param flagName The flag name.
   * @param contentId The content ID to check if it was flagged.
   * @param uid The user ID to check if they flagged the content.
   * @return boolean; TRUE if content was flagged.
   */
  isFlagged(flagName: string, contentId: number, uid?: number): Promise<boolean> {
    const isFlagged = {
        flag_name: flagName,
        content_id: contentId,
        uid
    };
    return this.post(isFlagged, 'is_flagged');
  }

  /**
   * Service wrapper to check if a content is flagged by a user.
   *
   * @param flagAction The flag action object.
   * @return boolean; TRUE if content was flagged.
   */
  flag(flagAction: FlagAction): Promise<boolean> {
    return this.post(flagAction, 'flag');
  }

  /**
   * Service wrapper to count a flag on a content.
   *
   * @param flagName
   *   The flag name.
   * @param contentId
   *   The content ID to check if it was flagged.
   * @return
   *   A object contain the number which shows how many users flagged a content.
   */
  countAll(flagName: string, contentId: number): Promise<null> {
    const flag = {
        flag_name: flagName,
        content_id: contentId
    };
    return this.post(flag, 'countall');
  }

  /**
   * Service wrapper to check if a content is flagged by a user.
   *
   * @param flagAction The flag action object.
   * @return array of results of the flagged content.
   */
    bulkFlag(flagAction: FlagAction[]): Promise<FlaggedEntity[]> {
      return this.post(flagAction, 'bulk_flag');
    }

}
