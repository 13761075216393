import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LogoHeaderComponent } from 'src/app/system/logo-header/logo-header.component';
import { UserServiceCustom } from 'src/app/services/user.service';
import { MessagesService } from 'src/app/services/messages.service';
import { PasswordCode, SystemConnection } from 'src/app/services/drupal7/models/system';
import { User } from 'src/app/services/drupal7/models';
import { PasswordChangedResponse } from 'src/app/services/drupal7/models/user';
@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.page.html',
  styleUrls: ['./password-reset.page.scss'],
  standalone: true,
  imports: [IonicModule, RouterModule, CommonModule, FormsModule, LogoHeaderComponent]
})
export class PasswordResetPage {

  private userService = inject(UserServiceCustom);
  private messages = inject(MessagesService);
  private router = inject(Router);

  email: string;

  session: SystemConnection;

  passcode: string;
  pass_reset_token: string;
  pass: string;
  pass2: string;

  passwordResetStep: number = 3;

  passwordCode: PasswordCode = {
    mail: false,
    sms: false,
    name: '',
    timestamp: 0,
  };

  constructor() {
  }

  getNewPasscode() {
    this.userService.passwordResetNew(this.email).then(res => {
      if (res) {
        this.passwordCode = res;

        // Reset the username
        this.email = '';

        // Go to confirm step
        this.passwordResetStep = 2;
      }
      console.log(res);
      if (res.mail) {
        this.messages.presentToast('A verification code has been sent to the email on file.', 2000);
      }
      if (res.sms) {
        this.messages.presentToast('A verification code has been texted to the phone number on file.', 2000, 2200);
      }
    });
  }

  getVerificationCode() {
  }

  confirmPasswordReset() {
    const body = {
      name: this.passwordCode.name,
      timestamp: this.passwordCode.timestamp,
      password: this.passcode
    }
    console.log(body);
    this.userService.confirmPasswordReset(body).then(user => {
      console.log(user);
      if (user?.session?.user?.uid) {
        this.session = user.session;
        this.pass_reset_token = user.pass_reset_token;

        // Go to new password step
        this.passwordResetStep = 3;
      }
    });
  }

  passMatch() {
    return this.pass && this.pass2 && (this.pass === this.pass2) ? true : false;
  }

  async changePassword() {
    if (this.session.user.uid) {
      this.userService.updatePassword(this.session.user.uid, {pass: this.pass}, this.pass_reset_token, this.session).then((res: PasswordChangedResponse) => {
        console.log(res);
        if (res?.uid) {
          this.clear();
          this.navigateToPage('login');
        }
      });
    }
  }

  navigateToPage(url: string, extras?: any) {
    this.router.navigateByUrl(url, {replaceUrl: true, skipLocationChange: true, state: {
      registerPage: extras
    }});
  }

  clear() {
    this.session = null;
    this.passwordResetStep = 1;
    this.email = '';  
    this.passcode = '';
    this.pass_reset_token = '';
    this.pass = '';
    this.pass2 = '';
    this.passwordResetStep = 1;
    this.passwordCode = {
      mail: false,
      sms: false,
      name: '',
      timestamp: 0,
    };
  
  }
}