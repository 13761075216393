import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class DarkModeService {

    prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    isDarkMode: boolean = false;
    constructor() {
        this.toggleDarkTheme(this.prefersDark.matches);
        this.prefersDark.addEventListener('change', (mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));
    }

    toggleDarkTheme(shouldAdd) {
        this.isDarkMode = shouldAdd;
    }
}