/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import { MessagesService } from './messages.service';
import { StorageService } from './storage/storage.service';
import { AuthService } from './auth.service';
import allFlags from '../../assets/flag/all-flags.json';
import { AlertController } from '@ionic/angular';
import { FlagItem } from './menu.service';
import { CommerceOrderStatus } from './commerce.service';

@Injectable({
  providedIn: 'root'
})
export class FlagService {

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private alertCtrl: AlertController,
    private message: MessagesService,
    public storage: StorageService) { }

async flagEntity(entity: any, flag: FlagItem, flagAction: boolean, session: any,
    flagToastMessage: string, unflagToastMessage: string, storageKey?: string, confirmed: boolean = true): Promise<boolean> {

      console.log(flagAction);

      if (environment.flag.flagTypes.confirmFlags.indexOf(flag.name) > -1) {
        confirmed = await this.confirmFlag(entity, flag, flagAction);
      }
      if (confirmed) {
        console.log(flagAction);
        const action = flagAction === true ? 'unflag' : 'flag';
        console.log(action);
        const body = {
          entity_id: entity.nid,
          flag_name: flag.name.replace('flag_', ''),
          action,
          uid: session.user.uid
        };
        console.log(body);
        return this.http.post(environment.flag.flagURL, JSON.stringify(body), this.auth.setupRequestOptions('csrf', session)).pipe(
        map(data => {
          if (data[0] === true) {
            const flagging = action === 'flag' ? true : false;
            entity[flag.name] = flagging;
            if (storageKey) {
              this.storage.storeEntity(storageKey, entity);
            }
            console.log(flagging);
            if (flagging) {
              this.message.presentToast(flagToastMessage, 2000);
            }
            if (!flagging) {
              this.message.presentToast(unflagToastMessage, 2000);
            }
          }
          return entity[flag.name];
        }),
        catchError(err => {
          console.error('Error Message: ', err);
          this.message.presentToast(err.status + err.statusText, 2000, null, 'bottom', 'danger');
          entity[flag.name] = flagAction;
          return entity[flag.name];
        })
      ).toPromise();
    } else {
      console.log(flagAction);
      return flagAction;
    }
  }

  async confirmFlag(item: any, flag: FlagItem, flagValue: boolean) {
    const wording = flagValue ? 'unflag' : 'flag';
    const alert = await this.alertCtrl.create({
      header: 'Confirm ' + flag.label,
      message: 'Are you sure you want to ' + wording + ' ' + item.register_first_name + ' ' + item.register_last_name + ' for ' + flag.label +'?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Confirm',
          role: 'submit',
          cssClass: 'warning',
        }
      ]
    });
    await alert.present();
    return await alert.onDidDismiss().then(async (res: any) => {
      if (res.role === 'submit') {
        return true;
      } else {
        return false;
      }
    });
  }

  async getActiveFlags() {
    const result: object = {};
    for await (const flag of allFlags) {
      result[flag.type] = allFlags.filter(o => o.type === flag.type && o.status);
    }
    const arr = Object.values(result).sort((a, b) => a[1] - b[1]);
    console.log(arr);
    return arr;
  }

  checkFlagDisabled(flag: any, entity: any): boolean {
    if (flag.disabled) {
      for (const depFlag of flag.disabled) {
        if (entity['flag_'+depFlag.name] === depFlag.value) {
          return true;
        }
      }
    } else if (flag.name !== 'not_attending' && entity.flag_not_attending) {
      return true;
    } else {
      return false;
    }
  }

  checkFlagHidden(flag: FlagItem, entity: any, session: any): boolean {
    const hasOpenOrder = entity.referenced_orders.map(o => o.state !== CommerceOrderStatus.invoiced);
    if (flag.name === 'conference_check_in' && !hasOpenOrder.indexOf(true) ||
        !flag.status ||
        entity['flag_'+flag.name] === undefined ||
        !session.user.flag_access.flag[flag.name]) {
      return true;
    } else { return false;}
  }

  getPageTitleandDesc(objKey: string) {
    let pageDescription = '';
    switch (objKey) {
      case 'badgeFlags':
        pageDescription = 'Check the flags to be automatically flagged when scanned with a code scanner on the badge page.';
        break;
      case 'priceFlags':
        pageDescription = 'Check the flags to enable or disable flags from being shown on the payment page.';
        break;
      default:
        pageDescription = 'Check the flags to be automatically flagged when scanned with a code scanner or enable or disable flags from being shown on the check in page.';
        break;
    }
    return pageDescription;
  }

  // loadFlags(delegate: any) {
  //   const adminPriceModifingFlags: Array<Flag> = [];
  //   const authorizedPriceModifingFlags: Array<Flag> = [];
  //   const adminFlags: Array<Flag> = [];
  //   const authorizedFlags: Array<Flag> = [];
  //   const checkInOut: Array<Flag> = [];
  //   const notAttending: Array<Flag> = [];
  //   const badgePrinted: Array<Flag> = [];
  //     delegate.authorized_flags = [];
  //     delegate.admin_flags = [];
  //     delegate.admin_price_flags = [];
  //     delegate.authorized_price_flags = [];
  //     delegate.badge_printed_flag = [];
  //     Object.keys(delegate)
  //     .map(key => {
  //       for (const flag of authorizedFlags) {
  //         if ('flag_' + flag.name === key) {
  //           const value = delegate[key];
  //           const label = flag.label;
  //           delegate.authorized_flags.push({key, value, label});
  //         }
  //       }
  //       for (const flag of adminFlags) {
  //         if ('flag_' + flag.name === key) {
  //           const value = delegate[key];
  //           const label = flag.label;
  //           delegate.admin_flags.push({key, value, label});
  //         }
  //       }
  //       for (const flag of adminPriceModifingFlags) {
  //         if ('flag_' + flag.name === key) {
  //           const value = delegate[key];
  //           const label = flag.label;
  //           delegate.admin_price_flags.push({key, value, label});
  //         }
  //       }
  //       for (const flag of authorizedPriceModifingFlags) {
  //         if ('flag_' + flag.name === key) {
  //           const value = delegate[key];
  //           const label = flag.label;
  //           delegate.authorized_price_flags.push({key, value, label});
  //         }
  //       }
  //       for (const flag of badgePrinted) {
  //         if ('flag_' + flag.name === key) {
  //           const value = delegate[key];
  //           const label = flag.label;
  //           delegate.badge_printed_flag.push({key, value, label});
  //         }
  //       }
  //     });
  //   return delegate;
  // }

}

  export interface Flag {
    label: string;
    name: string;
    flagMessage: string;
    unflagMessage: string;
    status: boolean;
    type: string;
}


