import { Injectable } from '@angular/core';
import { LoadingController, ToastController, AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(
    public toastController: ToastController,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController) { }

    async delay(ms: number) {
      await new Promise<void>(resolve => setTimeout(()=>resolve(), ms));
    }


    async showLoading(title, dismiss, time) {
      const loading = await this.loadingCtrl.create({
        message: title,
        backdropDismiss: dismiss,
        duration: time
      });
      await loading.present();
    }

    async presentAlert(title, body) {
      const alert = await this.alertCtrl.create({
        header: title,
        message: body,
        buttons: ['OK']
      });
      await alert.present();
    }

    async presentToast(title: string, duration: number, delay?: number, position?: any, color?: string) {
      if (delay) {
        await this.delay(delay);
      }
      const toast = await this.toastController.create({
        message: title,
        duration,
        position,
        color,
        mode: 'ios',
        cssClass: 'ion-text-center'
      });
      toast.present();
    }

}
