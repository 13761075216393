import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { IonicStorageModule, Storage } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';
import { Drupal7ServicesModule, DrupalConstants, Settings } from './app/services/drupal7/public_api';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import '@angular/compiler';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { MainStripeService } from './app/services/commerce/stripe/main.service';

import { BrowserModule } from '@angular/platform-browser';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { SMS } from '@awesome-cordova-plugins/sms/ngx';
import { TouchID } from '@awesome-cordova-plugins/touch-id/ngx';
import { AppleWallet } from '@awesome-cordova-plugins/apple-wallet/ngx';
import { StorageService } from './app/services/storage/storage.service';
import { StripeService } from './app/services/stripe.service';
import { ElavonService } from './app/services/elavon.service';
import { CommerceService } from './app/services/commerce.service';
import { MyCustomApiService } from './app/services/drupal7/my_custom_api/my_custom_api.service';


if (environment.production) {
  enableProdMode();
}

const drupalSettings: Settings = {
  apiHost: 'www.lancasterbaptist.org',
  apiEndPoint: 'api',
  language: 'und',
  requestTimeout: 15000,
  apiProtocol: 'https',
  allowOffline: true,
  cookieHeader: true,
  sessionDays: 23
};
DrupalConstants.Settings = drupalSettings;

bootstrapApplication(AppComponent, {
  providers: [
    Storage,
    StorageService,
    CommonModule,
    NgxQRCodeModule,
    FormsModule,
    ReactiveFormsModule,
    StripeService,
    MyCustomApiService,
    MainStripeService,
    BarcodeScanner,
    SMS,
    BrowserModule, YouTubePlayerModule, TouchID, AppleWallet, ElavonService, CommerceService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(IonicModule.forRoot({})),
    importProvidersFrom(Drupal7ServicesModule),
    importProvidersFrom(IonicStorageModule.forRoot({
      name: '__lbc',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    })),
    provideRouter(routes),
  ],
});
