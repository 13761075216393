import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from '../../services/auth.service';
import { MessagesService } from '../../services/messages.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-prayer-list',
  templateUrl: './prayer-list.page.html',
  styleUrls: ['./prayer-list.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class PrayerListPage implements OnInit {
  prayerRequests: any;
  missionary: any;
  prayerRequest: any;
  fakeItems: Array<any> = new Array(6);
  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  selectedCategory = 'all';
  constructor(
    public messages: MessagesService,
    public router: Router,
    public auth: AuthService) {
      this.getData();
   }

  ngOnInit() {
  }

  getData(showLoading: boolean = true) {
    if (showLoading) {
      this.messages.showLoading('Getting the prayer list...', false, 1000);
    }
    this.auth.get(environment.churchURLS.PrayerListUrl, this.options).subscribe(data => {
      console.log(data);
      this.prayerRequests = data.events;
      console.log(this.prayerRequests);
    });
    this.auth.get(environment.churchURLS.MissionaryUrl, this.options).subscribe(data => {
      console.log(data);

      this.missionary = data[0];
    });
  }

  segmentChanged(val: string) {
    this.selectedCategory = val;
    console.log(this.selectedCategory);
    this.prayerRequests.map(category => {
      category.prayer_list_items.map(item => {
        if (this.selectedCategory === 'new') {
          item.hidden = !item.promote ? true : false;
        } else {
          item.hidden = false;
        }
      });
      return category;
    });
  }


}
