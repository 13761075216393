import { HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { DailyDevotional, DailyDevotionalSection } from '../daily-devotions.page';
import { AuthService } from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-daily-devotional',
  templateUrl: './daily-devotional.page.html',
  styleUrls: ['./daily-devotional.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class DailyDevotionalPage implements OnInit {

  @Input() devotion: DailyDevotional;
  @Input() sections: DailyDevotionalSection[] = [];
  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  user: any;
  constructor(
    private router: Router,
    public auth: AuthService
  ) {
    const nav: Navigation = this.router.getCurrentNavigation();
    if (nav?.extras && nav.extras.state) {
      this.devotion = nav.extras.state.extras.devotion;
      this.sections = nav.extras.state.extras.sections;
      if (!this.sections.length) {
        this.getBibleReading(this.devotion, this.options);
      }
    }
   }

  ngOnInit() {
  }

  async getDevotionals() {
    const devotion = await this.auth.get(environment.churchURLS.DevotionalUrl, this.options).toPromise().then(res => res[0]);
    const sections: DailyDevotionalSection[] = await this.getBibleReading(devotion, this.options);
    this.devotion = devotion;
    this.sections = sections;
    return {devotion, sections};
  }

  async getBibleReading(devotion: DailyDevotional, options: any) {
    const bibleReading = await this.auth.get(environment.churchURLS.BibleReadingUrl + '/' + devotion.month + '/' + devotion.day, options).toPromise().then(res => res[0]);
    const proverbsReading = await this.auth.get(environment.churchURLS.ProverbsReadingUrl + '/' + devotion.day, options).toPromise().then(res => res[0]);
    const sections: DailyDevotionalSection[] = [];
    sections.push(new DailyDevotionalSection(
      'Old Testament Reading',
      bibleReading.old_testament_ref,
      bibleReading.old_testament_text));

      sections.push(new DailyDevotionalSection(
        'New Testament Reading',
        bibleReading.new_testament_ref,
        bibleReading.new_testament_text));

        sections.push(new DailyDevotionalSection(
        'Proverbs Reading',
        proverbsReading.proverbs_ref,
        proverbsReading.proverbs_text));

    return this.sections = sections;
  }

  navigateToPage() {
    this.router.navigateByUrl('home/daily-devotions', {replaceUrl: true, skipLocationChange: true});
  }

}
