import { Component, Input, OnInit } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { AlertController, IonicModule, ModalController } from '@ionic/angular';
import { AuthService } from '../../services/auth.service';
import { StripeService } from '../../services/stripe.service';
import { DrupalFormControlObject } from '../../services/entity.service';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormsService } from '../../services/forms.service';

import { PaymentMethod } from '../../services/payment.service';
import PaymentMethods from '../../../assets/stripe/payment_methods.json';
import { MessagesService } from '../../services/messages.service';
import { StorageService } from '../../services/storage/storage.service';
import { environment } from 'src/environments/environment.prod';
import { CommonModule } from '@angular/common';
import { SystemConnection } from '../../services/drupal7/public_api';
import { UserServiceCustom } from '../../services/user.service';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.page.html',
  styleUrls: ['./payment-methods.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
  ],
})
export class PaymentMethodsPage implements OnInit {

  @Input() modalMode = false;
  @Input() customer: any;
  session: SystemConnection;
  addMethodForm = false;
  dynamicAdministrativeArea: any;
  paymentMethods: Array<PaymentMethod>;
  paymentMethod = 'card';
  cardControls: FormGroup;
  cardFormJSON: Array<DrupalFormControlObject>;
  bankControls: FormGroup;
  bankAccountFormJSON: Array<DrupalFormControlObject>;
  maxDate = (new Date().getFullYear() + 20).toString();
  minDate = (new Date().getFullYear()).toString();

  constructor(
    public router: Router,
    public auth: AuthService,
    public user: UserServiceCustom,
    public forms: FormsService,
    public storage: StorageService,
    private messages: MessagesService,
    private modalController: ModalController,
    public stripeService: StripeService,
    private alertCtrl: AlertController) {
      this.getAddCardForm();
      this.getAddBankForm();
      this.paymentMethods = this.getActivePaymentMethods('access');

      const nav: Navigation = this.router.getCurrentNavigation();
      if (nav?.extras && nav.extras.state) {
        this.customer = nav.extras.state.extras.customer;
      }
  }
  ngOnInit() {
    this.user.currentSession.subscribe(session => {
      this.session = session;
      this.stripeService.getCardData(session).then(res => this.customer = res);
    });
  }

  submitModal() {
    if (this.modalMode) {
      this.modalController.dismiss({submit: false, customer: this.customer}, 'submit');
    }
  }

  cancelModal() {
    if (this.modalMode) {
      this.modalController.dismiss({submit: false, customer: this.customer}, 'cancel');
    }
  }

  async getData(showLoading: boolean = false) {
    if (showLoading) {
      this.messages.showLoading('Refreshing the data...', false, 1500);
    }
    if (!this.session) {
      this.ngOnInit();
      this.customer = await this.stripeService.getCardData(this.session);
    } else {
      this.customer = await this.stripeService.getCardData(this.session);
    }
  }

  getActivePaymentMethods(prop) {
    const activePaymentMethods = [];
    for (const paymentMethod of PaymentMethods) {
      if (paymentMethod[prop]) {
        activePaymentMethods.push(paymentMethod);
      }
    }
    return activePaymentMethods;
  }

  async addCard() {
    this.messages.showLoading('Adding card...', false, 4000);
    this.customer = await this.stripeService.validateCard(this.cardControls.value).then(res => res.customer);
    this.cardControls = new FormGroup([]);
    this.getAddCardForm();
    this.addMethodForm = false;
    if (this.modalMode) {
      this.submitModal();
    }
  }

  async addBank() {
    this.messages.showLoading('Adding bank account...', false, 4000);
    this.customer = await this.stripeService.validateBank(this.bankControls.value);
    this.bankControls = new FormGroup([]);
    this.getAddBankForm();
    this.addMethodForm = false;
    if (this.modalMode) {
      this.submitModal();
    }
  }

  async getAddCardForm() {
    const reactiveForm = await this.forms.getForm(this.session, 'stripe', 'card');
    this.cardControls = reactiveForm.formGroup;
    // Add the dynamic options to the expiration year
    this.auth.addOptions(reactiveForm.formFields, ['exp_year'], this.auth.generateArrayOfYears(0, 10));
    this.cardFormJSON = reactiveForm.formFields;
  }

  async getAddBankForm() {
    const reactiveForm = await this.forms.getForm(this.session, 'stripe', 'bank_account');
    this.bankControls = reactiveForm.formGroup;
    this.bankAccountFormJSON = reactiveForm.formFields;
  }

  showDatePicked(field) {
    this.cardControls.get(field.field_name)['formattedValue'] = this.forms.formatDate(this.cardControls, field);
  }

  async getCardDataD7() {
    console.log(this.session);
    this.customer = await this.stripeService.getCardData(this.session);
  }

  async deleteCard(customerID: string, tokenID: string) {
    const alert = await this.alertCtrl.create({
      header: 'Are you sure?',
      message: 'This will delete your payment method from your account.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'DELETE',
          handler: () => {
            this.deleteCardData(tokenID);
          }
        }
      ]
    });
    await alert.present();
  }

  async deleteCardData(tokenID: string) {
    // const customer = await this.storage.get('session');
    const customer = this.session;
    if (customer.user.field_stripe.und[0] !== undefined || customer.user.field_stripe.und[0] !== null) {
      const customerID = customer.user.field_stripe.und[0].value;
      await this.stripeService.getStripeTokenByValue(customer, environment.commerce.payment_methods.stripe.stripeSKKey).then( async stripeTokendata => {
        await this.stripeService.deleteCustomerCard(customerID, tokenID, stripeTokendata)
        .then(async customerData => {
          this.customer = await this.getCardDataD7();
          const alert = await this.alertCtrl.create({
            header: 'Card Successfully Removed!',
            message: 'Your card has been removed from your account.',
            buttons: ['OK']
          });
          await alert.present();
          if (this.modalMode) {
            this.submitModal();
          } else {
            return this.getCardDataD7();
          }
        }, error => error);
      }, async err => {
        console.log('error stripe token');
        if (err.error.message === 'Expired token' || err.error.message === 'Internal Server Error') {
          this.deleteCardData(tokenID);
        }
      });
    }
  }



}
