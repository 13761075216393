import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule, ViewWillEnter } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { UserServiceCustom } from 'src/app/services/user.service';
import { LoginCredentials } from 'src/app/services/drupal7/models';
import { environment } from 'src/environments/environment.prod';
import { LogoHeaderComponent } from 'src/app/system/logo-header/logo-header.component';
import { StorageService } from 'src/app/services/storage/storage.service';
import { BiometryType } from 'capacitor-native-biometric';
import { DarkModeService } from 'src/app/services/dark-mode.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  standalone: true,
  imports: [IonicModule, RouterModule, CommonModule, FormsModule, LogoHeaderComponent]
})
export class LoginPage implements ViewWillEnter {
  private userService = inject(UserServiceCustom);
  private storageService = inject(StorageService);
  public darkModeService = inject(DarkModeService);

  biometricType: BiometryType;
  // biometricTypeMessage: string;
  // biometricTypeKey: string;
  hasBiometricCredentials = false;
  hasTouchID = false;
  hasFaceID = false;
  hasIrisID = false;
  hasMultipleID = false;
  hasLoginOption = false;


  credentials: LoginCredentials = {
    username: '', 
    password: ''
  };
  constructor(
    public router: Router
  ) {}

  login() {
    const body: LoginCredentials = {
      username: this.credentials.username,
      password: this.credentials.password
    }
    this.userService.login('session', body, true, environment.checkInType.entityType, environment.checkInType.bundle).then(res => {
      this.router.navigate(['']);
      this.credentials.username = '';
      this.credentials.password = '';
    });
  }

  async ionViewWillEnter() {
    this.biometricType = await this.userService.biometricGetType();
    if (this.biometricType) {
      this.hasLoginOption = await this.storageService.get('login_BiometryType_'+ this.biometricType);
      if (this.biometricType === BiometryType.TOUCH_ID || this.biometricType === BiometryType.FINGERPRINT) {
        this.hasTouchID = true;
      }
      if (this.biometricType === BiometryType.FACE_ID || this.biometricType === BiometryType.FACE_AUTHENTICATION) {
        this.hasFaceID = true;
      }
      if (this.biometricType === BiometryType.IRIS_AUTHENTICATION) {
        this.hasIrisID = true;
      }
      if (this.biometricType === BiometryType.MULTIPLE) {
        this.hasMultipleID = true;
        const touchID = await this.storageService.get('login_BiometryType_3');
        const faceID = await this.storageService.get('login_BiometryType_4');
        const irisID = await this.storageService.get('login_BiometryType_5');
        if (!touchID && !faceID) {
          this.hasLoginOption = false;
        }
        if (touchID || faceID) {
          this.hasLoginOption = true;
        }
      }
      this.hasBiometricCredentials = await this.userService.biometricHasCredentials();
      if (this.hasBiometricCredentials && this.hasLoginOption) {
        this.loginWithBiometrics();
      }
    }
  }

  async loginWithBiometrics() {
    const res = await this.userService.biometricVerification();
    this.credentials = res.credentials;
    this.login();
  }

  navigateToPage(url: string, extras?: any) {
    this.router.navigateByUrl(url, {replaceUrl: true, skipLocationChange: true, state: {
      registerPage: extras
    }});
  }

  continueWithoutSigningIn(url: string) {
    this.storageService.set('loggedIn', false);
    this.navigateToPage(url);
  }

}