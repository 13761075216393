import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Browser } from '@capacitor/browser';
import { environment } from 'src/environments/environment.prod';
import { HttpHeaders } from '@angular/common/http';
import ClassCategories from '../../assets/node/connection_group_categories.json';
import { MessagesService } from '../services/messages.service';
import { StorageService } from '../services/storage/storage.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SystemConnection } from '../services/drupal7/public_api';
import { UserServiceCustom } from '../services/user.service';

@Component({
  selector: 'app-info-guide',
  templateUrl: 'info-guide.page.html',
  styleUrls: ['info-guide.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
  ],
})
export class InfoGuidePage implements OnInit {

  language = 'english';
  serviceSchedule: any;
  classCategories = ClassCategories;
  session: SystemConnection;

  constructor(public router: Router, public auth: AuthService, private user: UserServiceCustom, private storage: StorageService, private messages: MessagesService) {
    this.storage.get('serviceSchedule').then(res => {
      this.serviceSchedule = res;
      if (!this.serviceSchedule) {
        this.getData();
      }
    });
  }

  ngOnInit() {
    this.user.currentSession.subscribe(session => this.session = session);
  }

  navigateToPage(url: string, extras?: any) {
    if (extras) {
      this.router.navigate([url], {state: {
          extras
      }});
    } else {
      this.router.navigate([url]);
    }
  }

  navigateToClassPage(title: string, url: string, img: string) {
    this.router.navigate([`info-guide/classes/${url}`], {state: {
        title,
        url,
        defaultImg: img
    }});
  }

  async getData(showLoading: boolean = false, event: any = null) {
    if (showLoading) {
      this.messages.showLoading('Loading the service schedule', false, 1500);
    }
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const res: Array<Schedule> = await this.auth.get(environment.churchURLS.ServiceScheduleUrl, options).toPromise();
    const result: object = {};
    res.map((a) => result[a.category] = new ScheduleGroup(a.category, []));
    res.map((b) => {
      let x = 0;
      if (result[b.category].groups[x]?.day !== b.day) {
        result[b.category].groups.push(new ScheduleDay(b.day, res.filter(d => d.category === b.category && d.day === b.day)));
      }
      x++;
    });
    const arr = Object.values(result).sort((a, b) => a[1] - b[1]);
    console.log(arr);
    this.language = arr[0].category;
    this.serviceSchedule = arr;
    this.storage.set('serviceSchedule', this.serviceSchedule);
    if (event) {
      setTimeout(() => {
        console.log('Async operation has ended');
        event.target.complete();
      }, 2000);
    }

  }

  openCampusMap() {
    Browser.open({url: 'https://lbc-downloads.com/downloads/imgs/campus-map.jpg'});
  }

}
export class ScheduleGroup {
  category: string;
  groups: Array<ScheduleDay>;
  constructor(Category: string, groups?: Array<ScheduleDay>) {
    this.category = Category;
    this.groups = groups;
  }
}
export class ScheduleDay {
  day: string;
  schedules: Array<Schedule>;
  constructor(day?: string, Schedules?: Array<Schedule>) {
    this.day = day;
    this.schedules = Schedules;
  }
}
export class Schedule {
  title: string;
  body: string;
  day: string;
  time: string;
  category: string;
  category_value: string;
}
