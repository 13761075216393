import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { catchError, timeout, map } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { StripeConstants } from './stripe-constants';
import { KeyService } from '../../drupal7/public_api';
import { Settings } from './models';

export const stripeSettings: Settings = {
  apiHost: 'api.stripe.com',
  apiEndPoint: 'v1',
  requestTimeout: 15000,
  apiProtocol: 'https',
  merchantID: 'merchant.edu.wcbc.app',
  stripePKKey: 'stripe_publishable_key',
  stripeSKKey: 'stripe'
};
StripeConstants.Settings = stripeSettings;

@Injectable()
export class MainStripeService {

  protected readonly entityType: string;

  readonly stripeSettings = stripeSettings;

  constructor(protected alertCtrl: AlertController, protected httpClient: HttpClient,
  protected keyService: KeyService) { }

    /**
     * a getter to return the required headers
     *
     * @return object of the headers
     */
    async getOptions(opts?: {publicKey?: boolean, params?: boolean}): Promise<any> {
      const token = await this.getKey(opts.publicKey);
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + token
      };
      const options: {headers, params?} = { headers: new HttpHeaders(headers) };
      if (opts.params) {
        options.params = {'expand[]': 'sources'};
      }

      return options;
    }

    async getKey(publicKey?: boolean) {
      let token = await this.keyService.getKeyValue(stripeSettings.stripeSKKey);
      if (publicKey) {
        token = await this.keyService.getKeyValue(stripeSettings.stripePKKey);
      }
      return token;
    }

  /**
   * parse an entity that comes back from a request
   * to make sure it is ready to use in the app
   *
   * @param entity the entity to be structured
   */
    cleanObject(entity: any) {
      if (entity) {
        Object.entries(entity).map(([k, v], i) => {
          let newVal = entity[k];
          if (typeof(entity[k]) === 'string') {
            newVal = entity[k].trim();
          try {
              newVal = JSON.parse(newVal);
              entity[k] = newVal;
              this.cleanObject(newVal);
            } catch (err) {
            }
            if (typeof(newVal) === 'object' && !Array.isArray(newVal) && newVal !== null) {
              Object.entries(newVal).map(([nestedKey, nestedValue], x) => {
                if (k === nestedKey) {
                  entity[k] = this.cleanObject(nestedValue);
                }
              });
            }
            if (typeof(newVal) === 'object' && Array.isArray(newVal) && newVal !== null && newVal.length === 1) {
              entity[k] = this.cleanObject(newVal);
            }
          } else if (typeof(v) === 'object' && Array.isArray(v) && v !== null && v.length === 1) {
            entity[k] = this.cleanObject(v);
          } else if (typeof(v) === 'object' && !Array.isArray(entity[k]) && entity[k] !== null) {
            this.cleanObject(entity[k]);
          } else if (typeof(v) === 'object' && Array.isArray(entity[k]) && entity[k] !== null && entity[k].length > 1) {
            for (const innerObj of entity[k]) {
              this.cleanObject(innerObj);
            }
          } else {
            this.cleanObject(newVal);
          }
        });
        return entity;
      }
    }

  /**
   * building up the full url path for each resource and / or params
   *
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @return full request path after adding the entity type and resource param
   */
  protected fullRequestURL(resource?: string | number): string {
    let request_url = StripeConstants.restUrl;

    if (this.entityType) {
      request_url += this.entityType;
    }

    if (resource) {
      if (request_url[request_url.length - 1] === '/') {
        request_url += resource;
      } else {
        request_url += '/' + resource;
      }
    }
    return request_url;
  }

  /**
   * handle status errors if the response doesn't return as a catch error
   * 
   * @param res the http response
   * @returns if the response contains an error code, return an alert for the error code, otherwise return the response
   */
  private async handleErrors(res: any): Promise<any> {
    if (res.status && res?.ok === false) {
      switch (res.status) {
        case 401:
          const unauthorized = await this.alertCtrl.create({
            header: '401 Error',
            message: res?.error ? res?.error[0] : 'Unauthorized',
            buttons: ['OK']
          });
          await unauthorized.present();
          return false;
        case 403:
          const accessDenied = await this.alertCtrl.create({
            header: '403 Error',
            message: 'Access Denied',
            buttons: ['OK']
          });
          await accessDenied.present();
          return false;
        case 404:
          const notFound = await this.alertCtrl.create({
            header: '404 Error',
            message: 'Not Found',
            buttons: ['OK']
          });
          await notFound.present();
          return false;
        case 500:
          const serverError = await this.alertCtrl.create({
            header: '500 Error',
            message: 'Internal Server Error',
            buttons: ['OK']
          });
          await serverError.present();
          return false;
        default:
          return res;
      }
    } else if (res.name === 'TimeoutError') {
      const timeoutError = await this.alertCtrl.create({
        header: 'Timeout Error',
        message: 'Request timed out',
        buttons: ['OK']
      });
      await timeoutError.present();
      return false;
    } else {
      return res;
    }
  }

  /**
   * adding http request configs: request timeout, error handler
   *
   * @param httpObservableRequest the http Observable to request
   * @return Observable of the request after adding required configs
   */
  protected async httpRequestWithConfig(httpObservableRequest: Observable<any>): Promise<any> {
    return firstValueFrom(httpObservableRequest.pipe(
      map(res => this.cleanObject(res)),
      timeout(StripeConstants.Settings.requestTimeout),
      catchError(err => StripeConstants.Instance.handleOffline(err))
    )).then(res => {
      return this.handleErrors(res);
    }).catch(err => err);
  }

  /**
   * basic http get request with headers.
   *
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @return http json response
   */
  public async get(resource?: string | number, opts?: {publicKey?: boolean, params?: boolean}): Promise<any> {
    const options = await this.getOptions(opts);
    return this.httpRequestWithConfig(
      this.httpClient.get(this.fullRequestURL(resource), options)
    );
  }

  /**
   * basic http post request with headers.
   *
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @param body the contenct of the request
   * @return http json response
   */
  public async post(body: any = {}, resource?: string | number, opts?: {publicKey?: boolean, params?: boolean}): Promise<any> {
    const options = await this.getOptions(opts);
    return this.httpRequestWithConfig(
      this.httpClient.post(this.fullRequestURL(resource), body, options),
    );
  }

  /**
   * basic http put request with headers.
   *
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @param body the contenct of the request
   * @return http json response
   */
  public async put(body: any = {}, resource?: string | number, opts?: {publicKey?: boolean, params?: boolean}): Promise<any> {
    const options = await this.getOptions(opts);
    return this.httpRequestWithConfig(
      this.httpClient.put(this.fullRequestURL(resource), body, options),
    );
  }

  /**
   * basic http delete request with headers.
   *
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @return http json response
   */
  public async delete(resource?: string | number, opts?: {publicKey?: boolean, params?: boolean}): Promise<any> {
    const options = await this.getOptions(opts);
    return this.httpRequestWithConfig(
      this.httpClient.delete(this.fullRequestURL(resource), options),
    );
  }
  
}
