
import { throwError as observableThrowError, of as observableOf, Observable } from 'rxjs';
import { Settings } from './models';

/**
 * DrupalConstants singleton design pattern of the required settings and user connection.
 */

// @dynamic
export class StripeConstants {

  private static instance: StripeConstants;


  private settings: Settings;

  constructor() { }

  /**
   * handle offline error messages
   *
   * @param err (optional) the error of the http request
   */
  public handleOffline = function(err?: any): Observable<any> {
    if (this.settings.allowOffline) {
      return observableOf(err);
    }
    return observableThrowError(err);
  };

  static get Instance() {
    if (!this.instance) {
      this.instance = new StripeConstants();
    }
    return this.instance;
  }

  /**
   * Handeling the case when user didn't implement the back-end settings and return the settings instance
   */
  static get Settings(): Settings {
    if (!this.Instance.settings) {
      throw new Error('Stripe Application settings are not set');
    }
    return this.Instance.settings;
  }

  /**
   * Set the settings instanse on application init.
   * you can also do that at the runtime, Thanks to Singleton design pattern <3 !
   */
  static set Settings(newSettings: Settings) {
    this.Instance.settings = newSettings;
  }

  /**
   * the full backend url for current user settings
   */
  static get backEndUrl(): string {
    const settings = this.Settings;
    const url = settings.apiProtocol + '://' + settings.apiHost;
    return settings.apiPort ? url + ':' + settings.apiPort + '/' : url + '/';
  }

  /**
   * Application back-end rest api url.
   */
  static get restUrl(): string {
    const settings = this.Settings;
    return this.backEndUrl + settings.apiEndPoint + '/';
  }

}
