import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { ViewOptions, ViewResults } from '../models/view';
import { Pager } from '../public_api';

/**
 * view service for drupal using services_views module
 *
 * @see https://www.drupal.org/project/services_views
 */
@Injectable()
export class ViewService extends MainService {
  override entityType = '';

  /**
   * impelemnt retrieve resource
   *
   * @param viewMachineName view machine name
   * @param options parameters of filteration for this view
   * @return array of view rows OR view html code if the options format_output is 1
   */
  async getView(viewMachineName: string, options?: ViewOptions): Promise<ViewResults> {
    if (!options) {
      const results = await this.get(viewMachineName);
      return await this.processView(results);
    }
    const args = this.getContextualFilters(options?.args);
    const filters = this.getArgs(options?.filters);
    const results = await this.get(viewMachineName + args + filters);
    if (options?.remove_props) {
      this.removeProps(results, options.remove_props);
    }
    if (options.convert_options && results.length) {
      this.convertSelectOptions(results);
    }
    return await this.processView(results, options);
  }

  async processView(res: any, options?: ViewOptions) {
        if (!res || res.pager?.page === -1) return;
  
        const result: any[] = Object.values(res).filter(item => item !== res.pager);

        if (result.length === 0) {
          return;
        }
        
        const pager: Pager = res?.pager;
        if (pager) {
          pager.links = Array.from({ length: pager.pages }, (_, i) => i);
        }

        this.removeProps(result, ['pager']);
        if (options?.obj_to_arr_props?.length) {
          for (const r of result) {
            this.convertObjToArray(r, options.obj_to_arr_props);
          }
        }
        const headers = Object.keys(result[0]);

        const viewResults: ViewResults = {
          headers,
          pager,
          results: result,
        };
        
        return viewResults;

    }
  
}
