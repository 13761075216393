import { HttpHeaders } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { environment } from 'src/environments/environment.prod';
import { DailyDevotional, DailyDevotionalSection } from './daily-devotions/daily-devotions.page';
import { AuthService } from '../services/auth.service';
import { MessagesService } from '../services/messages.service';
import { StorageService } from '../services/storage/storage.service';
import { IonicModule, ModalController, Platform } from '@ionic/angular';

import { NotificationCenterPage } from './notification-center/notification-center.page';
import { PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SystemConnection } from '../services/drupal7/public_api';
import { UserServiceCustom } from '../services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    // SwiperModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomePage implements OnInit {

  devotion: DailyDevotional;
  devotions: DailyDevotional[];
  sections: DailyDevotionalSection[] = [];

  session: SystemConnection;
  churchEvents: any;
  bible_reading: any;
  proverbs_reading: any;
  slideOpts = {
    speed: 500,
    spaceBetween: 20,
  };
  siteName = environment.siteName;

  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(public storage: StorageService,
    public router: Router,
    private user: UserServiceCustom,
    private platform: Platform,
    private modalController: ModalController,
    public auth: AuthService,
    public messages: MessagesService) {
    this.getDevotionals();
    this.getData(false);
  }

  ngOnInit() {
    this.user.currentSession.subscribe(session => this.session = session);
  }

  navigateToPage(url: string, extras?: any) {
    if (extras) {
      this.router.navigateByUrl(url, {state: {
        extras
      }});
    } else {
      this.router.navigateByUrl(url);
    }
  }

  externalLink(url: string) {
    Browser.open({url});
  }

  async getData(showLoading: boolean = true, event: any = null) {
    this.storage.get('session').then(res => this.session = res);
    if (showLoading) {
      this.messages.presentToast('Getting a fresh list of events', 1500, null, 'bottom', 'primary');
    }
    this.auth.get(environment.churchURLS.PromotedEvents, this.options).subscribe(data => {
      this.churchEvents = data.events;
    });
    if (event) {
      setTimeout(() => {
        console.log('Async operation has ended');
        event.target.complete();
      }, 2000);
    }
  }

  async getDevotionals() {
    const options = this.auth.setupRequestOptions('json', null);
    this.auth.get(environment.churchURLS.DevotionalUrl, options).toPromise().then(async (res) => {
      this.devotion = res[0];
      // this.sections = await this.getBibleReading(this.devotion, this.options);

      this.devotions = res;
    });
  }

  async getBibleReading(devotion: DailyDevotional, options: any) {
    const bibleReading = await this.auth.get(environment.churchURLS.BibleReadingUrl + '/' + devotion.month + '/' + devotion.day, options).toPromise().then(res => res[0]);
    const proverbsReading = await this.auth.get(environment.churchURLS.ProverbsReadingUrl + '/' + devotion.day, options).toPromise().then(res => res[0]);
    const sections: DailyDevotionalSection[] = [];
    sections.push(new DailyDevotionalSection(
      'Old Testament Reading',
      bibleReading.old_testament_ref,
      bibleReading.old_testament_text));

      sections.push(new DailyDevotionalSection(
        'New Testament Reading',
        bibleReading.new_testament_ref,
        bibleReading.new_testament_text));

        sections.push(new DailyDevotionalSection(
        'Proverbs Reading',
        proverbsReading.proverbs_ref,
        proverbsReading.proverbs_text));

    return sections;
  }

  navigateToNotificationCenter() {
    this.router.navigateByUrl('home/notification-center');
  }

  async notificationCenter() {
    const notifications = await this.storage.get('notifications');
    // const notifications = await PushNotifications.getDeliveredNotifications().then(res => res.notifications);

    const modal = await this.modalController.create({
      component: NotificationCenterPage,
      cssClass: 'notification-center',
      canDismiss: true,
      componentProps: {
        notifications,
        modalMode: true
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
  }



}
