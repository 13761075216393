import { HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, inject } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { SMS } from '@awesome-cordova-plugins/sms/ngx';
import { Browser } from '@capacitor/browser';
import { AlertController, IonicModule, ModalController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';
import { ArenaService } from '../services/arena.service';
import { AuthService } from '../services/auth.service';
import { FormFromJSON } from '../services/forms.service';
import { MessagesService } from '../services/messages.service';
import { StorageService } from '../services/storage/storage.service';
import { StripeService } from '../services/stripe.service';
import { BiometryType } from 'capacitor-native-biometric';
import { FirebaseCloudMessagesService } from '../services/firebase-cloud-messages.service';
import { LoginOptionsComponent } from '../login-options/login-options.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormComponent } from '../form/form.component';
import { LoginCredentials, SystemConnection } from '../services/drupal7/public_api';
import { UserServiceCustom } from '../services/user.service';
import { FormInlineComponent } from '../system/form-inline/form-inline.component';
import { FormFieldOptions } from '../services/drupal7/models/form-controls';

@Component({
  selector: 'app-account',
  templateUrl: './account.page.html',
  styleUrls: ['./account.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    FormComponent, FormInlineComponent, LoginOptionsComponent
  ],
})
export class AccountPage implements OnInit {

  private userService = inject(UserServiceCustom);
  private auth = inject(AuthService);
  private messages = inject(MessagesService);
  private sms = inject(SMS);
  private stripeService = inject(StripeService);
  private arena = inject(ArenaService);
  private fcm = inject(FirebaseCloudMessagesService);
  public storage = inject(StorageService);
  public alertCtrl = inject(AlertController);
  private modalCtrl = inject(ModalController);

  @Input() customer: any;
  userSession: SystemConnection;

  formSchema: FormFromJSON;
  loginCredentials: LoginCredentials = {username: '', password: ''};
  loginOptions = {
    touchID: false,
    faceID: false,
    irisID: false
  };
  biometricType: BiometryType;

  version = environment.version;

  subscribedTopic = {
    prayer: false,
    weekly: false,
    special: false
  };

  topics: any;
  hasTopics = false;

  constructor(
    public router: Router,
    public platform: Platform,
  ) {
    const nav: Navigation = this.router.getCurrentNavigation();
     this.initialize();

  }

  ngOnInit() {
    this.userService.currentSession.subscribe(session => {
      if (session?.user?.uid) {
        this.userSession = session;
      }
    });
    this.userService.biometricGetType().then(res => {this.biometricType = res;});
    if (this.platform.is('ios') || this.platform.is('android')) {
      this.hasTopics = true;
    }
  }

  async initialize() {
    this.userSession = await this.storage.get('session');
    if (this.userSession) {
      if (!this.customer) {
        this.customer = await this.stripeService.getCardData(this.userSession, false);
      }
    } else {
      this.loginCredentials = {username: '', password: ''};
    }
  }

  async setupTopics() {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    this.topics = await this.auth.get(environment.churchURLS.FirebaseTopics, options).toPromise();
    console.log(this.topics);
    this.fcm.getTopics().then((res: any) => {
      if (res) {
        Object.values(res).map((key: any) => {
          this.subscribedTopic[key] = true;
        });
      // Object.keys(res?.rel?.topics).map(key => {
      //       this.topics[key] = true;
      //   });
      }
    });
  }

  toggleTopicSubscription(e: any, topic: string) {
    const toggle = e.srcElement.checked ? this.fcm.subscribe(topic) : this.fcm.unsubscribe(topic);
    console.log(toggle);
  }

  sendSMS(phoneNumber: any) {
    this.sms.send(phoneNumber, 'Give',{replaceLineBreaks: true, android: {intent: 'INTENT'}});
  }

  async loginOptionsModal() {
    const modal = await this.modalCtrl.create({
      component: LoginOptionsComponent,
      cssClass: 'login-options',
      canDismiss: true,
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      componentProps: {
       biometricType: this.biometricType,
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data !== undefined) {
      console.log(data);
    }
  }


  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  loginArena() {
    this.arena.login(this.loginCredentials);
  }

  getMD5() {
    const apiSession = '0367e232-d084-48be-b3fc-23931ff8c58a';
    const personList = 'person/list?persons=10&fields=PreferredEmailFamilyID,MaritalStatusValue,PersonLink,PersonID,FirstName,LastName,CellPhone&MemberStatus=958'; // Works but can't figure out getting the email address, page size, or page number
    this.arena.createArenaSig(personList, apiSession, true);
    const familyByID = 'family/44220'; // Works
    this.arena.createArenaSig(familyByID, apiSession);
    const profile = 'profile/list'; // Works
    this.arena.createArenaSig(profile, apiSession);
    const attributes = 'attribute/list'; // Works
    this.arena.createArenaSig(attributes, apiSession);
    const personByArenaID = 'person/487'; // Works
    this.arena.createArenaSig(personByArenaID, apiSession);
    const personByGUID = 'person/8e8b323a-d459-4506-8500-c4282432261d'; // Works
    this.arena.createArenaSig(personByGUID, apiSession);
    const contributionListUrl = 'contribution/list'; // Cannot view contributions list through api
    this.arena.createArenaSig(contributionListUrl, apiSession);
    const fundList = 'fund/list'; // Cannot view list through api
    this.arena.createArenaSig(fundList, apiSession);
  }

  async getData(showloading: boolean = true, event: any = null) {
    if (showloading) {
      this.messages.showLoading('Refreshing your data...', false, 2000);
    }
    this.userService.getAPIUser('session', this.userSession);
    this.userService.biometricGetType().then(res => {this.biometricType = res;});
    if (event) {
      setTimeout(() => {
        console.log('Async operation has ended');
        event.target.complete();
      }, 2000);
    }
  }

  // async editProfile() {
  //   this.router.navigate(['account/edit'], {state: {
  //     entityBundle: environment.checkInType.bundle,
  //     entityType: environment.checkInType.entityType,
  //     loadedEntity: this.userSession.user,
  //     pageTitle: 'Profile',
  //     session: this.userSession
  //   }});
  // }

  async editProfile() {
    const loadedEntity = {
      field_student_registration: '',
      location: '',
      room_number: ''
    };
    const fieldOpts: FormFieldOptions = {};
    const modal = await this.modalCtrl.create({
        component: FormInlineComponent,
        componentProps: {
            entityType: 'user',
            entityBundle: 'user_d7',
            viewMode: 'form',
            formTitle: 'Profile',
            userSession: this.userSession,
            showSegment: false,
            formSubmitLabel: 'Submit',
            loadedEntity: this.userSession.user,
            fieldOpts,
            formCallback: 'getInternalForm'
        }
    });
    await modal.present();
    await modal.onDidDismiss().then(res => {
      console.log(res);
        if (res.role === 'success') {
        }
    });
  }

  async supportPage() {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    this.messages.showLoading('Loading the support form...', false, 1500);
    const event = await this.auth.get(environment.churchURLS.Support.URL, options).toPromise().then(res => res.events[0]);
    const extras = {session: this.userSession, customer: this.customer, event};
    this.router.navigate(['support'], {state: {
      extras
    }});
  }

  navigateToPage(url: string, extras?: any) {
    if (extras) {
      this.router.navigate([url], {state: {
          extras
      }});
    } else {
      this.router.navigate([url]);
    }
  }

  externalLink(url: string) {
    Browser.open({url});
  }

  async logout() {
    await this.userService.logout().then(res => {
      console.log(res);
    });
  }

}
