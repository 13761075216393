import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { CommerceProduct } from '../models';
import { ServicesArguments } from '../models/view';

/**
 * node service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class CommerceProductService extends MainService {
  override entityType = 'entity_commerce_product';

  /**
   * impelement index resource
   *
   * @return array of products
   */
  getAllProducts(options?: ServicesArguments): Promise<CommerceProduct[]> {
    if (!options) {
      return this.get();
    }

    const filters = this.getArgs(options);
    return this.get(filters);
  }

  /**
   * implement retrieve resource
   *
   * @param productId product id to retrieve
   * @return object of the product entity
   */
  getProductById(productId: number): Promise<CommerceProduct> {
    return this.get(productId);
  }

  /**
   * implement create resource
   *
   * @param product payment transaction entity to create
   * @return created payment transaction details
   */
  createProduct(product: CommerceProduct): Promise<CommerceProduct> {
    return this.post(product);
  }

  /**
   * implement update resource
   *
   * @param product payment transaction to update
   * @return updated payment transaction details
   */
  updateProduct(product: {productId: number}): Promise<CommerceProduct> {
    return this.put(product, product.productId);
  }

  /**
   * implement delete resource
   *
   * @param productId payment transaction id to delete
   * @return array true if payment transaction deleted, otherwise return false array
   */
  deleteProduct(productId: number): Promise<boolean[]> {
    return this.delete(productId);
  }

}
