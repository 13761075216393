import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from '../services/auth.service';
import { MessagesService } from '../services/messages.service';
import { StorageService } from '../services/storage/storage.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UserServiceCustom } from '../services/user.service';
import { SystemConnection } from '../services/drupal7/public_api';

@Component({
  selector: 'app-events',
  templateUrl: './events.page.html',
  styleUrls: ['./events.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class EventsPage implements OnInit {

  events: any;
  fakeEvents: Array<any> = new Array(3);
  session: SystemConnection;
  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(
    public router: Router,
    private auth: AuthService,
    private user: UserServiceCustom,
    private messages: MessagesService,
    public storage: StorageService) {
        this.getData();
     }

  ngOnInit() {
    this.user.currentSession.subscribe(session => this.session = session);
  }

  getData(showLoading: boolean = true, event: any = null) {
    if (showLoading) {
      this.messages.showLoading('Loading the events...', false, 1500);
    }
    this.auth.get(environment.churchURLS.WebformEventsUrl, this.options).subscribe(data => {
      this.events = data.events;
      this.getUserWebformSubmissions(this.events);
    });
    if (event) {
      setTimeout(() => {
        console.log('Async operation has ended');
        event.target.complete();
      }, 2000);
    }
  }

  navigateToPage(event: any) {
    const extras = {event, session: this.session};
    this.router.navigate(['events/' + event.nid], {state: {
        extras
    }});
  }

  async getUserWebformSubmissions(events: any) {
    const session = await this.storage.get('session');
    const options = this.auth.setupRequestOptions('csrf', session);
    await this.auth.get(environment.views.webformSubmissions + session.user.uid, options).toPromise()
    .then(submissions => {
      if (submissions) {
        events.map(event => event.registered = submissions.filter(submission => submission.uuid === event.uuid).length ? true : false);
      }
      return submissions;
    },
    error => {
      console.log('Error', error);
      return error;
    });
  }



}
