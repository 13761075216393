import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { CommerceService } from '../../services/commerce.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DrupalEntityBundleInfo, DrupalFormControlObject, EntityService } from '../../services/entity.service';
import { AlertController, IonicModule, ModalController } from '@ionic/angular';
import { FormsService } from '../../services/forms.service';
// Import JSON Files
import AdministrativeArea from '../../../assets/address/administrative_area.json';
import USStates from '../../../assets/address/us_states.json';
import { MessagesService } from '../../services/messages.service';
import { environment } from '../../../environments/environment.prod';
import { Navigation, Router } from '@angular/router';
import { StorageService } from '../../services/storage/storage.service';
import { format, parseISO } from 'date-fns';
import { CommonModule } from '@angular/common';
import { SystemConnection } from '../../services/drupal7/public_api';
import { UserServiceCustom } from '../../services/user.service';

@Component({
  selector: 'app-checkout-form',
  templateUrl: './checkout-form.page.html',
  styleUrls: ['./checkout-form.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
  ],
})
export class CheckoutFormPage implements OnInit {

  @Input() entityType: string;
  @Input() entityBundle: string;
  @Input() requestOptions: any;
  @Input() pageTitle = 'Payment';
  @Input() orderTotal: string;
  @Output() checkout: EventEmitter<any> = new EventEmitter();
  session: SystemConnection;

  form: FormGroup;
  bundleInfo: DrupalEntityBundleInfo;
  formFields: Array<DrupalFormControlObject>;
  nestedFieldGroups: Array<DrupalFormControlObject>;
  activeIndex: number;
  deviceLinked = false;
  deviceNumber: object;

  formSubmitLabel: string;
  formPreviewLabel: string;

  administrativeArea = AdministrativeArea;
  dynamicAdministrativeArea: any;
  states: object;
  minDate = '1900';
  maxDate = (new Date().getFullYear() + 3).toString();
  order = null;

  constructor(
    private fb: FormBuilder,
    private alertCtrl: AlertController,
    private auth: AuthService,
    private commerce: CommerceService,
    private modalController: ModalController,
    public message: MessagesService,
    private user: UserServiceCustom,
    private router: Router,
    private entity: EntityService,
    private forms: FormsService,
    public storage: StorageService
  ) {
    const nav: Navigation = this.router.getCurrentNavigation();

    if (nav?.extras && nav.extras.state) {
      this.entityType = nav.extras.state.entityType;
      this.session = nav.extras.state.session;
      this.requestOptions = nav.extras.state.requestOptions;
      this.entityBundle = nav.extras.state.entityBundle;
      this.pageTitle = nav.extras.state.pageTitle;
    }

    // this.appUser = this.user.getAppUser();
    this.dynamicAdministrativeArea = USStates;
  }

  get errorControl() {
    return this.form.controls;
  }


  ngOnInit() {
    this.entityType = this.entityType ? this.entityType : environment.checkInType.entityType;
    this.entityBundle = this.entityBundle ? this.entityBundle : environment.checkInType.bundle;
    console.log(this.entityType);
    this.user.currentSession.subscribe(session => {
      this.session = session;
      this.getForm(this.entityType, this.entityBundle, 'Save');
    });
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(()=>resolve(), ms));
  }

   async getAutoCompleteOptions(e: any, field: DrupalFormControlObject) {
    return this.forms.getAutoCompleteOptions(e, field, this.requestOptions);
  }

  getForm(entityType, entityBundle,
    entitySubmitLabel = 'Submit', previewLabel = 'Preview', externalSource = false, viewMode = 'form', extra = 'none') {

    this.forms.getForm(this.session, entityType,
      entityBundle, entitySubmitLabel, previewLabel, externalSource, viewMode, extra).then(res => {
        console.log(res);
      this.activeIndex = res.activeIndex;
      this.formFields = res.formFields;
      // Add the year options to the card
      this.auth.addOptions(this.formFields, ['exp_year'], this.auth.generateArrayOfYears(0, 10));
      this.form = res.formGroup;
      this.nestedFieldGroups = res.nestedFieldGroups;
      this.entityBundle = res.entityBundle;
      this.bundleInfo = res.bundleInfo;
      this.entityType = res.entityType;
      this.formPreviewLabel = res.formPreviewLabel;
      this.formSubmitLabel = res.formSubmitLabel;
    });
  }

  goToFieldGroup(i) {
    this.forms.goToFieldGroup(i, this.form, this.formFields);
  }

  addArrayField(arrayName) {
    this.forms.addArrayField(arrayName, this.form);
  }

  addArrayGroup(arrayName, fields) {
    this.forms.addArrayGroup(arrayName, fields, this.form);
  }

  removeArrayGroup(arrayName, index) {
    this.forms.removeArrayGroup(arrayName, index, this.form);
  }

  getFormArray(key) {
    return this.forms.getFormArray(key, this.form);
  }

  conditionalField(event, dependeeField) {
    this.forms.conditionalField(event, dependeeField, this.form, this.formFields);
  }

  updateAdministrativeAreas(country, options, addressField) {
    this.dynamicAdministrativeArea = this.forms.updateAdministrativeAreas(country, options, addressField, this.dynamicAdministrativeArea);
  }

  pushCheckout() {
    this.checkout.emit(this.form.value);
  }

  resetForm() {
    this.form = this.fb.group({});
    this.formFields = null;
  }

  cancelModal() {
    this.modalController.dismiss();
  }

}
