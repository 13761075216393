import { HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { ActionSheetController, IonRange, IonicModule, ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';
import { Sermon } from '../media/media.page';
import { AuthService } from '../services/auth.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { YouTubePlayerModule } from '@angular/youtube-player';
export enum mediaTypes {
  audio,
  video,
  youtube
}

declare const YT: any;

@Component({
  selector: 'app-media-player',
  templateUrl: './media-player.component.html',
  styleUrls: ['./media-player.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    YouTubePlayerModule,
    FormsModule,
    IonicModule,
  ],
})
export class MediaPlayerComponent implements OnInit, AfterViewInit {

  @ViewChild('range', {static: false}) range: IonRange;
  @ViewChild('currVideo') currVideo: ElementRef;
  @Input() sermon: Sermon;
  @Input() sermons: Array<Sermon>;
  @Input() url: string;
  @Input() currentNid: string;
  @Input() prevSermon: Sermon;
  @Input() nextSermon: Sermon;
  sermonsInSeries: Array<Sermon>;
  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  progress = 0;
  miniPlayer = false;
  isPlaying = false;
  isTouched = false;
  currSecsText: string;
  intervalId: number;
  intervalTimer: any;
  durationText: string;
  currRangeTime: number;
  currentSpeed = 1;
  maxRangeValue: number;
  mediaType: mediaTypes = mediaTypes.youtube;
  // currAudio: HTMLAudioElement = new Audio();
  // youtubeVideo: any;

  currMedia: any;

  private apiLoaded = false;

  constructor(private actionSheetCtrl: ActionSheetController,
    private router: Router,
    public modalController: ModalController,
    public auth: AuthService) {
      const nav: Navigation = this.router.getCurrentNavigation();
      this.url = this.router.url;
      this.currentNid = this.url.split('/')[3];

      if (nav?.extras && nav.extras.state) {
        this.sermon = nav.extras.state.sermon;
        this.sermons = nav.extras.state.sermons;
        this.getSermonsInSeries();
      }

    }

  ngOnInit() {
    this.loadYoutubeIframe();
  }

  ngAfterViewInit() {
    // this.playMedia(this.sermon);
    this.getPrevAndNext(this.sermon);
  }

  getSermonsInSeries() {
    const url = `${environment.churchURLS.SermonUrl}all/all/all/all/${this.sermon.sermon_series_id}`;
    this.auth.get(url, this.options).subscribe(data => {
      this.sermonsInSeries = data;
      for (const sermon of data) {
        sermon.embedded_video = this.getVideoEmbeds(sermon);
        if (sermon.tags) {
          sermon.tags = sermon.tags.split(',');
        }
      }
    });
  }

  getVideoEmbeds(sermon: Sermon) {
    if (sermon.video) {
      if (sermon.video.includes('v=')) {
        sermon.youtube_video_id = sermon.video.split('v=')[1].split('&')[0];
        return `https://www.youtube.com/embed/${sermon.video.split('v=')[1].split('&')[0]}`;
      }
      if (sermon.video.includes('tu.be/')) {
        sermon.youtube_video_id = sermon.video.split('tu.be/')[1];
        return `https://www.youtube.com/embed/${sermon.video.split('tu.be/')[1]}`;
      }
    }
    if (sermon.sermon_video_vimeo) {
      return sermon.sermon_video_vimeo;
    }
  }

  getPrevAndNext(sermon: Sermon) {
    if (sermon) {
      const index = this.sermons.findIndex(x => x.title === this.sermon.title);
      this.nextSermon = index + 1 === this.sermons.length ? this.sermons[0] : this.sermons[index +1];
      this.prevSermon = index === 0 ? this.sermons[this.sermons.length - 1] : this.sermons[index - 1];
      this.getSermonsInSeries();
    }
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Playback Speed',
      buttons: [
        { data: .25, text: '.25x', role: 'submit'},
        { data: .5, text: '.5x', role: 'submit'},
        { data: .75, text: '.75x', role: 'submit'},
        { data: 1, text: '1x', role: 'submit'},
        { data: 1.25, text: '1.25x', role: 'submit'},
        { data: 1.5, text: '1.5x', role: 'submit'},
        { data: 1.75, text: '1.75x', role: 'submit'},
        { data: 2, text: '2x', role: 'submit'},
        { data: 2.5, text: '2.5x', role: 'submit'},
        { data: 3, text: '3x', role: 'submit'},
        { data: 3.5, text: '3.5x', role: 'submit'},
        { data: 4, text: '4x', role: 'submit'},
        { data: 'cancel', text: 'Cancel', role: 'cancel',},
      ],
    });
     actionSheet.present();
     actionSheet.onDidDismiss().then(res => {
      if (res.role !== 'cancel') {
        this.currentSpeed = res.data;
        if (this.mediaType === mediaTypes.audio) {
          this.currMedia.playbackRate = this.currentSpeed;
        }
        if (this.mediaType === mediaTypes.video) {
          this.currMedia.playbackRate = this.currentSpeed;
        }
        if (this.mediaType === mediaTypes.youtube) {
          this.currMedia.setPlaybackRate(this.currentSpeed);
        }
      }
     });
  }

  closeExtraOptions() {
    this.modalController.dismiss(null, 'cancel');
  }

  playFromSeriesLegacy(sermon: Sermon, sermons: Array<Sermon>) {
    this.sermon = sermon;
    this.sermons = sermons;
    this.getPrevAndNext(this.sermon);
    this.playMedia(this.sermon);
  }

  externalLink(url: string) {
    Browser.open({url});
  }

  toggleMediaModeLegacy() {
    console.log(this.currMedia);
    if (this.mediaType === mediaTypes.youtube && this.currMedia) {
      this.currMedia.pauseVideo();
    } else {
      this.currMedia.pause();
    }
    this.mediaType = this.mediaType === mediaTypes.youtube ? mediaTypes.audio : this.mediaType + 1;
    if (this.mediaType === mediaTypes.audio) {
      this.currMedia = new Audio();
      this.currMedia.load();
      this.currMedia = new Audio(this.sermon.audio_fallback);
      this.setupSlider(this.currMedia);
    }
    if (this.mediaType === mediaTypes.video) {
      this.currMedia = this.currVideo.nativeElement;
      this.setupSlider(this.currMedia);
    }
    if (this.mediaType === mediaTypes.youtube) {
      this.loadYoutubeVideo(this.sermon);
      if (this.currMedia) {
        this.currMedia.loadVideoById(this.sermon.youtube_video_id, 0.1);
      }
    }
  }

  toggleMediaMode(val: number) {
    this.mediaType = val;
    if (this.mediaType === mediaTypes.audio) {
      this.currMedia = new Audio();
      this.currMedia.load();
      this.currMedia = new Audio(this.sermon.audio_fallback);
      this.setupSlider(this.currMedia);
    }
    if (this.mediaType === mediaTypes.youtube) {
      this.currMedia?.pause();
      this.isPlaying = false;
    }
  }

  checkMediaType() {
    if (this.mediaType === mediaTypes.audio) {
      return 'videocam';
    }
    if (this.mediaType === mediaTypes.video) {
      return 'logo-youtube';
    }
    if (this.mediaType === mediaTypes.youtube) {
      return 'headset';
    }
  }

  setupSlider(media: any) {
    console.log(this.sermon);
    media.play().then(() => {
      this.durationText = this.sToTime(media.duration);
      this.maxRangeValue = Number(media.duration.toFixed(2).toString().substring(0, 5));
      media.playbackRate = this.currentSpeed;
      // this.sermonsInSeries = null;
      // this.getSermonsInSeries();
      this.isPlaying = true;
    }, err => {
      console.log(`Error playing ${this.sermon.title}`, err);
    });

    media.addEventListener('timeupdate', () => {
      if (!this.isTouched) {
        this.currRangeTime = Number(media.currentTime.toFixed(2).toString().substring(0, 5));
        this.currSecsText = this.sToTime(media.currentTime);
        this.progress = (Math.floor(media.currentTime) / Math.floor(media.duration));
        if (media.currentTime === media.duration) {
          this.playNext();
        }
      }
    });
  }

  playMediaLegacy(sermon: Sermon) {
    if (sermon) {
      const index = this.sermons.findIndex(x => x.title === this.sermon.title);
      this.nextSermon = index + 1 === this.sermons.length ? this.sermons[0] : this.sermons[index +1];
      this.currMedia?.pause();

      if (this.mediaType === mediaTypes.audio) {
        this.currMedia = new Audio();
        this.currMedia.load();
        this.currMedia = new Audio(sermon.audio_fallback);
        this.setupSlider(this.currMedia);
      }
      if (this.mediaType === mediaTypes.video) {
        this.currMedia = this.currVideo.nativeElement;
        this.currMedia.load();
        this.currMedia.src = sermon.sermon_video_fallback;
        this.setupSlider(this.currMedia);
      }
      if (this.mediaType === mediaTypes.youtube && sermon.youtube_video_id) {
         this.loadYoutubeVideo(sermon);
         if (this.currMedia) {
          console.log(this.currMedia);
           this.currMedia.loadVideoById(sermon.youtube_video_id, 0.1);
         }
      }
    }
  }

  playMedia(sermon: Sermon) {
    if (sermon) {
      this.currMedia?.pause();
      this.isPlaying = false;

      if (this.mediaType === mediaTypes.audio) {
        this.currMedia = new Audio();
        this.currMedia.load();
        this.currMedia = new Audio(sermon.audio_fallback);
        this.setupSlider(this.currMedia);
      }
    }
  }


  sToTime(t: number) {
    return this.padZero(parseInt(String((t / (60)) % 60), 10)) + ':' + this.padZero(parseInt(String((t) % 60), 10));
  }

  padZero(v: number) {
    return (v < 10) ? '0' + v : v;
  }

  play() {
    if (this.mediaType === mediaTypes.audio && this.currMedia.paused && !this.isPlaying) {
      this.currMedia.play();
    }
    if (this.mediaType === mediaTypes.video && this.currMedia.paused && !this.isPlaying) {
      this.currMedia.play();
    }
    if (this.mediaType === mediaTypes.youtube && !this.isPlaying && this.currMedia) {
      this.currMedia.playVideo();
    }
    this.isPlaying = true;
  }

  pause() {
    if (this.mediaType === mediaTypes.audio && !this.currMedia.paused && this.isPlaying) {
      this.currMedia.pause();
    }
    if (this.mediaType === mediaTypes.video && !this.currMedia.paused && this.isPlaying) {
      this.currMedia.pause();
    }
    if (this.mediaType === mediaTypes.youtube && this.isPlaying && this.currMedia) {
      this.currMedia.pauseVideo();
    }
    this.isPlaying = false;
  }

  playNext() {
    const index = this.sermons.findIndex(x => x.title === this.sermon.title);
    this.sermon = (index + 1) === this.sermons.length ? this.sermons[0] : this.sermons[index + 1];
    this.playMedia(this.sermon);
  }

  playPrev() {
    this.pause();
    const index = this.sermons.findIndex(x => x.title === this.sermon.title);
    this.sermon = index === 0 ? this.sermons[this.sermons.length - 1] : this.sermons[index - 1];
    this.playMedia(this.sermon);
  }

  showNext() {
    this.pause();
    const index = this.sermons.findIndex(x => x.title === this.sermon.title);
    this.sermon = (index + 1) === this.sermons.length ? this.sermons[0] : this.sermons[index + 1];
    this.getPrevAndNext(this.sermon);
    this.playMedia(this.sermon);
  }

  showPrev() {
    const index = this.sermons.findIndex(x => x.title === this.sermon.title);
    this.sermon = index === 0 ? this.sermons[this.sermons.length - 1] : this.sermons[index - 1];
    this.getPrevAndNext(this.sermon);
    this.playMedia(this.sermon);
  }

  playFromSeries(sermon: Sermon, sermons: Array<Sermon>) {
    this.sermon = sermon;
    // this.sermons = sermons;
    this.getPrevAndNext(this.sermon);
    this.playMedia(this.sermon);
  }

  playheadFormatter(value: number) {
    const minutes = Number((value / 60).toFixed(2).substring(0, 2));
    const newMinutes = (minutes < 10) ? '0' + minutes : minutes;
    const seconds = Number((60 * Number((value / 60).toFixed(3).substring(2, 4))).toFixed(2).substring(0, 2));
    const newSeconds = (seconds < 10) ? '0' + seconds : seconds;
    return `${newMinutes}:${newSeconds}`;
  }

  cancel() {
    this.progress = 0;
    if (this.mediaType === mediaTypes.audio) {
      this.currMedia.pause();
      this.currMedia = new Audio();
    }
    if (this.mediaType === mediaTypes.video) {
        this.currVideo.nativeElement.pause();
        this.currVideo.nativeElement.src = null;
    }
    this.isPlaying = false;
  }

  closeModal() {
    this.modalController.dismiss(null, 'cancel').then(res => {
      console.log(res);
      this.currMedia?.pause();
    });
  }

  touchStart() {
    this.isTouched = true;
    this.currRangeTime = Number(this.range.value);
  }

  touchEnd() {
    this.isTouched = false;
    if (this.mediaType === mediaTypes.audio) {
      this.updateSliderText(this.currMedia);
    }
    if (this.mediaType === mediaTypes.video) {
      this.updateSliderText(this.currVideo.nativeElement);
    }
    if (this.mediaType === mediaTypes.youtube) {
      this.currMedia.seekTo(Number(this.range.value));
      this.currSecsText = this.sToTime(this.currMedia.getCurrentTime());
      this.currRangeTime = Number(this.range.value);
    }
  }

  updateSliderText(media: any) {
    media.currentTime = Number(this.range.value);
    this.currSecsText = this.sToTime(media.currentTime);
    this.currRangeTime = Number(media.currentTime.toFixed(2).toString().substring(0, 5));
  }

  loadYoutubeIframe() {
    if(!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
  }

  loadYoutubeVideo(sermon: Sermon) {
    (window as any).onYouTubeIframeAPIReady = () => {
      new (window as any).YT.Player('youtubeVideo', {
        height: '1080',
        width: '1920',
        videoId: sermon.youtube_video_id,
        playerVars: {autoplay: 1, rel: 0, controls: 1},
        events: {
          onReady: (event: any) => {
            this.currMedia = event.target;
          },
          onStateChange: (event: any) => {
            // this.handleStateChange(event);
          },
        }
      });
    };
  }

  // setupYoutubeVideo(e: any) {
  //   if (this.currMedia) {
  //     console.log(this.currMedia.getCurrentTime());
  //     this.durationText = this.sToTime(this.currMedia.playerInfo.duration);
  //     this.maxRangeValue = Number(this.currMedia.playerInfo.duration.toFixed(2).toString().substring(0, 5));
  //     this.currMedia.setPlaybackRate(this.currentSpeed);
  //     this.sermonsInSeries = null;
  //     this.getSermonsInSeries();
  //     this.currMedia.playVideo();
  //     if (!this.isTouched && this.isPlaying) {
  //       this.currRangeTime = Number(this.currMedia.getCurrentTime().toFixed(2).toString().substring(0, 5));
  //       console.log(this.currRangeTime);
  //       this.currSecsText = this.sToTime(this.currMedia.getCurrentTime());
  //       this.progress = (Math.floor(this.currMedia.getCurrentTime()) / Math.floor(this.currMedia.playerInfo.duration));
  //       if (this.currMedia.getCurrentTime() === this.currMedia.playerInfo.duration) {
  //         this.playNext();
  //       }
  //     }
  //   }
  // }

  // handleStateChange(e: any) {
  //   if (e.data === YT.PlayerState.PLAYING) {
  //     this.intervalId = window.setInterval(this.updateTime, 1000, e);
  //   } else {
  //       window.clearTimeout(this.intervalId);
  //   }
  // }

  // updateTime(e: any) {
  //   if (!this.isTouched && this.isPlaying) {
  //     const currentTime = e.target.getCurrentTime();
  //     if (this.currRangeTime !== currentTime) {
  //       this.currRangeTime = Number(e.target.getCurrentTime().toFixed(2).toString().substring(0, 5));
  //       const parsedMinute = parseInt(String((e.target.getCurrentTime() / (60)) % 60), 10);
  //       const parsedSeconds = parseInt(String((e.target.getCurrentTime()) % 60), 10);
  //       const paddedMinute = (parsedMinute < 10) ? '0' + parsedMinute : parsedMinute;
  //       const paddedSeconds = (parsedSeconds < 10) ? '0' + parsedSeconds : parsedSeconds;
  //       const time = paddedMinute + ':' + paddedSeconds;
  //       this.currSecsText = time;
  //       this.progress = (Math.floor(e.target.getCurrentTime()) / Math.floor(e.target.playerInfo.duration));
  //       if (e.target.getCurrentTime() === e.target.playerInfo.duration) {
  //         this.playNext();
  //       }
  //     }
  //     // await new Promise<void>(resolve => setTimeout(()=>resolve(), 1000));
  //     // this.updateTime(e);
  //   }
  // }

}
