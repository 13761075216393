// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-description {
  color: var(--ion-color-medium);
  line-height: 1.5rem;
  font-size: 1.1rem;
}

a {
  text-decoration: none;
  color: var(--ion-color-medium);
}`, "",{"version":3,"sources":["webpack://./src/app/support/support.page.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,mBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,8BAAA;AACJ","sourcesContent":[".event-description {\n    color: var(--ion-color-medium);\n    line-height: 1.5rem;\n    font-size: 1.1rem;\n}\n\na {\n    text-decoration: none;\n    color: var(--ion-color-medium);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
