import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { NodeEntity, TaxonomyTerm } from '../models';
import { ServicesArguments } from '../models/view';

/**
 * taxonomy term service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class TaxonomyTermService extends MainService {
  override entityType = 'taxonomy_term';

  /**
   * implement index resource
   *
   * @return array of terms
   */
  getAllTaxonomyTerms(options?: ServicesArguments): Promise<TaxonomyTerm[]> {
    if (!options) {
      return this.get();
    }

    const filters = this.getArgs(options);
    return this.get(filters);
  }

  /**
   * implement retrieve resource
   *
   * @param tid term id
   * @return term object
   */
  getTaxonomyTermById(tid: number): Promise<TaxonomyTerm> {
    return this.get(tid);
  }

  /**
   * implement create resource
   *
   * @param taxonomyTerm the term to create
   * @return array number for the wight of the term
   */
  createTaxonomyTerm(taxonomyTerm: TaxonomyTerm): Promise<number[]> {
    return this.post(taxonomyTerm);
  }

  /**
   * implement update resource
   *
   * @param taxonomyTerm term to update
   * @return array number for the wight of the term
   */
  updateTaxonomyTerm(taxonomyTerm: TaxonomyTerm): Promise<number[]> {
    return this.put(taxonomyTerm, taxonomyTerm.tid);
  }

  /**
   * implement delete resource
   *
   * @param tid term id to delete
   * @return array of deleted term id
   */
  deleteTaxonomyTerm(tid: number): Promise<number[]> {
    return this.delete(tid);
  }

  /**
   * implement selectNodes
   *
   * @param tid term id
   * @return array of nodes that referenced this term in one of the fields
   */
  getAllNodesForTerm(tid: number, options?: ServicesArguments): Promise <NodeEntity[]> {
    if (!options) {
      return this.post({tid}, `selectNodes`);
    }
    
    const filters = this.getArgs(options);
    return this.post({tid}, `selectNodes${filters}`);

  }
}
