import { CommonModule, formatCurrency } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, Navigation } from '@angular/router';
import { SMS } from '@awesome-cordova-plugins/sms/ngx';
import { Browser } from '@capacitor/browser';
import { IonicModule, Platform, } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from '../../services/auth.service';
import { DrupalEntityBundleInfo, FieldGroup, WebformFieldControlObject } from '../../services/entity.service';
import { EventsService } from '../../services/events.service';
import { MessagesService } from '../../services/messages.service';
import { StorageService } from '../../services/storage/storage.service';
import { StripeService } from '../../services/stripe.service';
import AdministrativeArea from '../../../assets/address/administrative_area.json';
import USStates from '../../../assets/address/us_states.json';
import { DrupalFormControlObject, SystemConnection } from '../../services/drupal7/public_api';
import { UserServiceCustom } from '../../services/user.service';
import { FormsService } from 'src/app/services/drupal-forms/forms.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.page.html',
  styleUrls: ['./event.page.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
  ],
})
export class EventPage implements OnInit {

  private formsService = inject(FormsService);
  private auth = inject(AuthService);
  private router = inject(Router);
  private sms = inject(SMS);
  private userService = inject(UserServiceCustom);
  private storage = inject(StorageService);
  public stripeService = inject(StripeService);
  public events = inject(EventsService);
  public platform = inject(Platform);
  private messages = inject(MessagesService);


  @Input() event: any;
  @Input() url: string;
  @Input() currentNid: string;
  userSession: SystemConnection;
  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  registered = false;
  registeredMessage: string;
  webformFields: any;
  entityformFields: any;
  conditionalFields: any;
  entityformData: any;
  connectionGroups = [];
  formSubmissionData = {};
  formSubmitLabel = 'Submit';
  enableForm = false;
  formLoaded = false;
  webform: any;
  customer: any;
  eventCost = 0;
  formattedEventCost: string;

  form: FormGroup;
  bundleInfo: DrupalEntityBundleInfo;
  formFields: DrupalFormControlObject[];
  nestedFieldGroups: DrupalFormControlObject[];
  administrativeArea = AdministrativeArea;
  administrativeAreas: any;
  dynamicAdministrativeArea: any;

  constructor(
  ) {
    const nav: Navigation = this.router.getCurrentNavigation();
    this.url = this.router.url;
    this.currentNid = this.url.split('/')[3];
    if (nav?.extras && nav.extras.state) {
      this.event = nav.extras.state.extras.event;
      this.enableForm = this.event.exclude_registration !== 'Yes' ? true : false;
      if (this.event.entityform && this.enableForm) {
          this.setupEntityform();
      } else if (!this.event.entityform && this.enableForm) {
          this.setupWebform();
      }
    } else {
      this.getData();
    }
    this.dynamicAdministrativeArea = USStates;

   }

  ngOnInit(): void {
    this.userService.currentSession.subscribe(session => {
      if (session.user.uid) {
        this.userSession = session;
      }
    });
  }

  navigateToPage(url: string) {
    this.router.navigate([url], {state: {
      pageTitle: 'Create Account'
    }});
  }

  externalLink(url: string) {
    Browser.open({url});
  }

  sendSMS(phoneNumber: any) {
    this.sms.send(phoneNumber, 'Give',{replaceLineBreaks: true, android: {intent: 'INTENT'}});
  }

  async getData(showLoading: boolean = false) {
    this.userSession = await this.storage.get('session');

    if (showLoading) {
      this.messages.showLoading('Loading the data...', false, 1500);
    }
    this.auth.get(environment.churchURLS.WebformEventsUrl, this.options).subscribe(data => {
      this.event = data.events.filter(x => x.nid === +this.currentNid)[0];
      this.enableForm = this.event.exclude_registration !== 'Yes' ? true : false;
      console.log(this.event);
      if (this.event.entityform && this.enableForm) {
          this.setupEntityform();
      } else if (!this.event.entityform && this.enableForm) {
          this.setupWebform();
      }
    });
  }

  async setupEntityform() {
    if (!this.userSession) {
      this.userSession = await this.storage.get('session');
    }
    const entityForm = await this.formsService.getExternalForm(this.userSession, 'entityform', this.event.entityform, 'form', {});
    console.log(entityForm);
    this.form = entityForm.formGroup;
    this.nestedFieldGroups = entityForm.nestedFieldGroups;
    this.bundleInfo = entityForm.bundle_info;
    this.conditionalFields = entityForm.conditional_fields;
    this.formSubmitLabel = entityForm.bundle_info.bundle_info.data.submit_button_text ? entityForm.bundle_info.bundle_info.data.submit_button_text : 'Submit';
    this.administrativeAreas = entityForm.administrativeAreas;
    // Remove field groups from the form fields for now
    if (entityForm.form_fields[0] instanceof FieldGroup) {
      const fields = [];
      entityForm.form_fields.map(group => {
        group?.fields.map(field => {
          fields.push(field);
        });
      });
      this.formFields = fields;
    } else {
      this.formFields = entityForm.form_fields;
    }

    const entity = this.userSession.user ? this.userSession.user : this.userSession;
    this.formsService.loadEntity(entity, this.form, this.formFields, this.nestedFieldGroups, this.administrativeAreas);
    if (this.form.get('visit_phone_number')) {
      this.form.get('visit_phone_number').setValue(this.userSession.user.person_phone_number?.und[0].value);
    }
    if (this.form.get('connection_group')) {
      if (this.form.get('connection_group').value !== '') {
        this.form.get('in_connection_group').setValue('yes');
      }
    }
    this.formLoaded = true;
  }

  loadEntity() {
    const entity = this.userSession.user ? this.userSession.user : this.userSession;
    this.formsService.loadEntity(entity, this.form, this.formFields, this.nestedFieldGroups, this.administrativeAreas);
  }

  goToFieldGroup(i) {
    this.formsService.goToFieldGroup(i, this.form, this.formFields);
  }

  addArrayField(arrayName) {
    this.formsService.addArrayField(arrayName, this.form);
  }

  addArrayGroup(arrayName, fields) {
    this.formsService.addArrayGroup(arrayName, fields, this.form);
  }

  removeArrayGroup(arrayName, index) {
    this.formsService.removeArrayGroup(arrayName, index, this.form);
  }

  getFormArray(key) {
    return this.formsService.getFormArray(key, this.form);
  }

  conditionalField(event, dependeeField) {
    this.formsService.conditionalField(event, dependeeField, this.form, this.formFields, this.conditionalFields);
  }

  updateAdministrativeAreas(country, options, addressField) {
    this.dynamicAdministrativeArea = this.formsService.updateAdministrativeAreas(country, addressField, options);
  }

  async setupWebform() {
    this.events.getWebformFields(this.event).then(async (res) => {
      this.webform = res.webform;
      console.log(res);
      this.formSubmitLabel = this.webform.webform.submit_text ? this.webform.webform.submit_text : 'Submit';
      let i = 0;
      for (const field of res.fields) {
        field.required = field.required === '1' ? true : false;
        if (this.userSession) {
          if (field.form_key === 'first_name' && this.userSession.user.visit_first_name?.und) {field.value = this.userSession.user.visit_first_name?.und[0]?.value;this.formSubmissionData[i] = field.value;};
          if (field.form_key === 'last_name' && this.userSession.user.visit_last_name?.und) {field.value = this.userSession.user.visit_last_name?.und[0]?.value;this.formSubmissionData[i] = field.value;};
          if (field.form_key === 'email') {field.value = this.userSession.user.mail;this.formSubmissionData[i] = field.value;};
          if (field.form_key === 'phone' && this.userSession.user.person_phone_number?.und) {field.value = this.userSession.user.person_phone_number?.und[0]?.value;this.formSubmissionData[i] = field.value;};
          if (field.form_key === 'connection_group' && this.userSession.user.connection_group?.und) {field.value = this.userSession.user.connection_group?.und[0]?.target_id.toString();this.formSubmissionData[i] = field.value;};
          if (field.type === 'productfield') {
            if (!this.customer) {
              this.customer = await this.stripeService.getCardData(this.userSession, false);
            }
            if (this.webform.webform.submit_text) {
              this.formSubmitLabel = this.webform.webform.submit_text;
            } else {
              this.formSubmitLabel = 'Next';
            }
          };
        }
        i++;
      }
      this.webformFields = res.fields;

      const newFields = [];
      for (const field of res.fields) {
        if (field.type !== 'fieldset') {
          newFields.push(field);
        }
      }

      this.webformFields = newFields;
      console.log(this.webformFields);
      if (res.submissions) {
        const userSubmissions = res.submissions.filter(submission => submission.user === this.userSession.user.uuid);
        console.log(userSubmissions);
        if (userSubmissions.length) {
          const registeredMessage = `${this.userSession.user.visit_first_name?.und[0].value}, you're registered for ${this.event.title}.`;
          this.registeredMessage = 'You have already submitted this form.';
          this.registered = true;
        } else {
          this.formLoaded = true;
        }
      } else {
        this.formLoaded = true;
      }
    });
  }

  async submitEntityform() {
    this.messages.showLoading('Submitting your registration', false, 1500);
    this.form.value.event_to_register = this.event.nid;
    this.form.value.type = this.event.entityform;
    this.form.value.user = this.userSession.user.uid ? this.userSession.user.uid : environment.appSettings.AppUserUID;

    const body = this.form.value;
    body.created = Math.round((new Date()).getTime() / 1000);
    console.log(this.userSession);
    const appUser = await this.userService.getAppUser();

    const options = this.auth.setupRequestOptions('csrf', appUser);
    console.log(body);
    await this.events.createEntityformSubmission(body, options).then(data => {
      if (data.status !== 403) {
        this.messages.presentAlert('Registration Complete', 'Looking forward to seeing you at ' + this.event.title);
      } else {
        this.messages.presentAlert('Sorry', 'We had an issue registering you for ' + this.event.title + ' please try again.');
      }
    }, error => {
      this.messages.presentAlert('Fail', error);
    });
  }

  async submitWebform() {
    if (this.eventCost) {
      this.messages.showLoading('Preparing your order...', false, 2000);
    }
    let i = 0;
    const submissionData = {};
    this.eventCost = 0;
    for (const inputData of this.webformFields) {

      submissionData[inputData.cid] = {
        values: [this.formSubmissionData[i]]
      };

      if (inputData.type === 'productfield') {
        this.eventCost = this.addProductPrices(inputData, submissionData, i, this.eventCost);
      }

      if (inputData.type === 'select' && this.formSubmissionData[i]) {
        if (inputData.extra.multiple === 1) {
          submissionData[inputData.cid].values = this.formSubmissionData[i];
        } else {
          submissionData[inputData.cid].values = [this.formSubmissionData[i]];
        }
      }

      if (inputData.type === 'time' && this.formSubmissionData[i]) {
        submissionData[inputData.cid].values = [this.formSubmissionData[i] + ':00'];
      }

      if (inputData.type === 'number' && this.formSubmissionData[i]) {
        submissionData[inputData.cid].values = [this.formSubmissionData[i]];
      }

      i++;
    }
    const body = {
      webform: this.event.uuid,
      submission: {
        uid: this.userSession.user.uid ? +this.userSession.user.uid : +environment.appSettings.AppUserUID,
        data: submissionData
      },
      event_cost: this.eventCost.toString()
    };

      if (!this.eventCost) {
        const options = this.auth.setupRequestOptions('csrf', this.userSession);
        this.events.createWebformSubmission(body, options).then(submission => {
          if (submission) {
            const alertMessage = this.webform.webform.confirmation ? this.webform.webform.confirmation : 'Looking forward to seeing you at ' + this.event.title;
            this.messages.presentAlert('Registration Complete', alertMessage);
            this.router.navigate(['events'], {state: {}, replaceUrl: true});
          } else {
            this.messages.presentAlert('Sorry', 'We had an issue registering you for ' + this.event.title + ' please try again.');
          }
        }, err => {
          this.messages.presentAlert('Fail', err);
        });
      }

      if (this.eventCost) {
        this.router.navigate(['checkout'], {state: {
            event: this.event,
            webform: this.webform,
            webformSubmission: body,
            customer: this.customer
        }});
      }
  }

  async addToCart() {
    if (this.eventCost) {
      this.messages.showLoading('Adding to your cart...', false, 500);
    }
    let i = 0;
    const submissionData = {};
    this.eventCost = 0;
    for (const inputData of this.webformFields) {

      submissionData[inputData.cid] = {
        values: [this.formSubmissionData[i]]
      };

      if (inputData.type === 'productfield') {
        this.eventCost = this.addProductPrices(inputData, submissionData, i, this.eventCost);
      }

      if (inputData.type === 'select' && this.formSubmissionData[i]) {
        if (inputData.extra.multiple === 1) {
          submissionData[inputData.cid].values = this.formSubmissionData[i];
        } else {
          submissionData[inputData.cid].values = [this.formSubmissionData[i]];
        }
      }

      if (inputData.type === 'time' && this.formSubmissionData[i]) {
        submissionData[inputData.cid].values = [this.formSubmissionData[i] + ':00'];
      }

      if (inputData.type === 'number' && this.formSubmissionData[i]) {
        submissionData[inputData.cid].values = [this.formSubmissionData[i]];
      }

      i++;
    }
    const body = {
      id: this.event.uuid,
      submission: {
        uid: this.userSession.user.uid ? +this.userSession.user.uid : +environment.appSettings.AppUserUID,
        data: submissionData
      },
      event_cost: this.eventCost.toString(),
      event: this.event,
      webform: this.webform,
      customer: this.customer
    };

    await this.storage.storeWebformSubmission('cart', body);
  }

  async goToCart() {
    const cart = await this.storage.get('cart');
    this.router.navigate(['cart'], {state: {
        cart,
    }});
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  checkPrice(field: WebformFieldControlObject) {
    if (field.type === 'productfield' && !this.isEmptyObject(this.formSubmissionData)) {
      const submissionData = {};

      let i = 0;
      this.eventCost = 0;
      for (const inputData of this.webformFields) {
        submissionData[inputData.cid] = {
          values: [this.formSubmissionData[i]]
        };
        if (inputData.type === 'productfield') {
          this.eventCost = this.addProductPrices(inputData, submissionData, i, this.eventCost);
        }
        i++;
      }
      this.formattedEventCost = formatCurrency(this.eventCost / 100, 'en-US', '$');
      if (this.webform.webform.submit_text) {
        this.formSubmitLabel = this.webform.webform.submit_text + ' ' + this.formattedEventCost;
      } else {
        this.formSubmitLabel = `Continue to pay ${this.formattedEventCost}`;
      }
    }
  }

  addProductPrices(field: WebformFieldControlObject, submissionData: any, index: number, eventCost: number) {
    let productDetails: any;
    const productsDetails = [];
    const productsDetailsArray = [];
    if (field.extra.multiple === 1 && this.formSubmissionData[index]) {
      for (const product of this.formSubmissionData[index]) {
        productDetails = ({
          product_id: product,
          quantity: field.quantity,
          order_id: 'FALSE',
          line_item_id: 'FALSE',
          paid: 'FALSE',
        });
        productsDetailsArray.push(productDetails);
        productsDetails.push(JSON.stringify(productDetails));
      }
      submissionData[field.cid].values = productsDetails;
    } else {
      productDetails = ({
        product_id: this.formSubmissionData[index],
        quantity: field.quantity,
        order_id: 'FALSE',
        line_item_id: 'FALSE',
        paid: 'FALSE',
      });
      submissionData[field.cid].values = [JSON.stringify(productDetails)];
    }

    let final_cost = 0;
    for (const option of field.options) {
      if (field.extra.multiple === 1) {
        let subtotal = 0;
        for (const product of productsDetailsArray) {
          if (product.product_id === option.product_id) {
            subtotal += field.quantity * +option.price;
            eventCost += subtotal;
          }
        }
      } else {
        if (productDetails.product_id === option.product_id) {
          final_cost = field.quantity * +option.price;
          eventCost += final_cost;
        }
      }
    }
    this.formattedEventCost = formatCurrency(eventCost / 100, 'en-US', '$');
    return eventCost;
  }

  resetForm() {
    this.formSubmissionData = {};
    this.eventCost = 0;
    this.formattedEventCost = null;
    this.formSubmitLabel = this.webform.webform.submit_text ? this.webform.webform.submit_text : 'Register';
  }

}
