import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { BulkEditEntity, ToggleStatusEntity } from '../models';

/**
 * flag service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class VboService extends MainService {
  override entityType = 'vbo';


  /**
   * Bulk edit the status of an entity.
   *
   * @param body The toggle status object.
   * @return boolean; TRUE if content was flagged.
   */
  toggleStatus(body: ToggleStatusEntity): Promise<boolean> {
    return this.post(body, 'toggle_status');
  }

    /**
     * Bulk edit the status of an entity.
     *
     * @param body The toggle status object.
     * @return boolean; TRUE if content was flagged.
     */
     bulkEdit(body: BulkEditEntity): Promise<boolean> {
      return this.post(body, 'bulk_edit');
    }


}
