/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { StorageService } from './storage/storage.service';


export interface Options {
  key: string;
  value: string | Array<Options>;
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static readonly LOGIN_URL = 'https://account.lancasterbaptist.org/user/login?_format=json';
  static readonly PASSWORD_URL = 'https://account.lancasterbaptist.org/user/lost-password?_format=json';
  static readonly RESET_PASSWORD_URL = 'https://account.lancasterbaptist.org/user/lost-password-reset?_format=json';
  static readonly REGISTER_URL = 'https://account.lancasterbaptist.org/user/register?_format=json';
  static readonly LOGOUT_URL = 'https://account.lancasterbaptist.org/user/logout?_format=json';
  static readonly USER_URL = 'https://account.lancasterbaptist.org/user';
  static readonly JWT_URL = 'https://account.lancasterbaptist.org/jwt/token?_format=json';
  static readonly CSRF_URL = 'https://account.lancasterbaptist.org/session/token?_format=json';
  static readonly USER_ROLE_URL = 'https://account.lancasterbaptist.org/user-role/';

  // currentUser: User;
  // jwt: string;
  // csrf: string;
  // USERdata: any;


  constructor(
    private http: HttpClient,
    public storage: StorageService) { }


  setupRequestOptions(type: string, session: any, token: any = null) {
    switch (type) {
      case 'json':
        return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      case 'basic':
        const headersBasic = {
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + btoa(environment.appSettings.AppUser.username + ':' + environment.appSettings.AppUser.password)
        };
        const requestOptionsBasic = {headers: new HttpHeaders(headersBasic)};
        return requestOptionsBasic;
      case 'cookie':
        const headersCookie = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Cookie': session.session_name + '=' + session.sessid,
        };
        const requestOptions = {headers: new HttpHeaders(headersCookie)};
        return requestOptions;
      case 'csrf':
        const headersCSRF = {
          'Content-Type': 'application/json',
          'X-Cookie': session.session_name + '=' + session.sessid,
          'X-CSRF-Token': session.token
        };
        const requestOptions2 = {headers: new HttpHeaders(headersCSRF)};
        return requestOptions2;
      case 'bearer':
        const headersBearer = {
          Authorization: 'Bearer ' + token
        };
        const requestOptions3 = {headers: new HttpHeaders(headersBearer)};
        return requestOptions3;
      default:
        const headersDefault = {
          'Content-Type': 'application/json'
        };
        const defaultRequestOptions = {headers: new HttpHeaders(headersDefault)};
        console.log('no headers');
        return defaultRequestOptions;
    }
  }

  get(url: string, options: any): Observable<any> {
    return this.http.get(url, options).pipe(
    map(res => this.cleanObject(res)),
    catchError(err => of(err)));
  }

  post(url: string, body: any, options: any): Observable<any> {
    return this.http.post(url, body, options).pipe(
    map(res => res),
    catchError(async err => of(err)));
  }

  removeProps(obj, keys){
    if (Array.isArray(obj)) {
      obj.forEach(item => {
        this.removeProps(item, keys);
      });
    }
    else if (typeof obj === 'object' && obj !== null) {
      Object.getOwnPropertyNames(obj).forEach((key) => {
        if (keys.indexOf(key) !== -1) {
          delete obj[key];
        } else {
          this.removeProps(obj[key], keys);
        }
      });
    }
  }

  convertObjToArray(entity, keys) {
    Object.getOwnPropertyNames(entity).forEach(key => {
      if (keys.indexOf(key) !== -1) {
        const arr = [];
        Object.keys(entity[key]).map(k => {
          if (typeof(JSON.parse(k)) === 'number') {
            const v = entity[key][k];
            arr.push(v);
          }
        });
        entity[key] = arr;
      }
    });
  }

  convertObjToArrayKeyValue(obj) {
    const arr = [];
    Object.keys(obj).map(key => {
      const value = obj[key];
      return arr.push({key, value});
    });
    return arr;
  }


  /**
   * Generates a select list range of years based on user input
   *
   * @param startYear the start year which can be a relative year
   * @param endYear the ending year which can be a relative year
   * @param reverseOrder a boolean value to reverse the order of the returned options
   * @param startRelative a boolean value to determine if the startYear is a relative date or not.
   * Defaults to true because most often you aren't setting the startYear to a specific year.
   * @param endRelative a boolean value to determin if the endYear is a relative date or not.
   * Defaults to true because most often you aren't setting the endYear to a specific year.
   * @returns Returns an array of key/value pairs of select list options
   */
  generateArrayOfYears(startYear: number, endYear: number, reverseOrder?: boolean, startRelative: boolean = true, endRelative: boolean = true): Array<Options> {
    const years = [];
    const minDate = startRelative ? (new Date().getFullYear() - startYear) : startYear;
    const maxDate = endRelative ? (new Date().getFullYear() + endYear) : endYear;

    if (reverseOrder) {
      for (let i = maxDate; i >= minDate; i--) {
        years.push({key: i.toString(), value: i.toString()});
      }
    } else {
      for (let i = minDate; i <= maxDate; i++) {
        years.push({key: i.toString(), value: i.toString()});
      }
    }
    return years;
  }

  addOptions(obj, keys, options) {
    if (Array.isArray(obj)) {
      obj.forEach(item => {
        this.addOptions(item, keys, options);
      });
    }
    else if (typeof obj === 'object' && obj !== null) {
      Object.getOwnPropertyNames(obj).forEach((key) => {
        if (keys.indexOf(obj[key]) !== -1) {
          obj.options = options;
        } else {
          this.addOptions(obj[key], keys, options);
        }
      });
    }
  }

  cleanObject(obj: any) {
    if (obj) {
      Object.entries(obj).map(([k, v], i) => {
        let newVal = obj[k];
        if (typeof(obj[k]) === 'string') {
          newVal = obj[k].trim();
        try {
            newVal = JSON.parse(newVal);
            obj[k] = newVal;
            this.cleanObject(newVal);
          } catch (err) {
            // obj[k] = newVal;
          }
          if (typeof(newVal) === 'object' && !Array.isArray(newVal) && newVal !== null) {
            Object.entries(newVal).map(([nestedKey, nestedValue], x) => {
              if (k === nestedKey) {
                obj[k] = this.cleanObject(nestedValue);
              }
            });
          }
          if (typeof(newVal) === 'object' && Array.isArray(newVal) && newVal !== null && newVal.length === 1) {
            obj[k] = this.cleanObject(newVal);
          }
        } else if (typeof(v) === 'object' && Array.isArray(v) && v !== null && v.length === 1) {
            obj[k] = this.cleanObject(v);
        } else if (typeof(v) === 'object' && !Array.isArray(obj[k]) && obj[k] !== null) {
          this.cleanObject(obj[k]);
        } else if (typeof(v) === 'object' && Array.isArray(obj[k]) && obj[k] !== null && obj[k].length > 1) {
          for (const innerObj of obj[k]) {
            this.cleanObject(innerObj);
          }
        } else {
          this.cleanObject(newVal);
        }
      });
      return obj;
    }
  }

  setupChildcare(data: any) {
    for (const entity of data) {
      this.convertObjToArray(entity, environment.arrayFields);
    }
    return data;
  }

  setupQRCode(data: any) {
    for (const entity of data) {
      entity.qr_codes = [];
      if (entity.referenced_orders) {
        for (const order of entity.referenced_orders) {
          const qrCodeDataValue = `%id=${entity.nid}^lineitemid=${order.line_item_id}^orderid=${order.order_id}^uid=${order.uid}?`;
          const qrCodeData = {
            id: entity.nid,
            line_item_id: order.line_item_id,
            order_id: order.order_id,
            uid: order.uid,
            qr_code_value: qrCodeDataValue
          };
          entity.qr_codes.push(qrCodeData);
        }
      }
    }
    return data;
  }

  setupBadgeControls(data: any) {
    for (const entity of data) {
      entity.register_first_name_size = '22';
      entity.register_last_name_size = '22';
      entity.church_title_size = '10';
      entity.seating_assignment_original = entity.seating_assignment;
      entity.church_title = entity.church[0].title;
      entity.church_title_original = entity.church[0].title;
    }
    return data;
  }

  setupUniqueOrders(data: any) {
    for (const entity of data) {
      entity.unique_orders = [];
      if (entity.referenced_orders) {
        for (const order of entity.referenced_orders) {
          const index = entity.unique_orders.map((o) => o.order_id).indexOf(order.order_id);
          if (index === -1) {
            entity.unique_orders.push(order);
          }
        }
      }
    }
    return data;
  }

  setupPaidCount(data: any) {
    let paidCount = 0;
      data.map(e => {
        paidCount += e.flag_delegate_paid ? 1 : 0;
      });
      return paidCount;
  }


}
