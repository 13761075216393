import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { FileEntity, CommentEntity, NodeEntity, CreatedNode, FileAttach } from '../models';
import { ServicesArguments } from '../models/view';

/**
 * node service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class NodeService extends MainService {
  override entityType = 'node';

  /**
   * impelement index resource
   *
   * @return array of nodes
   */
  getAllNodes(options?: ServicesArguments): Promise<NodeEntity[]> {
    if (!options) {
      return this.get();
    }

    const filters = this.getArgs(options);
    return this.get(filters);
  }

  /**
   * implement retrieve resource
   *
   * @param nid node id to retrieve
   * @return object of the node entity
   */
  getNodeById(nid: number): Promise<NodeEntity> {
    return this.get(nid);
  }

  /**
   * implement create resource
   *
   * @param node node entity to create
   * @return created node details
   */
  createNode(node: NodeEntity): Promise<CreatedNode> {
    return this.post(node);
  }

  /**
   * implement update resource
   *
   * @param node node to update
   * @return updated node details
   */
  updateNode(node: {nid: number}): Promise<CreatedNode> {
    return this.put(node, node.nid);
  }

  /**
   * implement delete resource
   *
   * @param nid node id to delete
   * @return array true if node deleted, otherwise return false array
   */
  deleteNode(nid: number): Promise<boolean[]> {
    return this.delete(nid);
  }

  /**
   * implement files resource
   *
   * @param nid node id
   * @return array of files that attached to that node
   */
  files(nid: number): Promise<FileEntity[]> {
    return this.get(`${nid}/files`);
  }

   /**
    * implement comments resource
    *
    * @param nid node id
    * @return array of comments that attached to that node
    */
  comments(nid: number): Promise<CommentEntity[]> {
    return this.get(`${nid}/comments`);
  }

   /**
    * implement attach_files resource
    *
    * @param nid node id
    * @file object contains the field details and the file to upload
    * @return attached file
    */
  attachFilesToNode(nid: number, file: FileAttach): Promise<FileEntity> {
    return this.post(file, `${nid}/attach_file`);
  }

}
