import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { IonicModule, ModalController, Platform } from '@ionic/angular';
import { StorageService } from '../../services/storage/storage.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.page.html',
  styleUrls: ['./notification-center.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class NotificationCenterPage implements OnInit {

  @Input() notifications: PushNotificationSchema[] = [];
  @Input() modalMode = false;
  constructor(private modalController: ModalController,
    private platform: Platform,
    private router: Router,
    private storage: StorageService) { }

  async ngOnInit() {
    if (!this.notifications && this.platform.is('ios') || !this.notifications && this.platform.is('android')) {
      this.getNotifications();
    }
  }

  async ionViewWillEnter() {
    this.notifications = await this.storage.get('notifications');
  }

  async getNotifications() {
    PushNotifications.getDeliveredNotifications().then(async (res) => {
      if (res.notifications) {
        for (const notification of res.notifications) {
          this.storage.storeNotification('notifications', notification);
        }
        this.notifications = await this.storage.get('notifications');
      }
    });
  }

  async deleteNotification(notification: PushNotificationSchema) {
   this.notifications = await this.storage.deleteNotification('notifications', notification);
   PushNotifications.removeDeliveredNotifications({notifications: [notification]});
  }

  async clearNotifications() {
    await this.storage.remove('notifications');
    PushNotifications.removeAllDeliveredNotifications();
    this.notifications = null;
  }

  navigateToEventPage(notification: PushNotificationSchema) {
    if (notification.data.eventPage) {
      this.router.navigateByUrl('events/' + notification.data.eventPage, {replaceUrl: true, skipLocationChange: true});
    }
  }

  closeModal() {
    this.modalController.dismiss(null, 'cancel');
  }


}
