import { CommonModule, formatCurrency } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from '../../services/auth.service';
import { MessagesService } from '../../services/messages.service';
import { StorageService } from '../../services/storage/storage.service';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UserServiceCustom } from '../../services/user.service';
import { SystemConnection } from '../../services/drupal7/public_api';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.page.html',
  styleUrls: ['./orders.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class OrdersPage implements OnInit {

  userSession: SystemConnection;
  orders: any;

  constructor(private router: Router, private user: UserServiceCustom, private messages: MessagesService, private storage: StorageService, private auth: AuthService) {
  }

  ngOnInit() {
    this.user.currentSession.subscribe(session => this.userSession = session);
    this.getData();
  }

  navigateToPage(url: string, extras?: any) {
    if (extras) {
      this.router.navigate([url], {state: {
          extras
      }});
    } else {
      this.router.navigate([url]);
    }
  }

  getData(showloading: boolean = true, event: any = null) {
    if (this.userSession) {
      if (showloading) {
        this.messages.showLoading('Getting your order history...', false, 2000);
      }
      const options = this.auth.setupRequestOptions('csrf', this.userSession);
      this.auth.get(environment.commerce.commerce_order.api_fetch + this.userSession.user.mail, options).subscribe(orders => {
        for (const o of orders.orders) {
          this.getPaymentTransactionsTotal(o);
        }
        this.orders = orders.orders;
        return this.orders;
      });
      if (event) {
        setTimeout(() => {
          console.log('Async operation has ended');
          event.target.complete();
        }, 2000);
      }
    }
  }

  getPaymentTransactionsTotal(res: any) {
    if (res.commerce_payment_transactions?.length) {
      let transactionsTotal = 0;
      for (const transaction of res.commerce_payment_transactions) {
        if (transaction.status === CommercePaymentTransactionStatus.success) {
          const amount = Number(transaction.amount.toString().replace(/[^0-9.-]+/g,''));
          transactionsTotal += amount;
        }
      }
      res.commerce_order_payments_amount = +transactionsTotal.toFixed(2).replace('.', '');
      res.commerce_order_payments_amount_formatted = formatCurrency(parseFloat(res.commerce_order_payments_amount.toFixed(2)) / 100, 'en-US', '$');
    }
    return res;
  }


}

export enum CommerceOrderStatus {
  canceled = 'Canceled',
  cart = 'Cart',
  checkout_checkout = 'Checkout: Checkout',
  checkout_review = 'Checkout: Review',
  checkout_payment = 'Checkout: Payment',
  checkout_complete = 'Checkout: Complete',
  pending = 'Pending',
  processing = 'Processing',
  invoiced = 'Invoiced'
}

export enum CommercePaymentTransactionStatus {
  canceled = 'canceled',
  pending = 'pending',
  success = 'success',
  failure = 'failure'
}

export enum CommerceComponentsState {
  base_price = 'base_price',
  discount = 'discount',
  fee = 'fee'
}

