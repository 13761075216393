import { Component, Input, OnInit } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { format, parseISO } from 'date-fns';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from '../../services/auth.service';
import { MessagesService } from '../../services/messages.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-daily-devotions',
  templateUrl: './daily-devotions.page.html',
  styleUrls: ['./daily-devotions.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class DailyDevotionsPage implements OnInit {

  @Input() devotions: DailyDevotional[] = [];
  date = new Date().toISOString();
  options = this.auth.setupRequestOptions('json', null);

  constructor(private auth: AuthService, private router: Router, private messages: MessagesService) {
    const nav: Navigation = this.router.getCurrentNavigation();
    if (nav?.extras && nav.extras.state) {
      this.devotions = nav.extras.state.extras.devotions;
    }

    this.initialize();
   }

  ngOnInit() {
  }

  async initialize() {
    console.log(this.devotions);
    if (!this.devotions.length) {
      this.messages.showLoading('Fetching the devotions', false, 2500);
      this.devotions = await this.auth.get(environment.churchURLS.DevotionalUrl, this.options).toPromise();
      // this.devotions.map(async (devo) => {
      // devo.sections = await this.getBibleReading(devo, options);
      // });
    }
  }

  async goToDevotional(devotion: DailyDevotional) {
    console.log(devotion);
    // this.messages.showLoading('Fetching the Bible reading for this devotion', false, 2500);
    // devotion.sections = await this.getBibleReading(devotion, this.options);
    devotion.sections = [];
    const extras = {devotion, sections: devotion.sections};
    this.router.navigate(['home/daily-devotions/'+ devotion.nid], {state: {
      extras
    }});
  }

  async getData(showloading: boolean = true, event: any = null) {
    if (showloading) {
      this.messages.showLoading('Fetching the devotions', false, 1200);
    }
    this.devotions = await this.auth.get(environment.churchURLS.DevotionalUrl, this.options).toPromise();
    if (event) {
      setTimeout(() => {
        console.log('Async operation has ended');
        event.target.complete();
      }, 2000);
    }
  }

  async getDevotionals() {
    const options = this.auth.setupRequestOptions('json', null);
    const devotion = await this.auth.get(environment.churchURLS.DevotionalUrl, options).toPromise().then(res => res[0]);
    const sections: DailyDevotionalSection[] = await this.getBibleReading(devotion, options);
    return {devotion, sections};
  }

  async getBibleReading(devotion: DailyDevotional, options: any) {
    const bibleReading = await this.auth.get(environment.churchURLS.BibleReadingUrl + '/' + devotion.month + '/' + devotion.day, options).toPromise().then(res => res[0]);
    const proverbsReading = await this.auth.get(environment.churchURLS.ProverbsReadingUrl + '/' + devotion.day, options).toPromise().then(res => res[0]);
    const sections: DailyDevotionalSection[] = [];
    sections.push(new DailyDevotionalSection(
      'Old Testament Reading',
      bibleReading.old_testament_ref,
      bibleReading.old_testament_text));

      sections.push(new DailyDevotionalSection(
        'New Testament Reading',
        bibleReading.new_testament_ref,
        bibleReading.new_testament_text));

        sections.push(new DailyDevotionalSection(
        'Proverbs Reading',
        proverbsReading.proverbs_ref,
        proverbsReading.proverbs_text));

    return sections;
  }

  async search() {
    if (this.date) {
      const date = format(parseISO(this.date), 'EEEE, MMMM dd, yyyy');
      const message = 'Searching for the devotion written on ' + date;
      const url = `${environment.churchURLS.DevotionalUrl}/${this.date}`;
      this.messages.showLoading(message, false, 1500);
      this.devotions = await this.auth.get(url, this.options).toPromise();
      this.date = null;
    }
  }

  resetSearch() {
    this.date = null;
    this.initialize();
  }



}

export class DailyDevotionalSection {
  title: string;
  reference: string;
  body: string;
  constructor(title: string, ref: string, body: string) {
    this.title = title;
    this.reference = ref;
    this.body = body;
  }
}

export interface DailyDevotional {
  day: number;
  month: string;
  year: string;
  full_date: string;
  devotion_body: string;
  devotion_teaser: string;
  growth_principle: string;
  title: string;
  author: string;
  nid: string;
  devotion_button_text: string;
  devotions_button_text: string;
  sections: DailyDevotionalSection[];
}

