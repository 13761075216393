import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ToastController, AlertController, LoadingController } from '@ionic/angular';
import { StorageService } from './storage/storage.service';
import { environment } from 'src/environments/environment.prod';
import { EntityService, UserService } from './drupal7/drupal7-services.module';
import { SystemConnection } from './drupal7/public_api';

@Injectable()
export class StripeService {

  StripeCustomerURL = 'https://api.stripe.com/v1/customers/';
  StripeChargeURL = 'https://api.stripe.com/v1/charges';
  StripePaymentIntents = 'https://api.stripe.com/v1/payment_intents';
  StripePaymentMethods = 'https://api.stripe.com/v1/payment_methods';
  Stripe_TokenURLD8 = 'https://account.lancasterbaptist.org/entity/key/stripe?_format=json';
  Stripe_PublishableKeyURLD8 = 'https://account.lancasterbaptist.org/entity/key/stripe_publishable_key?_format=json';
  Stripe_TokenURLD7 = 'https://www.lancasterbaptist.org/api/key/stripe';
  Stripe_PublishableKeyURLD7 = 'https://www.lancasterbaptist.org/api/key/stripe_publishable_key';
  StripeTokenKeyURL = 'https://www.lancasterbaptist.org/api/key/';

  constructor(
    public http: HttpClient,
    public storage: StorageService,
    public toastController: ToastController,
    public alertCtrl: AlertController,
    public auth: AuthService,
    // public user: UserService,
    private entityService: EntityService,
    private userService: UserService,
    public loadingCtrl: LoadingController) {
  }

  async presentAlert(title, body) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: body,
      buttons: ['OK']
    });
    await alert.present();
  }

  getStripeTokenByValue(session: SystemConnection, key: string): Promise<any> {
    const options = this.auth.setupRequestOptions('csrf', session);
    return this.http.get(this.StripeTokenKeyURL + key, options).toPromise()
      .then((res: any) => res.key_provider_settings.key_value,
      async err => {
        if (err.error.message === 'Expired token' || err.error.message === 'Internal Server Error') {
          session = await this.storage.get('session');
          this.getStripeTokenByValue(session, key);
        }
      });
  }

  // getStripePublishableKey(session: Session): Promise<any> {
  //   const options = this.auth.setupRequestOptions('csrf', session);
  //   return this.http.get(this.Stripe_PublishableKeyURLD7, options).toPromise()
  //     .then((res: any) => res.key_provider_settings.key_value,
  //     async err => {
  //       if (err.error.message === 'Expired token' || err.error.message === 'Internal Server Error') {
  //         await this.user.refreshTokens();
  //         session = await this.storage.get('session');
  //         this.getStripePublishableKey(session);
  //       }
  //     });
  // }

  // async getStripeToken(session: any): Promise<any> {
  //   const options = this.auth.setupRequestOptions('csrf', session);
  //   return this.http.get(this.Stripe_TokenURLD7, options).toPromise().then((res: any) => res.key_provider_settings.key_value,
  //     async err => {
  //       console.log('error stripe token');
  //       if (err.error.message === 'Expired token' || err.error.message === 'Internal Server Error') {
  //         await this.user.refreshTokens();
  //         session = await this.storage.get('session');
  //         this.getStripeToken(session);
  //       }
  //     });
  // }


   getCustomer(customerID: string, stripeToken: string): Promise<any> {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + stripeToken
    };
    const options = { headers: new HttpHeaders(headers), params: {'expand[]': 'sources'} };
    return this.http.get(this.StripeCustomerURL + customerID, options).toPromise()
      .then((CustomerData1: any) => CustomerData1,
      error => {
        console.log('Error', error);
      });
  }

  async getCardData(session: any, showLoading: boolean = true): Promise<any> {
    if (showLoading) {
      this.showLoading('Checking for Your Payment Info...', false, 1500);
    }
    if (session?.user.field_stripe?.und?.length) {
      const customerID = session.user.field_stripe.und[0].value;
      return await this.getStripeTokenByValue(session, environment.commerce.payment_methods.stripe.stripeSKKey).then(async (StripeTokendata) => await this.getCustomer(customerID, StripeTokendata).then(async CustomerData => CustomerData),
      async err => {
        console.log('error stripe token');
        if (err.error.message === 'Expired token' || err.error.message === 'Internal Server Error') {
          session = await this.storage.get('session');
          return this.getCardData(session);
        }
      });
    }
  }

  async validateBank(bankInfo: StripeBankAccount) {
    const customer = await this.storage.get('session');
    const stripeKey = await this.getStripeTokenByValue(customer, environment.commerce.payment_methods.stripe.stripePKKey);
    const stripeToken = await this.getStripeTokenByValue(customer, environment.commerce.payment_methods.stripe.stripeSKKey);

    const token = await this.createBankAccountToken(bankInfo, stripeKey);
    if (token) {
      if (customer) {
        console.log('found customer');
        const newCard = await this.createCardExistingCustomer(customer.user.field_stripe.und[0].value, token, stripeToken);
        console.log(newCard);
        return this.getCardData(customer);
      } else {
        console.log('no customer found');
        const newCustomerAndCard = await this.createCustomerAndBankAccount(customer.user.mail, bankInfo, token, stripeToken);
        console.log(newCustomerAndCard);
        return this.getCardData(customer);
      }
    }
  }

  async validateCard(cardInfo: StripeCard): Promise<StripeValidated> {
    const customer = await this.storage.get('session');
    const stripeKey = await this.getStripeTokenByValue(customer, environment.commerce.payment_methods.stripe.stripePKKey);
    const stripeToken = await this.getStripeTokenByValue(customer, environment.commerce.payment_methods.stripe.stripeSKKey);

    const card: StripeCard = {
      name: cardInfo.name,
      card_number: cardInfo.card_number,
      exp_month: cardInfo.exp_month,
      exp_year: cardInfo.exp_year,
      cvc: cardInfo.cvc,
      address_city: cardInfo.address_city,
      address_country: 'US',
      address_line1: cardInfo.address_line1,
      address_line2: cardInfo.address_line2,
      address_state: cardInfo.address_state,
      address_zip: cardInfo.address_zip
    };

    const token = await this.createCardToken(card, stripeKey);
    if (token) {
      if (customer.user.field_stripe?.und?.length) {
        console.log('found customer');
        const newCard = await this.createCardExistingCustomer(customer.user.field_stripe.und[0].value, token, stripeToken);
        console.log(newCard);
        return {
          token,
          customer: await this.getCardData(customer)
        };
      } else {
        console.log('no customer found');
        const customerName = customer.user.visit_last_name ? customer.user.visit_first_name?.und[0].value + ' ' + customer.user.visit_last_name?.und[0].value : card.name;
        const customerInfo: StripeCustomer = {
          name: customerName,
          email: customer.user.mail,
          phone: customer.user.person_phone_number?.und?.length ? customer.user.person_phone_number?.und[0].value : ''
        };
        const newCustomerAndCard = await this.createCustomerAndCard(customerInfo, cardInfo, token, stripeToken);
        console.log(newCustomerAndCard);
        return {
          token,
          customer: await this.getCardData(newCustomerAndCard.session)
        };
      }
    }
  }

  createToken(apiKey: string): Promise<any> {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + apiKey
    };
    const options = { headers: new HttpHeaders(headers) };
    return this.http.post('https://api.stripe.com/v1/tokens', null, options).toPromise()
      .then((cardData: any) => cardData, err => {
        console.log('Error Message: ', err);
        this.presentAlert('We had a Problem', err.error.message);
      }
    );
  }


  createCardToken(card: StripeCard, stripePublishableKey: string): Promise<any> {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + stripePublishableKey
    };
    const options = { headers: new HttpHeaders(headers) };
    const body = `card[name]=${card.name}&card[number]=${card.card_number}&card[exp_month]=${card.exp_month}&card[exp_year]=${card.exp_year}&card[cvc]=${card.cvc}&card[address_city]=${card.address_city}&card[address_country]=${card.address_country}&card[address_line1]=${card.address_line1}&card[address_line2]=${card.address_line2}&card[address_state]=${card.address_state}&card[address_zip]=${card.address_zip}`;
    return this.http.post('https://api.stripe.com/v1/tokens', body, options).toPromise()
      .then((cardData: any) => cardData, err => {
        console.log('Error Message: ', err);
        this.presentAlert('We had a Problem', err.error.message);
      }
    );
  }

  createBankAccountToken(bankInfo: StripeBankAccount, stripePublishableKey: string): Promise<any> {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + stripePublishableKey
    };
    const options = { headers: new HttpHeaders(headers) };
    const body = `bank_account[account_holder_name]=${bankInfo.account_holder_name}&bank_account[account_holder_type]=${bankInfo.account_holder_type}&bank_account[country]=${bankInfo.country}&bank_account[currency]=${bankInfo.currency}&bank_account[routing_number]=${bankInfo.routing_number}&bank_account[account_number]=${bankInfo.account_number}`;
    return this.http.post('https://api.stripe.com/v1/tokens', body, options).toPromise()
      .then((bankData: any) => {
        console.log(bankData);
        return bankData;
      }, err => {
        console.log('Error Message: ', err);
        this.presentAlert('We had a Problem', err.error.message);
      }
    );
  }

  createCustomerAndBankAccount(email: string, bankInfo: StripeBankAccount, token: any, stripeToken: string): Promise<any> {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + stripeToken
    };
    const options = { headers: new HttpHeaders(headers) };
    const body = `description=${bankInfo.country}&source=${token.id}&email=${email}`;
    return this.http.post('https://api.stripe.com/v1/customers', body, options).toPromise()
      .then(async (customerData: any) => {
        console.log(customerData.id);

        // Drupal 7
        const field = {field_stripe: [{ value: customerData.id }]};
        const session = await this.storage.get('session');
        this.userService.updateUser({mail: session.user.mail, field_stripe: {und: [{ value: customerData.id }]}})
          .then(data => {
            console.log('updated', data);
            if (data.uid) {
              console.log('Success: ', customerData);
              return customerData;
            } else {
              this.presentAlert('We had a Problem', 'Please make sure you have entered the Card and Billing Address information correctly.');
              return customerData;
            };
          }, err => this.presentAlert('We had a Problem', err));

      },
      err => {
        console.log('Error Message: ', err);
        this.presentAlert('We had a Problem', err.error.message);
      }
    );
  }


  async createCustomerAndCard(customerInfo: StripeCustomer, cardInfo: StripeCard, token: any, stripeToken: string): Promise<any> {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + stripeToken
    };
    const options = { headers: new HttpHeaders(headers) };
    const body = `description=${cardInfo.name}&source=${token.id}&email=${customerInfo.email}&phone=${customerInfo.phone}`;
    return this.http.post('https://api.stripe.com/v1/customers', body, options).toPromise()
      .then(async (customerData: any) => {
        // Drupal 7
        const session = await this.storage.get('session');
        return this.userService.updateUser({mail: session.user.mail, field_stripe: {und: [{value: customerData.id}]}})
          .then(data => {
            if (data) {
              return {session: data, customer: customerData};
            } else {
              this.presentAlert('We had a Problem', 'Please make sure you have entered the Card and Billing Address information correctly.');
              return {customer: customerData};
            };
          }, err => {console.log(err);this.presentAlert('We had a Problem', err.error.message);});
      },
      err => {
        console.log('Error Message: ', err);
        this.presentAlert('We had a Problem', err.error.message);
      });
  }

  public createCardExistingCustomer(customerID: string, cardToken: any, stripeToken: string): Promise<any> {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + stripeToken
    };
    const options = { headers: new HttpHeaders(headers) };
    const body = `source=${cardToken.id}`;
    return this.http.post(this.StripeCustomerURL + customerID + '/sources', body, options).toPromise()
    .then((customerData: any) => customerData, err => {
      console.log('Error', err);
      this.presentAlert('We had a Problem', err.error.message);
    });

  }

  public deleteCustomerCard(customerID: string, tokenID: string, stripeToken: string) {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + stripeToken
    };
    const options = { headers: new HttpHeaders(headers) };
    return this.http.delete(this.StripeCustomerURL + customerID + '/sources/' + tokenID, options).toPromise()
    .then((customerData: any) => customerData, err => {
      console.log('Error', err);
    });
  }

  public deleteCustomer(customerID: string, token: string) {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + token
    };
    const options = { headers: new HttpHeaders(headers) };
    return this.http.delete(this.StripeCustomerURL + customerID, options).toPromise()
    .then((customerData: any) => customerData, err => {
      console.log('Error', err);
    });
  }

  public createCharge(charge: StripeCharge, stripeToken: string): Promise<any> {
    console.log(charge);
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + stripeToken
    };
    const options = { headers: new HttpHeaders(headers) };
    let body = `amount=${charge.amount}&currency=${charge.currency}&customer=${charge.customer}&source=${charge.source}&description=${charge.description}&receipt_email=${charge.receipt_email}&statement_descriptor=${charge.statement_descriptor}`;
    if (!charge.customer) {
      body = `amount=${charge.amount}&currency=${charge.currency}&source=${charge.source}&description=${charge.description}&receipt_email=${charge.receipt_email}&statement_descriptor=${charge.statement_descriptor}`;
    }
    return this.http.post(this.StripeChargeURL, body, options).toPromise()
    .then((chargeData: any) => {
      console.log(chargeData);
      return chargeData;
    }, err => {
      console.log('Error', err);
      this.presentAlert('We had a Problem', err.error.message);
    });

  }

  async createPaymentIntent(charge: StripePaymentIntent, stripeToken: string) {
    const body = `amount=${charge.amount}&currency=${charge.currency}&receipt_email=${charge.receipt_email}&automatic_payment_methods[enabled]=${charge.automatic_payment_methods.enabled}&description=${charge.description}`;
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + stripeToken
    };
    const options = { headers: new HttpHeaders(headers) };
    return this.http.post(this.StripePaymentIntents, body, options).toPromise()
    .then((res: any) => {
      console.log(res);
      return res;
    }, err => {
      console.log(err);
      return err;
    });
  }

  async createPaymentMethod(token: string, stripeToken: string) {
    const body = `type=${StripePaymentMethods.card}&card[token]=${token}`;
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + stripeToken
    };
    const options = { headers: new HttpHeaders(headers) };
    return this.http.post(this.StripePaymentMethods, body, options).toPromise()
    .then((res: any) => {
      console.log(res);
      return res;
    }, err => {
      console.log(err);
      return err;
    });
  }


  async confirmPaymentIntent(id: string, paymentIntent: StripePaymentIntent, stripeToken: string) {
    const body = `payment_method=${paymentIntent.payment_method}&receipt_email=${paymentIntent.receipt_email}`;
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + stripeToken
    };
    const options = { headers: new HttpHeaders(headers) };
    return this.http.post(`${this.StripePaymentIntents}/${id}/confirm`, body, options).toPromise()
    .then((res: any) => {
      console.log(res);
      return res;
    }, err => {
      console.log(err);
      return err;
    });
  }


  async getPaymentIntent(id: string, stripeToken: string) {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + stripeToken
    };
    const options = { headers: new HttpHeaders(headers) };
    return this.http.get(this.StripePaymentIntents + '/' + id, options).toPromise()
    .then((res: any) => {
      console.log(res);
      return res;
    }, err => {
      console.log(err);
      return err;
    });
  }


  async showLoading(title: string, dismiss: boolean, time: number) {
    const loading = await this.loadingCtrl.create({
      message: title,
      backdropDismiss: dismiss,
      duration: time
    });
    await loading.present();
    console.log('Loading dismissed!');
  }

}

export interface StripeBankAccount {
  country: string;
  account_number: string;
  routing_number: string;
  currency: string;
  account_holder_name: string;
  account_holder_type: string;
}
export interface StripeCard {
  name: string;
  card_number: string;
  exp_month: string;
  exp_year: string;
  cvc: string;
  address_city: string;
  address_country: string;
  address_line1: string;
  address_line2: string;
  address_state: string;
  address_zip: string;
}

export interface StripeCharge {
  amount: string | number;
  currency: string;
  customer?: string;
  source?: string;
  description?: string;
  receipt_email?: string;
  statement_descriptor?: string;
}

export interface StripeCustomer {
  name: string;
  email: string;
  phone: string;
  description?: string;
}

export interface StripePaymentIntent {
  amount: number;
  currency: string;
  customer?: string;
  receipt_email?: string;
  description?: string;
  statement_descriptor?: string;
  confirm?: boolean;
  automatic_payment_methods?: {enabled: boolean};
  payment_method?: string;
  payment_method_types?: Array<StripePaymentMethods>;
  payment_method_data?: string;
  id?: string;
  client_secret?: string;
}

export interface StripeValidated {
  token?: any;
  customer: any;
  session?: SystemConnection;
}

export enum StripePaymentMethods {
  acss_debit = 'acss_debit',
  affirm = 'affirm',
  afterpay_clearpay = 'afterpay_clearpay',
  alipay = 'alipay',
  au_becs_debit = 'au_becs_debit',
  bacs_debit = 'bacs_debit',
  bancontact = 'bancontact',
  blik = 'blik',
  boleto = 'boleto',
  card = 'card',
  customer_balance = 'customer_balance',
  eps = 'eps',
  fpx = 'fpx',
  giropay = 'giropay',
  grabpay = 'grabpay',
  ideal = 'ideal',
  klarna = 'klarna',
  konbini = 'konbini',
  link = 'link',
  oxxo = 'oxxo',
  p24 = 'p24',
  paynow = 'paynow',
  pix = 'pix',
  promptpay = 'promptpay',
  sepa_debit = 'sepa_debit',
  sofort = 'sofort',
  us_bank_account = 'us_bank_account',
  wechat_pay = 'wechat_pay'
}
