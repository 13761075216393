import { Component, Input, OnInit } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { ConnectionGroupCategory, ConnectionGroupClass, ConnectionGroupDay } from '../classes.page';
import { AuthService } from '../../../services/auth.service';
import { MessagesService } from '../../../services/messages.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-class',
  templateUrl: './class.page.html',
  styleUrls: ['./class.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class ClassPage implements OnInit {

  @Input() connectionGroup: ConnectionGroupClass;
  @Input() url: string;
  @Input() currentNid: string;
  @Input() defaultImg: 'connection_groups';

  constructor(
    public router: Router,
    public auth: AuthService,
    public messages: MessagesService) {
    const nav: Navigation = this.router.getCurrentNavigation();
    this.url = this.router.url;
    this.currentNid = this.url.split('/')[3];
    if (nav?.extras && nav.extras.state) {
      this.connectionGroup = nav.extras.state.connectionGroup;
      this.defaultImg = nav.extras.state.defaultImg;
    } else {
      this.getData();
    }
  }

  ngOnInit() {
  }

  getData() {
    this.auth.get(environment.churchURLS.ConnectionGroupsUrl, this.auth.setupRequestOptions('json', null)).subscribe(res => {
      this.connectionGroup = res.filter(x => x.nid === +this.currentNid)[0];
      console.log(this.connectionGroup);
      // return this.initializeClasses(this.connectionGroup);
    });
  }

  async initializeClasses(connectionGroup: ConnectionGroupClass) {
    console.log(connectionGroup);
    const options = this.auth.setupRequestOptions('json', null);
    this.messages.showLoading('Getting the class schedule for ' + this.connectionGroup?.title, false, 2000);
    const adultsURL = 'adults';
    const allClasses: any = await this.auth.get(environment.churchURLS.AllClasses + adultsURL, options).toPromise();
    // Get the class times to add to the schedule
    const classTimes: ConnectionGroupDay = await this.auth.get(environment.churchURLS.ClassTimesUrl + adultsURL, options).toPromise();
    const currentCategory: ConnectionGroupCategory = await allClasses.map((category) => {
      category.classes = classTimes;
      if (category.name === connectionGroup.connection_group_category) {
        return category;
      };
    }).filter(res => res)[0];
    currentCategory.classes.filter((res: ConnectionGroupDay) => {
      res.classes = res.classes.filter((c: ConnectionGroupClass) => c.nid === connectionGroup.nid);
    });
    console.log(currentCategory);
  }


}
