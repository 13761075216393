// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header + ion-card {
  margin-top: 0;
}

.event-description {
  color: var(--ion-color-medium);
  margin-bottom: 2rem;
}

.tags {
  margin-top: 15px;
  margin-bottom: 10px;
}
.tags ion-badge {
  margin: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/events/events.page.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,8BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,mBAAA;AACJ;AAAI;EACI,WAAA;AAER","sourcesContent":["ion-header+ion-card {\n    margin-top: 0;\n}\n\n.event-description {\n    color: var(--ion-color-medium);\n    margin-bottom: 2rem;\n}\n\n.tags {\n    margin-top: 15px;\n    margin-bottom: 10px;\n    ion-badge {\n        margin: 3px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
