import { CommonModule, formatCurrency } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router, Navigation } from '@angular/router';
import { SMS } from '@awesome-cordova-plugins/sms/ngx';
import { Browser } from '@capacitor/browser';
import { IonicModule, Platform, } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from '../services/auth.service';
import { WebformFieldControlObject } from '../services/entity.service';
import { EventsService } from '../services/events.service';
import { MessagesService } from '../services/messages.service';
import { StorageService } from '../services/storage/storage.service';
import { StripeService } from '../services/stripe.service';
import { FormsModule } from '@angular/forms';
import { SystemConnection } from '../services/drupal7/models';

@Component({
  selector: 'app-support',
  templateUrl: './support.page.html',
  styleUrls: ['./support.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class SupportPage implements OnInit {
  @Input() event: any;
  @Input() session: SystemConnection;
  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  webformFields: any;
  entityformFields: any;
  entityformData: any;
  connectionGroups = [];
  formFields = {};
  SubmitButtonText = 'Submit';
  formLoaded = false;
  webform: any;
  customer: any;
  eventCost = 0;
  formattedEventCost: string;

  constructor(
    private auth: AuthService,
    private router: Router,
    private sms: SMS,
    private storage: StorageService,
    public stripeService: StripeService,
    public events: EventsService,
    public platform: Platform,
    private messages: MessagesService
  ) {
    const nav: Navigation = this.router.getCurrentNavigation();
    if (nav?.extras && nav.extras.state) {
      this.event = nav.extras.state.extras.event;
      this.session = nav.extras.state.extras.session;
      if (this.event.entityform) {
        if (this.event.exclude_registration !== 'Yes') {
          this.setupEntityform();
        }
      } else {
        if (this.event.exclude_registration !== 'Yes') {
          this.setupWebform();
        }
      }
    } else {
      this.getData();
    }
   }

  ngOnInit() {
  }

  externalLink(url: string) {
    Browser.open({url});
  }

  sendSMS(phoneNumber: any) {
    this.sms.send(phoneNumber, 'Give',{replaceLineBreaks: true, android: {intent: 'INTENT'}});
  }

  async getData(showLoading: boolean = false) {
    this.session = await this.storage.get('session');

    if (showLoading) {
      this.messages.showLoading('Loading the data...', false, 1500);
    }
    this.auth.get(environment.churchURLS.Support.URL, this.options).subscribe(data => {
      this.event = data.events[0];

      if (this.event.entityform) {
        if (this.event.exclude_registration !== 'Yes') {
          this.setupEntityform();
        }
      } else {
        if (this.event.exclude_registration !== 'Yes') {
          this.setupWebform();
        }
      }
    });
  }

  async setupEntityform() {
    if (!this.session) {
      this.session = await this.storage.get('session');
    }

    this.auth.get(environment.FormSchemaURL + 'entityform_type/' + this.event.entityform + '/form', this.options).subscribe(data => {
      for (const field of data.fields) {
        if (field.field_name === 'connection_group') {
          this.auth.get(environment.churchURLS.ConnectionGroupsOptions, this.options).subscribe(connectionGroups => {
            this.connectionGroups = connectionGroups;
          });
        }

        if (field.field_name === 'visit_first_name') {field.value = this.session.user.visit_first_name.und[0]?.value;this.entityformData[field.field_name] = field.value;};
        if (field.field_name === 'visit_last_name') {field.value = this.session.user.visit_last_name.und[0]?.value;this.entityformData[field.field_name] = field.value;};
        if (field.field_name === 'visit_email') {field.value = this.session.user.mail;this.entityformData[field.field_name] = field.value;};
        if (field.field_name === 'visit_phone_number') {field.value = this.session.user.person_phone_number.und[0]?.value;this.entityformData[field.field_name] = field.value;};
        if (field.form_key === 'connection_group') {field.value = this.session.user.connection_group.und[0]?.target_id;this.entityformData[field.field_name] = field.value;};

        if (field.field_name === 'event_to_register') {field.value = this.event.nid;this.entityformData[field.field_name] = field.value;};
        if (field.type === 'list_text') {
          let options = {};
          options = Object.keys(field.options)
          .map(key1 => {
            let key = '';
            let value = '';
            key = key1;
            value = field.options[key1];
            options = {key, value};
            return options;
          });
          field.options = options;
        }
      }

      // Set type and user for the entityform data
      this.entityformData.type = this.event.entityform;
      this.entityformData.user = 3327;

      this.entityformFields = data.fields;
      return this.formLoaded = true;
    });
  }

  async setupWebform() {
    if (!this.session) {
      this.session = await this.storage.get('session');
    }
    this.events.getWebformFields(this.event).then(async (res) => {
      this.webform = res.webform;
      let i = 0;
      for (const field of res.fields) {
        field.required = field.required === '1' ? true : false;
        if (this.session) {
          if (field.form_key === 'first_name') {field.value = this.session.user.visit_first_name.und[0]?.value;this.formFields[i] = field.value;};
          if (field.form_key === 'last_name') {field.value = this.session.user.visit_last_name.und[0]?.value;this.formFields[i] = field.value;};
          if (field.form_key === 'email') {field.value = this.session.user.mail;this.formFields[i] = field.value;};
          if (field.form_key === 'phone') {field.value = this.session.user.person_phone_number.und[0]?.value;this.formFields[i] = field.value;};
          if (field.form_key === 'connection_group') {field.value = this.session.user.connection_group.und[0]?.target_id;this.formFields[i] = field.value;};
          if (field.type === 'productfield') {
            if (!this.customer) {
              this.customer = await this.stripeService.getCardData(this.session, false);
            }
            if (this.webform.webform.submit_text) {
              this.SubmitButtonText = this.webform.webform.submit_text;
            } else {
              this.SubmitButtonText = 'Next';
            }
          };
        }
        if (field.form_key === 'platform') {
          if (this.platform.is('ios')) {
            field.value = 'ios';
            this.formFields[i] = field.value;
          }
          if (this.platform.is('android')) {
            field.value = 'android';
            this.formFields[i] = field.value;
          }
          if (!this.platform.is('ios') && !this.platform.is('android')) {
            field.value = 'web';
            this.formFields[i] = field.value;
          }
        }
        if (field.form_key === 'version') {
          field.value = environment.version;
          this.formFields[i] = field.value;
        }
        i++;
      }
      this.webformFields = res.fields;

      const newFields = [];
      for (const field of res.fields) {
        if (field.type !== 'fieldset') {
          newFields.push(field);
        }
      }

      this.webformFields = newFields;
      return this.formLoaded = true;
    });
  }

  async submitEntityform() {
    this.messages.showLoading('Submitting your registration', false, 1500);
    if (this.entityformData.connection_group) {
      if (this.entityformData.connection_group === 'No Connection Group') {
        this.entityformData.in_connection_group = 'no';
        delete this.entityformData.connection_group;
      } else {
        this.entityformData.in_connection_group = 'yes';
      }
    }
    const body = this.entityformData;
    body.created = Math.round((new Date()).getTime() / 1000);
    // console.log(body);
    const options = this.auth.setupRequestOptions('csrf', this.session);
    await this.events.createEntityformSubmission(body, options).then(data => {
      if (data) {
        // console.log(data);
        this.messages.presentAlert('Registration Complete', 'Looking forward to seeing you at ' + this.event.title);
      } else {
        this.messages.presentAlert('Sorry', 'We had an issue registering you for ' + this.event.title + ' please try again.');
      }
    }, error => {
      this.messages.presentAlert('Fail', error);
    });
  }

  async submitWebform() {
    let i = 0;
    const submissionData = {};
    this.eventCost = 0;
    for (const inputData of this.webformFields) {

      submissionData[inputData.cid] = {
        values: [this.formFields[i]]
      };

      if (inputData.type === 'productfield') {
        this.eventCost = this.addProductPrices(inputData, submissionData, i, this.eventCost);
      }

      if (inputData.type === 'select' && this.formFields[i]) {
        if (inputData.extra.multiple === 1) {
          submissionData[inputData.cid].values = this.formFields[i];
        } else {
          submissionData[inputData.cid].values = [this.formFields[i]];
        }
      }

      if (inputData.type === 'time' && this.formFields[i]) {
        submissionData[inputData.cid].values = [this.formFields[i] + ':00'];
      }

      if (inputData.type === 'number' && this.formFields[i]) {
        submissionData[inputData.cid].values = [this.formFields[i]];
      }

      i++;
    }
    const body = {
      webform: this.event.uuid,
      submission: {
        uid: this.session.user.uid ? +this.session.user.uid : +environment.appSettings.AppUserUID,
        data: submissionData
      },
      event_cost: this.eventCost.toString()
    };


      if (!this.eventCost) {
        const options = this.auth.setupRequestOptions('csrf', this.session);
        this.events.createWebformSubmission(body, options).then(submission => {
          if (submission) {
            // console.log(submission);
            // console.log(this.webform);
            this.messages.presentAlert('Ticket Created', 'A ticket has been submitted');
            this.router.navigate(['account'], {state: {}});
          } else {
            this.messages.presentAlert('Sorry', 'We had a problem submitting your issue');
          }
        }, err => {
          this.messages.presentAlert('Fail', err);
        });
      }

      if (this.eventCost) {
        this.router.navigate(['checkout'], {state: {
            event: this.event,
            webform: this.webform,
            webformSubmission: body,
            customer: this.customer
        }});
      }

  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  checkPrice(field: WebformFieldControlObject) {
    if (field.type === 'productfield' && !this.isEmptyObject(this.formFields)) {
      const submissionData = {};

      let i = 0;
      this.eventCost = 0;
      for (const inputData of this.webformFields) {
        submissionData[inputData.cid] = {
          values: [this.formFields[i]]
        };
        if (inputData.type === 'productfield') {
          this.eventCost = this.addProductPrices(inputData, submissionData, i, this.eventCost);
        }
        i++;
      }
      this.formattedEventCost = formatCurrency(this.eventCost / 100, 'en-US', '$');
      if (this.webform.webform.submit_text) {
        this.SubmitButtonText = this.webform.webform.submit_text + ' ' + this.formattedEventCost;
      } else {
        this.SubmitButtonText = `Continue to pay ${this.formattedEventCost}`;
      }
    }
  }

  addProductPrices(field: WebformFieldControlObject, submissionData: any, index: number, eventCost: number) {
    let productDetails: any;
    const productsDetails = [];
    const productsDetailsArray = [];
    if (field.extra.multiple === 1 && this.formFields[index]) {
      for (const product of this.formFields[index]) {
        productDetails = ({
          product_id: product,
          quantity: field.quantity,
          order_id: 'FALSE',
          line_item_id: 'FALSE',
          paid: 'FALSE',
        });
        productsDetailsArray.push(productDetails);
        productsDetails.push(JSON.stringify(productDetails));
      }
      submissionData[field.cid].values = productsDetails;
    } else {
      productDetails = ({
        product_id: this.formFields[index],
        quantity: field.quantity,
        order_id: 'FALSE',
        line_item_id: 'FALSE',
        paid: 'FALSE',
      });
      submissionData[field.cid].values = [JSON.stringify(productDetails)];
    }

    let final_cost = 0;
    for (const option of field.options) {
      if (field.extra.multiple === 1) {
        let subtotal = 0;
        for (const product of productsDetailsArray) {
          if (product.product_id === option.product_id) {
            subtotal += field.quantity * +option.price;
            eventCost += subtotal;
          }
        }
      } else {
        if (productDetails.product_id === option.product_id) {
          final_cost = field.quantity * +option.price;
          eventCost += final_cost;
        }
      }
    }
    this.formattedEventCost = formatCurrency(eventCost / 100, 'en-US', '$');
    return eventCost;
  }

  resetForm() {
    this.formFields = {};
    this.eventCost = 0;
    this.formattedEventCost = null;
    this.SubmitButtonText = this.webform.webform.submit_text ? this.webform.webform.submit_text : 'Register';
  }

}
