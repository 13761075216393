import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { Key } from '../models/key';

@Injectable()
export class KeyService extends MainService {
  override entityType = 'key';

  /**
   * retrieve all keys
   *
   * @return object of the form schema
   */
  getAllKeys(): Promise<{[key: string]: Key}> {
    return this.get('all');
  }

  /**
   * retrieve a key
   *
   * @param key the key name
   * @return object of the key
   */
    getKey(key: string): Promise<Key> {
        return this.get(key);
    }


  /**
   * retrieve a key value
   *
   * @param key the key name
   * @return value of the key
   */
    async getKeyValue(key: string): Promise<any> {
        return this.get(key)
          .then((res: Key) => {
          return res?.key_provider_settings?.key_value;
        });
    }

      /**
   * retrieve a key value
   *
   * @param keys an array of keys to return
   * @return value of the key
   */
      async getMultipleKeys(keys: string[]): Promise<any> {
        return this.get('all')
          .then((res: {[key: string]: Key}) => {
            const obj = {}
            for (const key of keys) {
              obj[key] = res[key].key_provider_settings.key_value;
            }
            return obj;
        });
    }

}
