import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from '../../services/auth.service';
import { MessagesService } from '../../services/messages.service';
import { StorageService } from '../../services/storage/storage.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.page.html',
  styleUrls: ['./team-members.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class TeamMembersPage implements OnInit {

  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  persons: any;
  constructor(public router: Router, private storage: StorageService, public auth: AuthService, private messages: MessagesService,) {
        this.storage.get('persons').then(persons => {
          this.persons = persons;
          if (!this.persons) {
            this.getData();
          }
    });
}

  ngOnInit() {
  }

  getData(showLoading: boolean = true, event: any = null) {
    if (showLoading) {
      this.messages.showLoading('Refreshing the data...', false, 1500);
    }
    this.auth.get(environment.churchURLS.StaffUrl, this.options).subscribe(data => {
      this.persons = data;
      this.storage.set('persons', this.persons);
    }, err => throwError(err));
    if (event) {
      setTimeout(() => {
        console.log('Async operation has ended');
        event.target.complete();
      }, 2000);
    }

  }

}
