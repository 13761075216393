import { HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from '../../services/auth.service';
import { MessagesService } from '../../services/messages.service';
import { StorageService } from '../../services/storage/storage.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-classes',
  templateUrl: './classes.page.html',
  styleUrls: ['./classes.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class ClassesPage implements OnInit {

  @Input() title = 'Adults';
  @Input() url = 'adults';
  @Input() defaultImg = 'connection_groups';
  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  categories: ConnectionGroupCategory[];
  classTimes: ConnectionGroupDay[];
  input = '';

  constructor(public router: Router, public auth: AuthService,
    private storage: StorageService,
    public messages: MessagesService) {
    const nav: Navigation = this.router.getCurrentNavigation();
    if (nav?.extras && nav.extras.state) {
      this.title = nav.extras.state.title;
      this.url = nav.extras.state.url;
      this.defaultImg = nav.extras.state.defaultImg;
    }
    this.storage.get('classTimes_' + this.url).then(res => {
      this.storage.get('classCategories_' + this.url).then(data => {
        this.classTimes = res;
        this.categories = data;
        console.log(this.classTimes);
        if (!this.classTimes || !this.categories) {
          this.getData();
        }
      });
    });
  }

  async ngOnInit() {
    this.classTimes = await this.storage.get('classTimes_' + this.url);
    this.categories = await this.storage.get('classCategories_' + this.url);
  }

  search() {
    return this.classTimes.map(res => res.classes.filter(x => {
      if (x.title.toLowerCase().indexOf(this.input.toLowerCase()) === -1) {
        x.hidden = true;
      } else {x.hidden = false;}
    }));
  }

  filter(val: any, obj: any, key: string) {
    return obj.map(res => res.classes.filter(x => {
        if (x[key] !== val && val !== 'all') {
         x.hidden = true;
        } else { x.hidden = false;}
      }));
  }

  filterTime(val: any, obj: any, key: string) {
    return obj.map(res => {
        if (res[key] !== val && val !== 'all') {
         res.hidden = true;
        } else { res.hidden = false;}
      });
  }

  async getData(showloading: boolean = true, event: any = null) {
    if (showloading) {
      this.messages.showLoading('Getting the class schedule for ' + this.title, false, 2000);
    }
    const AllClasses = await this.auth.get(environment.churchURLS.AllClasses + this.url, this.options).toPromise();

    // Get the class times to add to the schedule
    const classTimes: any = await this.auth.get(environment.churchURLS.ClassTimesUrl + this.url, this.options).toPromise();
    this.classTimes = classTimes;
    await AllClasses.map(async (category) => {
      category.classes = classTimes;
    });
    this.categories = AllClasses;
    this.storage.set('classTimes_' + this.url, this.classTimes);
    this.storage.set('classCategories_' + this.url, this.categories);
    if (event) {
      setTimeout(() => {
        console.log('Async operation has ended');
        event.target.complete();
      }, 2000);
    }

  }

  resetFilter() {
    this.categories.map(category => {category.classes.map(c => {c.hidden = false; return c;});});
  }

  navigateToPage(connectionGroup: ConnectionGroupClass) {
    console.log(connectionGroup);
    this.router.navigate([`info-guide/classes/${this.url}/${connectionGroup.nid}`], {state: {
        connectionGroup,
        defaultImg: this.defaultImg
    }});
  }

}

export interface ConnectionGroupCategory {
  tid: number;
  name: string;
  classes: ConnectionGroupDay[];
  hidden?: boolean;
}
export interface ConnectionGroupDay {
  category: string;
  classes: ConnectionGroupClass[];
  day: string;
  time: string;
  time_filter: string;
  hidden?: boolean;
}

export interface ConnectionGroupClass {
  class_logo: string;
  title: string;
  class_leaders: string;
  class_leader_email: string;
  class_leader_phone: string;
  lifestage: string;
  day: string;
  class_location: string;
  connection_group_category: string;
  class_leader_photo: string;
  tid: number;
  time: string;
  time_filter: string;
  nid: number;
  room_image: string;
  hidden?: boolean;
}
