
export interface FlagAction {
    flag_name: string;
    entity_id: number;
    action?: typeof FlaggingAction[keyof typeof FlaggingAction];
    uid?: number;
    skip_permission_check?: boolean;
}

export interface FlaggedEntity {
    flag_name: string;
    entity_id: number;
    status: boolean;
}

export const FlaggingAction = {
    flag: 'flag',
    unflag: 'unflag'
} as const;

export interface FlagEntity {
    fid: number;
    entity_type: string;
    name: string;
    title: string;
    global: number;

    types?: Array<string>;
    roles?: {flag?: {[rid: number]: string}; unflag?: {[rid: number]: string}};
    errors?: Array<any>;
    flag_short?: string;
    flag_long?: string;
    flag_message?: string;
    unflag_short?: string;
    unflag_long?: string;
    unflag_message?: string;
    unflag_denied_text?: string;
    link_type?: string;
    weight?: number;
    show_in_links?: {[display: string]: number};
    show_as_field?: number;
    show_on_form?: number;
    show_contextual_link?: number;
    i18n?: number;
}
export interface FlagIonic extends FlagEntity {
    label: string;
    flag_on_scan?: boolean;
    status?: boolean;
    view?: boolean;
    color?: string;
    fill?: string;
    category?: string;
}
