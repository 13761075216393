// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.credit-card-mockup {
  box-shadow: 0 2px 16px 2px rgba(0, 0, 0, 0.14);
  border-top: solid 12px var(--ion-color-primary);
  border-radius: 12px;
  padding: 2rem 2rem 1.5rem 2rem;
  max-width: 32rem;
  margin-top: 2rem;
}
.credit-card-mockup span {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  color: #b5b5b5;
  display: block;
  width: 100%;
}
.credit-card-mockup p {
  margin: 1rem 0 2rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.1rem;
  color: #848484;
}
.credit-card-mockup .card-number p {
  font-size: 1.7rem;
  letter-spacing: 0.3rem;
}
.credit-card-mockup .bottom-of-card p {
  margin-bottom: 0;
}

ion-datetime {
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/commerce/payment-methods/payment-methods.page.scss"],"names":[],"mappings":"AAAA;EACI,8CAAA;EACA,+CAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AAAI;EACI,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,cAAA;EACA,WAAA;AAER;AAAI;EACI,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,sBAAA;EACA,cAAA;AAER;AAAI;EACI,iBAAA;EACA,sBAAA;AAER;AAAI;EACI,gBAAA;AAER;;AAEA;EACI,cAAA;AACJ","sourcesContent":[".credit-card-mockup {\n    box-shadow: 0 2px 16px 2px rgba(0, 0, 0, 0.14);\n    border-top: solid 12px var(--ion-color-primary);\n    border-radius: 12px;\n    padding: 2rem 2rem 1.5rem 2rem;\n    max-width: 32rem;\n    margin-top: 2rem;\n    span {\n        text-transform: uppercase;\n        font-size: 10px;\n        font-weight: 400;\n        color: #b5b5b5;\n        display: block;\n        width: 100%;\n    }\n    p {\n        margin: 1rem 0 2rem;\n        text-transform: uppercase;\n        font-weight: 500;\n        letter-spacing: .1rem;\n        color: #848484;\n    }\n    .card-number p {\n        font-size: 1.7rem;\n        letter-spacing: .3rem;\n    }\n    .bottom-of-card p {\n        margin-bottom: 0;\n    }\n}\n\nion-datetime {\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
