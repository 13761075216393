
import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { PasswordCode, PasswordResetSession } from '../models/system';

/**
 * user service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class MyCustomApiService extends MainService {
  override entityType = 'my_custom_api';

  /**
   * implement request_new_password resource
   *
   * @param username the username of the user to request a new password
   * @return array of boolean, if the mail sent successfully will be true, otherwhise will be false.
   * if the server is not a mail server or if there is not mail server configuration this will always return false
   */
  requestNewPassword(username: string): Promise<PasswordCode> {
    const user = {
      name: username
    };
    return this.post(user, 'request_new_password');
  }

  /**
   * implement user_pass_reset resource
   *
   * @param passwordReset Object of the sent details to the email that contains the timestamp and the hashed password
   * @return response of the password reset request
   * this request will automatically logging the user in for only one time and will be expired after the first use.
   * the hashed password can be found at the drupal reset password email and you can customize it if you have token module installed
   */
  userPasswordReset(passwordReset: {name: string, password: string, timestamp: number}): Promise<PasswordResetSession> {
    return this.post(passwordReset, 'token');
  }

}
