// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-grid.line-items,
ion-grid.payment-transactions {
  --ion-grid-column-padding: 10px;
  border-collapse: collapse;
  border-style: hidden;
}
ion-grid.line-items ion-row:first-child,
ion-grid.payment-transactions ion-row:first-child {
  font-weight: bold;
}
ion-grid.line-items ion-col,
ion-grid.payment-transactions ion-col {
  border-bottom: 1px solid rgb(128, 128, 128);
}
ion-grid.line-items ion-row:last-child ion-col,
ion-grid.payment-transactions ion-row:last-child ion-col {
  border-bottom: 0;
}

.hide-for-swipe {
  position: fixed;
  top: -500rem;
}`, "",{"version":3,"sources":["webpack://./src/app/commerce/payment/payment.page.scss"],"names":[],"mappings":"AAAA;;EAEI,+BAAA;EACA,yBAAA;EACA,oBAAA;AACJ;AAAI;;EACI,iBAAA;AAGR;AADI;;EACI,2CAAA;AAIR;AAFI;;EACI,gBAAA;AAKR;;AADA;EACI,eAAA;EACA,YAAA;AAIJ","sourcesContent":["ion-grid.line-items,\nion-grid.payment-transactions {\n    --ion-grid-column-padding: 10px;\n    border-collapse: collapse;\n    border-style: hidden;\n    ion-row:first-child {\n        font-weight: bold;\n    }\n    ion-col {\n        border-bottom: 1px solid rgb(128, 128, 128);\n    }\n    ion-row:last-child ion-col {\n        border-bottom: 0;\n    }\n}\n\n.hide-for-swipe {\n    position: fixed;\n    top: -500rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
