/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from './auth.service';
import { MessagesService } from './messages.service';
import { StorageService } from './storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ElavonService {

  constructor(
    private http: HttpClient,
    private message: MessagesService,
    private auth: AuthService,
    public storage: StorageService
    ) { }


  prepare(billingData, cardDetails, payment, session) {

    let extData = '';
    // Build and populate the API request SimpleXML element.
    extData += '<CustomerID>' + session.user.uid + '</CustomerID>';
        // Customer Billing Address.
    extData += '<Invoice><BillTo>';
    const nameOnCard = billingData.first_name + ' ' + billingData.last_name;
    // Use company name as billing name when available.
    if (billingData.organisation_name) {
      extData += '<Name>' + billingData.organisation_name + '</Name>';
    } else {
      extData += '<Name>' + nameOnCard + '</Name>';
    }
    extData += '<Email>' + session.user.mail + '</Email>';
    extData += '<Address>';
    extData += '<Street>' + billingData.thoroughfare + '</Street>';
    extData += '<City>' + billingData.locality + '</City>';
    extData += '<State>' + billingData.administrative_area + '</State>';
    extData += '<Zip>' + billingData.postal_code + '</Zip>';
    extData += '<Country>' + billingData.country + '</Country>';
    extData += '</Address></BillTo></Invoice>';

    // Format the expiration date
    const expDate = cardDetails.exp_month.substring(5, 7) + cardDetails.exp_year.substring(2, 4);

    const info = {
      ssl_card_number: cardDetails.card_number,
      ssl_exp_date: expDate,
      CVNum: cardDetails.code,
      ssl_amount: payment.amount / 100,

      ssl_avs_address: billingData.thoroughfare,
      ssl_avs_zip: billingData.postal_code,
      ssl_first_name: billingData.first_name,
      ssl_last_name: billingData.last_name,
      ssl_transaction_type: 'ccsale',
      ssl_cvv2cvc2: cardDetails.code,
      ssl_salestax: 0,

      InvNum: payment.order_id,
      ssl_show_form: 'false',

      NameOnCard: nameOnCard,
      Street: billingData.thoroughfare,
      Zip: billingData.postal_code,
      ExtData: extData
    };

    // Format the amount
    const formattedAmount = parseInt(payment.amount.toString().replace('.', ''), 10);
    const charge = {amount: formattedAmount, currency_code: payment.currency_code};

    const body = {
      payment_method: environment.commerce.payment_methods.elavonProduction,
      info,
      order_id: payment.order_id,
      charge,
      uid: session.user.uid
    };
    return body;
  }

  elavonPaymentTransactionProcess(billingData, cardDetails, payment, session, appUser): Observable<any> {

    const body = this.prepare(billingData, cardDetails, payment, session);
    const options = this.auth.setupRequestOptions('csrf', appUser);
    console.log(body);
    console.log(options);

    this.message.showLoading('Submitting your payment...', true, 1000);
    // Send the payment
    return this.http.post(environment.commerce.payment_methods.elavon.api_url, body, options).pipe(
    map(data => {
      console.log(data);
      return data;
    }, err => {
      console.log('error: ', err);
      return err;
    }),
    catchError(err => {
      console.error('Error Message: ', err);
      return throwError(err);
    }));
  }

  commerceElavonRequest(body): Observable<any> {
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const requestOptions = { headers: new HttpHeaders(headers) };

    console.log(body);
    return this.http.post(environment.commerce.payment_methods.elavon.test_url, body, requestOptions).pipe(map(res => {
      console.log(res);
      return res;
    }, err => {
      console.error('error: ', err);
    }));
  }

  getLogMessage(response, type) {
    const status = response.status;
    const avs = response.xml.ssl_avs_response ? response.xml.ssl_avs_response : false;
    const cvv = response.xml.ssl_cvv2_response ? response.xml.ssl_cvv2_response : false;
    const message = {
      status,
      avs: this.avsReponse(avs),
      cvv: this.cvvResponse(cvv)
    };

    return message;
  }

  avsReponse(code) {
    switch (code) {
      case 'A':
        return 'Address matches - ZIP Code does not';
      case 'B':
        return 'Street address match, Postal code in wrong format (international issuer)';
      case 'C':
        return 'Street address and postal code in wrong formats';
      case 'D':
        return 'Street address and postal code match (international issuer)';
      case 'E':
        return 'AVS Error';
      case 'F':
        return 'Address does compare and five-digit ZIP code does compare (UK only)';
      case 'G':
        return 'Service not supported by non-US issuer';
      case 'I':
        return 'Address information not verified by international issuer';
      case 'M':
        return 'Street Address and Postal code match (international issuer)';
      case 'N':
        return 'No Match on Address (Street) or ZIP';
      case 'O':
        return 'No Response sent';
      case 'P':
        return 'Postal codes match, Street address not verified due to incompatible formats';
      case 'R':
        return 'Retry, System unavailable or Timed out';
      case 'S':
        return 'Service not supported by issuer';
      case 'U':
        return 'Address information is unavailable';
      case 'W':
        return '9-digit ZIP matches, Address (Street) does not';
      case 'X':
        return 'Exact AVS Match';
      case 'Y':
        return 'Address (Street) and 5-digit ZIP match';
      case 'Z':
        return '5-digit ZIP matches, Address (Street) does not';
      default:
        return '-';
    }
  }

  public cvvResponse(code) {
    switch (code) {
      case 'M':
        return 'CVV2/CVC2 Match';
      case 'N':
        return 'CVV2/CVC2 No match';
      case 'P':
        return 'Not Processed';
      case 'S':
        // eslint-disable-next-line max-len
        return 'Issuer indicates that the CVV2/CVC2 data should be present on the card, but the merchant has indicated that the CVV2/CVC2 data is not resent on the card';
      case 'U':
        return 'Issuer has not certified for CVV2/CVC2 or Issuer has not provided Visa with the CVV2/CVC2 encryption keys';
      default:
        return '-';
    }
  }
}

export interface ElavonTransaction {
  elavon_response: ElavonResponse;
  transaction: any;
  order: any;
}
export interface ElavonResponse {
    status: boolean;
    msg: string;
    xml: ElavonResponseXML;
}
export interface ElavonResponseXML {
  ssl_issuer_response: string;
  ssl_last_name: string;
  ssl_company: object;
  ssl_phone: object;
  ssl_card_number: string;
  ssl_departure_date: object;
  ssl_oar_data: string;
  ssl_result: string;
  ssl_txn_id: string;
  ssl_avs_response: string;
  ssl_approval_code: string;
  ssl_email: object;
  ssl_amount: string;
  ssl_avs_zip: string;
  ssl_transaction_currency: string;
  ssl_txn_time: string;
  ssl_description: object;
  ssl_exp_date: string;
  ssl_completion_date: object;
  ssl_address2: object;
  ssl_card_short_description: string;
  ssl_par_value: string;
  ssl_customer_code: object;
  ssl_country: object;
  ssl_card_type: string;
  ssl_transaction_type: string;
  ssl_salestax: string;
  ssl_avs_address: string;
  ssl_account_balance: string;
  ssl_ps2000_data: string;
  ssl_state: object;
  ssl_city: object;
  ssl_result_message: string;
  ssl_first_name: string;
  ssl_invoice_number: string;
  ssl_cvv2_response: string;
  ssl_partner_app_id: string;

  // Error properties
  errorCode?: string;
  errorMessage?: string;
  errorName?: string;
}
