import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AddressFieldService {

    constructor() {}
/**
 * Contains the predefined address formats.
 *
 * Derived from Google's dataset: https://chromium-i18n.appspot.com/ssl-address.
 */

/**
 * Returns the address format for the given country code.
 *
 * @param country_code
 *   The country code for which the address format should be returned.
 *
 * @return
 *   The address format array with the following keys:
 *   - used_fields: An array of fields used by this format. Possible values:
 *     'dependent_locality', 'locality', 'administrative_area', 'postal_code'.
 *   - required_fields: An array of required fields. See "used_fields".
 *   - dependent_locality_label: The label for the dependent locality field.
 *   - locality_label: The label for the locality field.
 *   - administrative_area_label: The label for the administrative area field.
 *   - postal_code_label: The label for the postal code field.
 *   - render_administrative_area_value: True if the value should be rendered
 *     instead of the ISO code. US example: California instead of CA.
 *     Only relevant for countries with predefined administrative areas.
 */
addressfield_get_address_format(country_code) {
  const default_values = {
    used_fields: ['locality'],
    required_fields: ['locality'],
    dependent_locality_label: 'Suburb',
    locality_label: 'City',
    administrative_area_label: 'Province',
    postal_code_label: 'Postal code',
    render_administrative_area_value: false,
};

  const address_formats = {};
  // These formats differ from the default only by the presence of the
  // postal code in 'used_fields'.
  const countries_with_optional_postal_code = [
    'AC', 'AD', 'AF', 'AI', 'AL', 'AZ', 'BA', 'BB', 'BD', 'BG', 'BH', 'BM',
    'BN', 'BT', 'CU', 'CR', 'CY', 'DO', 'DZ', 'EC', 'EH', 'ET', 'FO', 'GE',
    'GN', 'GT', 'GW', 'HR', 'HM', 'HT', 'IL', 'IS', 'JO', 'KE', 'KG', 'KH',
    'KP', 'KW', 'LA', 'LB', 'LK', 'LR', 'LS',  'MA', 'MC', 'MD', 'ME', 'MG',
    'MK', 'MM', 'MT', 'MU', 'MV', 'NE', 'NP', 'OM', 'PK', 'PY', 'RO', 'RS',
    'SA', 'SD', 'SI', 'SN', 'SZ', 'TA', 'TJ', 'TM', 'TN', 'TZ', 'VA', 'VC',
    'VG', 'XK', 'ZM',
  ];
  for (const code of countries_with_optional_postal_code) {
    address_formats[code] = {
      used_fields: ['locality', 'postal_code'],
    };
  }

  // These formats differ from the default only by the presence of the
  // postal code in 'used_fields' and 'required_fields'.
  const countries_with_required_postal_code = [
    'AT', 'AX', 'BE', 'BL', 'CH', 'CZ', 'DE', 'DK', 'FI', 'FK', 'FR', 'GF', 'GG',
    'GL', 'GP', 'GR', 'GS', 'HU', 'IM', 'IO', 'JE', 'LI', 'LU', 'MF', 'MQ', 'NC',
    'NL', 'NO', 'PL', 'PM', 'PN', 'PT', 'RE', 'SE', 'SH', 'SJ', 'SK', 'TC', 'WF',
    'YT',
  ];
  for (const code of countries_with_required_postal_code) {
    address_formats[code] = {
      used_fields: ['locality', 'postal_code'],
      required_fields: ['locality', 'postal_code'],
    };
  }

  address_formats['AE'] = {
    used_fields: ['administrative_area'],
    administrative_area_label: 'Emirate',
    render_administrative_area_value: true,
    required_fields: ['administrative_area'],
  };
  address_formats['AM'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
  };
  address_formats['AR'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
    render_administrative_area_value: true,
  };
  address_formats['AS'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
    postal_code_label: 'ZIP code',
  };
  address_formats['AU'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    locality_label: 'City/Suburb',
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
    postal_code_label: 'Postcode',
  };
  address_formats['BR'] = {
    used_fields: ['dependent_locality', 'locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    dependent_locality_label: 'Neighborhood',
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
  };
  address_formats['BS'] = {
    used_fields: ['locality', 'administrative_area'],
    administrative_area_label: 'Island',
  };
  address_formats['BY'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
  };
  address_formats['CA'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
  };
  address_formats['CC'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
  };
  address_formats['CL'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
    render_administrative_area_value: true,
  };
  address_formats['CN'] = {
    used_fields: ['dependent_locality', 'locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area'],
    dependent_locality_label: 'District',
    render_administrative_area_value: true,
  };
  address_formats['CO'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area'],
    administrative_area_label: {label: 'Department', array: [], context: 'Territory of a country'},
  };
  address_formats['CV'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: 'Island',
  };
  address_formats['CX'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
  };
  address_formats['EG'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: 'Governorate',
  };
  address_formats['EE'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'postal_code'],
    administrative_area_label: 'County',
    render_administrative_area_value: true,
  };
  address_formats['ES'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    render_administrative_area_value: true,
  };
  address_formats['FM'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
    postal_code_label: 'ZIP code',
  };
  address_formats['GB'] = {
    used_fields: ['locality', 'postal_code'],
    required_fields: ['locality', 'postal_code'],
    locality_label: 'Town/City',
    administrative_area_label: 'County',
    postal_code_label: 'Postcode',
  };
  address_formats['GI'] = {
    used_fields: ['postal_code'],
  };
  address_formats['GU'] = {
    used_fields: ['locality', 'postal_code'],
    required_fields: ['locality', 'postal_code'],
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
    postal_code_label: 'ZIP code',
  };
  address_formats['HK'] = {
    used_fields: ['locality', 'administrative_area'],
    required_fields: ['administrative_area'],
    locality_label: 'District',
    administrative_area_label: {label: 'Area', array: [], context: 'Territory of a country'},
  };
  address_formats['HN'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area'],
  };
  address_formats['ID'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['administrative_area'],
    locality_label: 'City/Regency',
    render_administrative_area_value: true,
  };
  address_formats['IE'] = {
    used_fields: ['dependent_locality', 'locality', 'administrative_area', 'postal_code'],
    locality_label: 'Town/City',
    administrative_area_label: 'County',
    postal_code_label: 'Eircode',
    render_administrative_area_value: true,
  };
  address_formats['IN'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
    postal_code_label: 'PIN code',
    render_administrative_area_value: true,
  };
  address_formats['IQ'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area'],
  };
  address_formats['IR'] = {
    used_fields: ['dependent_locality', 'locality', 'administrative_area', 'postal_code'],
    dependent_locality_label: 'Neighborhood',
  };
  address_formats['IT'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
  };
  address_formats['JM'] = {
    used_fields: ['locality', 'administrative_area'],
    required_fields: ['locality', 'administrative_area'],
    administrative_area_label: {label: 'Parish', array: [], context: 'Territory of a country'},
    render_administrative_area_value: true,
  };
  address_formats['JP'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: 'Prefecture',
    render_administrative_area_value: true,
  };
  address_formats['KI'] = {
    used_fields: ['locality', 'administrative_area'],
    administrative_area_label: 'Island',
  };
  address_formats['KN'] = {
    used_fields: ['locality', 'administrative_area'],
    required_fields: ['locality', 'administrative_area'],
    administrative_area_label: 'Island',
  };
  address_formats['KR'] = {
    used_fields: ['dependent_locality', 'locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    dependent_locality_label: 'District',
    render_administrative_area_value: true,
  };
  address_formats['KY'] = {
    used_fields: ['administrative_area', 'postal_code'],
    required_fields: ['administrative_area'],
    administrative_area_label: 'Island',
  };
  address_formats['KZ'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: {label: 'Region', array: [], context: 'Territory of a country'},
    render_administrative_area_value: true,
  };
  address_formats['LT'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'postal_code'],
    administrative_area_label: 'County',
  };
  address_formats['LV'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'postal_code'],
    administrative_area_label: 'Municipality',
  };
  address_formats['MH'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
    postal_code_label: 'ZIP code',
  };
  address_formats['MN'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
  };
  address_formats['MP'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
    postal_code_label: 'ZIP code',
  };
  address_formats['MX'] = {
    used_fields: ['dependent_locality', 'locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'postal_code'],
    dependent_locality_label: 'Neighborhood',
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
  };
  address_formats['MY'] = {
    used_fields: ['dependent_locality', 'locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'postal_code'],
    dependent_locality_label: 'Village / Township',
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
    render_administrative_area_value: true,
  };
  address_formats['MZ'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
  };
  address_formats['NF'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
  };
  address_formats['NG'] = {
    used_fields: ['dependent_locality', 'locality', 'administrative_area', 'postal_code'],
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
  };
  address_formats['NI'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: {label: 'Department', array: [], context: 'Territory of a country'},
  };
  address_formats['NR'] = {
    used_fields: ['administrative_area'],
    required_fields: ['administrative_area'],
    administrative_area_label: 'District',
  };
  address_formats['NZ'] = {
    used_fields: ['dependent_locality', 'locality', 'postal_code'],
    required_fields: ['locality', 'postal_code'],
    locality_label: 'Town/City',
    postal_code_label: 'Postcode',
  };
  address_formats['PA'] = {
    used_fields: ['locality', 'administrative_area'],
  };
  address_formats['PE'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    locality_label: 'District',
    administrative_area_label: {label: 'Department', array: [], context: 'Territory of a country'},
  };
  address_formats['PF'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: 'Island',
  };
  address_formats['PG'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area'],
  };
  address_formats['PH'] = {
    used_fields: ['dependent_locality', 'locality', 'administrative_area', 'postal_code'],
    render_administrative_area_value: true,
  };
  address_formats['PR'] = {
    used_fields: ['locality', 'postal_code'],
    required_fields: ['locality', 'postal_code'],
    postal_code_label: 'ZIP code',
  };
  address_formats['PW'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
    postal_code_label: 'ZIP code',
  };
  address_formats['RU'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    render_administrative_area_value: true,
  };
  address_formats['SC'] = {
    used_fields: ['locality', 'administrative_area'],
    administrative_area_label: 'Island',
  };
  address_formats['SG'] = {
    used_fields: ['postal_code'],
    required_fields: ['postal_code'],
  };
  address_formats['SM'] = {
    used_fields: ['locality', 'postal_code'],
    required_fields: ['postal_code'],
  };
  address_formats['SO'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area'],
  };
  address_formats['SR'] = {
    used_fields: ['locality', 'administrative_area'],
  };
  address_formats['SV'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area'],
  };
  address_formats['TH'] = {
    used_fields: ['dependent_locality', 'locality', 'administrative_area', 'postal_code'],
  };
  address_formats['TR'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'postal_code'],
    locality_label: 'District',
    render_administrative_area_value: true,
  };
  address_formats['TV'] = {
    used_fields: ['locality', 'administrative_area'],
    administrative_area_label: 'Island',
  };
  address_formats['TW'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
  };
  address_formats['UA'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: {label: 'Region', array: [], context: 'Territory of a country'},
  };
  address_formats['UM'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area'],
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
    postal_code_label: 'ZIP code',
  };
  address_formats['US'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
    postal_code_label: 'ZIP code',
  };
  address_formats['UY'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
  };
  address_formats['UZ'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
  };
  address_formats['VE'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area'],
    render_administrative_area_value: true,
  };
  address_formats['VI'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
    required_fields: ['locality', 'administrative_area', 'postal_code'],
    administrative_area_label: {label: 'State', array: [], context: 'Territory of a country'},
    postal_code_label: 'ZIP code',
  };
  address_formats['VN'] = {
    used_fields: ['locality', 'administrative_area', 'postal_code'],
  };
  address_formats['ZA'] = {
    used_fields: ['dependent_locality', 'locality', 'postal_code'],
    required_fields: ['locality', 'postal_code'],
  };

  if (address_formats[country_code]) {
    return address_formats[country_code] + default_values;
  }
  else {
    // There is no predefined address format for the requested country,
    // but the defaults should work fine.
    return default_values;
  }
}

}
