/* eslint-disable @typescript-eslint/naming-convention */
import { formatCurrency } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { MessagesService } from './messages.service';

@Injectable({
    providedIn: 'root'
  })
export class EventsService {

  jwt: string;
  csrf: string;
  USERdata: any;
  registeredEvents: any;
  headers = { 'Content-Type': 'application/json' };
  requestOptions = { headers: new HttpHeaders(this.headers) };
  headersBasic = {
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('app' + ':' + 'password')
  };
  requestOptionsBasic = { headers: new HttpHeaders(this.headersBasic) };

    constructor(
      private messages: MessagesService,
        private http: HttpClient) { }

    // the API GET request for the Events from the church website
    getWebformFields(event): Promise<any> {
      return this.http.get(environment.webform.get + event.uuid).toPromise()
      .then(async (data: any) => {
        const result = [];
          const components = data.webform.components;
          let fields = [];
          fields = Object.keys(components).map(key => {
            fields = components[key];
            return fields;
          });
          for (const field of fields) {
            if (field.type === 'select') {
              const options = [];
              let optionsOld = field.extra.items;
                if (optionsOld !== null || optionsOld !== undefined) {
                  optionsOld = optionsOld.split(/[\r\n]/).filter(e => e);
                  Object.keys(optionsOld)
                  .map(i => {
                    const optionArray = optionsOld[i].split('|');
                    const key = optionArray[0];
                    const value = optionArray[1];
                    options.push({key, value});
                    return options;
                  });
                  field.options = options;
                }
            }

            if (field.type === 'productfield') {
                const items = Object.keys(field.extra.items);
                field.quantity = 1;
                const prices = [];
                for (const productID of items) {
                  const value = field.extra.items[productID];
                  prices.push(value);
                }
                field.options = prices;
            }

            if (field.type === 'time') {
              field.presentation = 'time';
              if (field.extra.minuteincrements === '5') {
                field.minute_values = [0,5,10,15,20,25,30,35,40,45,50,55];
              }
              if (field.extra.minuteincrements === '10') {
                field.minute_values = [0,10,20,30,40,50];
              }
              if (field.extra.minuteincrements === '15') {
                field.minute_values = [0,15,30,45];
              }
              if (field.extra.minuteincrements === '30') {
                field.minute_values = [0,30];
              }
            }

            if (field.type === 'date') {
                field.value = new Date().toISOString();
                if (field.extra.exclude?.indexOf('day') > -1 && field.extra.exclude?.indexOf('month') > -1) {
                  field.presentation = 'year';
                } else {
                  field.presentation = 'date';
                }
                if (field.extra.start_date) {
                  const minDate = field.extra.start_date.split(' ')[0];
                  console.log(minDate);
                  field.min = new Date().getFullYear() + +minDate;
                }
                if (field.extra.end_date) {
                  const maxDate = field.extra.end_date.split(' ')[0];
                  console.log(maxDate);
                  field.max = new Date().getFullYear() + +maxDate;
                }

            }

            const newField = field;
            result.push(newField);
          }
        result.sort((a, b) => a.weight - b.weight);
        const submissions = await this.getWebformSubmissions(data.submissions);
        return {webform: data, fields: result, submissions};
      },
      error => {
        console.log('Error Message: ', error);
      });
  }

  async getWebformSubmissions(webformURL: string) {
    return this.http.get(webformURL).toPromise()
    .then((submissions: any) => {
      submissions.map(submission => {
        Object.keys(submission.data).map((key) => {
          const field = submission.data[key];
          if (field.type === 'productfield') {
            if (Array.isArray(field.values)) {
              for (let val of field.values) {
                val = JSON.parse(val);
              }
            } else {
              field.values = JSON.parse(field?.values);
            }
          }
        });
      });
      return submissions;
    }).catch(err => err);
  }

  async getUserWebformSubmissions(uid: string, options): Promise<any> {
    return this.http.get(environment.views.webformSubmissions + uid, options).toPromise()
    .then(data => data,
    error => {
      console.log('Error', error);
      return error;
    });
}


  createWebformSubmission(formData, options): Promise<any> {
      return this.http.post(environment.webform.create, formData, options).toPromise()
      .then(data => data,
      error => {
        console.log('Error', error);
        return error;
      });
  }

  createEntityformSubmission(formData, options): Promise<any> {
      return this.http.post(environment.entityform.create, formData, options).toPromise()
      .then(data => data,
      error => {
        console.log('Error', error);
        return error;
      });
  }

}

export class Event {
    title: string;
    nid: string;
    langcode: string;
    body: string;
    field_connection_group_category: string;
    contact_person: [''];
    field_end_date: string;
    field_icon_image: string;
    field_promotional_image: string;
    field_start_date: string;
    webform_id: string;
    webform_url: string;
    promote: boolean;
    field_start_time: string;
    field_end_time: string;
    field_external_link: string;
    field_external_link_title: string;
    register_other_users: boolean;
    field_call_to_action: string;

    constructor(title, nid, langcode, body,
        field_connection_group_category, contact_person,
        field_end_date, field_icon_image, field_promotional_image,
        field_start_date, webform_id, webform_url, promote,
        field_start_time, field_end_time, field_external_link,
        field_external_link_title, register_other_users, field_call_to_action) {
      this.title = title;
      this.nid = nid;
      this.langcode = langcode;
      this.body = body;
      this.field_connection_group_category = field_connection_group_category;
      this.contact_person = contact_person;
      this.field_end_date = field_end_date;
      this.field_icon_image = 'https://account.lancasterbaptist.org' + field_icon_image;
      this.field_promotional_image = 'https://account.lancasterbaptist.org' + field_promotional_image;
      this.field_start_date = field_start_date;
      this.webform_id = webform_id;
      this.webform_url = webform_url;
      this.promote = promote;
      this.field_start_time = field_start_time;
      this.field_end_time = field_end_time;
      this.field_external_link = field_external_link;
      this.field_external_link_title = field_external_link_title;
      this.register_other_users = register_other_users;
      this.field_call_to_action = field_call_to_action;
    }
  }
