import { addHours, getUnixTime } from 'date-fns';
import { Entity, CreatedEntity } from './entity';

export interface User extends Entity {
  mail: string;
  access?: number;
  data?: boolean;
  init?: string;
  login?: number;
  roles?: {[rid: number]: string};
  signature?: string;
  signature_format?: string;
  theme?: number;
  timezone?: string;
  timestamp?: number;
  pass?: string;
  flag_access?: {flag: {[key: string]: any}, unflag: {[key:string]: any}};
  role_types?: {[key:string]: boolean};

  first_name?: {und: [{value: string}]};
  last_name?: {und: [{value: string}]};
  field_stripe?: {und: [{value: string}]};
  cell_phone?: {und: [{value: string}]};
  uuid?: string;
  ssid?: string;
  sid?: string;
  
  visit_first_name?: {und: [{value: string}]};
  visit_last_name?: {und: [{value: string}]};
  person_phone_number?: {und: [{value: string}]};
  gender?: {und: string};
  marital_status?: {und: {select: string}};
  birthday?: {und: [{value: {date: string}}]};
  connection_group?: {und: [{target_id: string}]};
  person_job?: {und: [{value: string}]};
  
  [key: string]: any;
}


export interface LoginCredentials {
  username: string;
  password: string;
}

export interface CreatedUser extends CreatedEntity {
  uid: number;
}

export interface PasswordReset {
  uid: number;
  timestamp:  number;
  hashed_pass: string;
}

export interface PasswordResetResponse {
  message: string;
  pass_reset_token: string;
}

export interface PasswordChangedResponse {
  roles: {[key: string]: string};
  uid: number;
}

// Custom models

export class LoginCredentialsClass {
  username: string;
  password: string;
  constructor(username: string, password: string) {
    this.username = username;
    this.password = password;
  }
}
export class JWT {
  jwt: string;
  constructor(jwt: string) {
    this.jwt = jwt;
  }
}

export class SessionWithFields {
  session: any;
  userFields: any;
  constructor(session: any, userFields: any) {
    this.session = session;
    this.userFields = userFields;
  }
}

export interface FakeSession {
  user: {
    mail: string;
    uid: string;
  };
}

export class UserAccount {
  [key: string]: any;

  created: string;
  name: string;
  mail: string;
  pass: string;
  user_name: {und: [{value: string}]};
  phone_number: {und: [{value: string}]};
  linked_church: {und: string};
  group_manager: {und: string};

  constructor(userName, mail, pass, phone?) {
    this.created = getUnixTime(addHours(new Date(), 8)).toString();
    this.mail = mail;
    this.pass = pass;
    this.user_name = {und: [{value: userName}]};
    this.phone_number = {und: [{value: phone}]};
  }
  set(key, value) {
    UserAccount[key] = value;
  }
}

