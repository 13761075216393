import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CommerceService } from '../services/commerce.service';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DrupalEntityBundleInfo, DrupalFormControlObject, EntityService } from '../services/entity.service';
import { AlertController, IonicModule, ModalController } from '@ionic/angular';
import { format, parseISO } from 'date-fns';
import { FormsService } from '../services/forms.service';
import { PaymentPage } from '../commerce/payment/payment.page';
// Import JSON Files
import AdministrativeArea from '../../assets/address/administrative_area.json';
import USStates from '../../assets/address/us_states.json';
import { MessagesService } from '../services/messages.service';
import { environment } from '../../environments/environment.prod';
import { Navigation, Router } from '@angular/router';
import { StorageService } from '../services/storage/storage.service';
import { CommonModule } from '@angular/common';
import { SystemConnection } from '../services/drupal7/public_api';
import { UserServiceCustom } from '../services/user.service';
import { UserAccount } from '../services/drupal7/models/user';

@Component({
  selector: 'app-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class FormComponent implements OnInit {

  @Input() entityType: string;
  @Input() entityBundle: string;
  @Input() requestOptions: any;
  @Input() loadedEntity: any;
  session: SystemConnection;

  entityForm: FormGroup;
  bundleInfo: DrupalEntityBundleInfo;
  entityJSON: Array<DrupalFormControlObject>;
  nestedFieldGroups: Array<DrupalFormControlObject>;
  activeIndex: number;
  deviceLinked = false;
  deviceNumber: object;

  formSubmitLabel: string;
  formPreviewLabel: string;

  administrativeArea = AdministrativeArea;
  dynamicAdministrativeArea: any;
  states: object;
  adminRole: boolean;
  admissionsGuide: boolean;
  adminContent: boolean;
  // appUser: any;
  maxDate = (new Date().getFullYear() + 3).toString();
  order = null;

  constructor(
    private fb: FormBuilder,
    private alertCtrl: AlertController,
    private auth: AuthService,
    private commerce: CommerceService,
    private modalController: ModalController,
    public message: MessagesService,
    private user: UserServiceCustom,
    private router: Router,
    private entity: EntityService,
    private forms: FormsService,
    public storage: StorageService
  ) {
    const nav: Navigation = this.router.getCurrentNavigation();

    if (nav?.extras && nav.extras.state) {
      this.entityType = nav.extras.state.entityType;
      this.requestOptions = nav.extras.state.requestOptions;
      this.entityBundle = nav.extras.state.entityBundle;
      if (nav.extras.state.loadedEntity) {
        this.loadedEntity = nav.extras.state.loadedEntity;
      }
    }

    // this.appUser = this.user.getAppUser();
    this.dynamicAdministrativeArea = USStates;
  }

  get errorControl() {
    return this.entityForm.controls;
  }

  ngOnInit() {
    console.log('loaded the form');
    this.user.currentSession.subscribe(session => this.session = session);
    console.log(this.entityType);
    console.log(this.entityBundle);
    console.log(this.session);
    console.log(this.loadedEntity);
    if (this.loadedEntity) {
      this.getForm(this.entityType, this.entityBundle, 'Update', 'Preview');
    } else {
      this.getForm(this.entityType, this.entityBundle, 'Create', 'Preview');
    }
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(()=>resolve(), ms));
  }

   async getAutoCompleteOptions(e: any, field: DrupalFormControlObject) {
    console.log(e);
    console.log(field);
    const url = `${environment.ApiURL}available/${field.field_name}?id=${e.detail.value}`;
    return field.options = await this.auth.get(url, this.requestOptions).toPromise();
  }

  getForm(entityType, entityBundle,
    entitySubmitLabel = 'Submit', previewLabel = 'Preview', externalSource = false, viewMode = 'form', extra = 'none') {
    this.forms.getForm(this.session, entityType,
      entityBundle, entitySubmitLabel, previewLabel, externalSource, viewMode, extra).then(res => {
      this.activeIndex = res.activeIndex;
      this.entityJSON = res.formFields;
      this.entityForm = res.formGroup;
      this.nestedFieldGroups = res.nestedFieldGroups;
      this.entityBundle = res.entityBundle;
      this.bundleInfo = res.bundleInfo;
      this.entityType = res.entityType;
      this.formPreviewLabel = res.formPreviewLabel;
      this.formSubmitLabel = res.formSubmitLabel;
      console.log(this.entityForm);
      console.log(this.entityJSON);
      console.log(this.loadedEntity);
      if (this.loadedEntity) {
        this.loadEntity();
      }
    });
  }

  loadEntity() {
    Object.keys(this.entityForm.controls).map(key => {
      const control = this.entityForm.get(key);
      if (control && !control['controls']) {
        if (typeof this.loadedEntity[key] === 'object') {
          const formField = this.getFieldControl(key);
          console.log(formField);
          control.setValue(this.loadedEntity[key].und[0].value);
        } else {
          control.setValue(this.loadedEntity[key]);
        }
      }
      if (Array.isArray(control['controls']) && control['controls']) {
        let z = 0;
        const fieldCollection = this.nestedFieldGroups.filter(o => o.field_name === key)[0];
        // Remove the initial control since we will add controls for each entity loaded
        this.removeArrayGroup(key, z);
        for (const arrayItem of this.loadedEntity[key]) {
          this.addArrayGroup(key, fieldCollection.fields);
          Object.keys(arrayItem).map(nestedKey => {
            const arrControl = control['controls'][z].get(nestedKey);
            if (arrControl) {
              console.log(typeof arrayItem[nestedKey]);
              arrControl.setValue(arrayItem[nestedKey]);
            }
          });
          z++;
        }
      } else if (typeof control['controls'] === 'object' && control['controls'] !== null) {
        console.log('this control is an object');
        Object.keys(control['controls']).map(nestedKey => {
          const nestedControl = control.get(nestedKey);
          console.log(typeof this.loadedEntity[key + '_' + nestedKey]);
          nestedControl.setValue(this.loadedEntity[key + '_' + nestedKey]);
        });
      }

    });
    console.log(this.entityForm.value);

  }

  getFieldControl(key: string) {
    if (this.entityForm.controls[key]) {
      for (const g of this.entityJSON) {
        if (g.fields) {
          const formField: DrupalFormControlObject = g.fields.filter(o => o.field_name === key)[0];
          console.log(formField);
          if (formField.field_name === key) {
            return formField;
          }
        } else {
          return this.entityJSON.filter(o => o.field_name === key)[0];
        }
      }
    }
  }


  checkNextGroup(i) {
    this.entityJSON[i + 1].visible = true;
  }

  goToFieldGroup(i) {
    let x = 0;
    for (const fieldGroup of this.entityJSON) {
      if (x === i - 1) {
        for (const field of fieldGroup.fields) {
          if (!this.entityForm.controls[field.field_name].valid &&
            this.entityForm.controls[field.field_name].hasValidator(Validators.required)) {
            this.fixErrors(field);
            return;
          }
        }
        this.entityJSON[x].access = false;
      } else if (x === i ) {
        this.entityJSON[x].access = true;
        this.activeIndex = x;
      } else {
        this.entityJSON[x].access = false;
      }
      x++;
    }
  }

  async fixErrors(field) {
    const alert = await this.alertCtrl.create({
      header: 'Missing Information',
      message: '<strong>' + field.label + '</strong> is required.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'OK',
          cssClass: 'primary',
        }
      ]
    });
    await alert.present();
  }

  prepareStorageData() {
    const preview: object = {};
    console.log(this.entityJSON);
    for (const group of this.entityJSON) {
        for (const field of group.fields) {
          switch(field.ionic_type) {
            case 'date': {
              console.log(this.entityForm.value[field.field_name]);
              preview[field.field_name] = this.forms.formatDate(this.entityForm, field);
              break;
            }
            case 'enddate_get': {
              Object.keys(this.entityForm.value[field.field_name])
              .map(key => {
                const value = this.entityForm.value[field.field_name][key];
                preview[field.field_name][key] = format(parseISO(value), 'yyyy-MM-dd');
              });
              break;
            }
            case 'boolean': {
              if (this.entityForm.value[field.field_name]) {
                preview[field.field_name] = 'Yes';
              } else {
                preview[field.field_name] = 'No';
              }
              break;
            }
            default: {
              preview[field.field_name] = this.entityForm.value[field.field_name];
            }
          }
        }
    }
    console.log(this.entityForm.value);
    return preview;
  }

  addArrayField(arrayName) {
    const control = this.getFormArray(arrayName);
    control.push(new FormControl(''));
  }

  addArrayGroup(arrayName, fields) {
    const control = this.getFormArray(arrayName);
    const oneGroup = new FormGroup({});
    fields.map(child => {
      oneGroup.addControl(child.field_name, new FormControl());
    });
    control.push(oneGroup);
  }

  removeArrayGroup(arrayName, index) {
    const control = this.getFormArray(arrayName);
    control.removeAt(index);
  }

  getFormArray(key) {
    return this.entityForm.controls[key] as FormArray;
  }

  async preparePreview() {
    console.log(this.entityForm.value);
    const preview: any = {};

    for (const group of this.entityJSON) {
      if (group.ionic_type === 'field_group') {
        for (const field of group.fields) {
          switch(field.ionic_type) {
            case 'date': {
              preview[field.field_name] = this.forms.formatDate(this.entityForm, field);
              break;
            }
            case 'enddate_get': {
              Object.keys(this.entityForm.value[field.field_name])
              .map(key => {
                const value = this.entityForm.value[field.field_name][key];
                preview[field.field_name][key] = format(parseISO(value), 'yyyy-MM-dd');
              });
              break;
            }
            case 'boolean': {
              if (this.entityForm.value[field.field_name]) {
                preview[field.field_name] = 'Yes';
              } else {
                preview[field.field_name] = 'No';
              }
              break;
            }
            default: {
              preview[field.field_name] = this.entityForm.value[field.field_name];
            }
          }
        }
      } else {
        preview[group.field_name] = this.entityForm.value[group.field_name];
        if (group.ionic_type === 'date') {
          preview[group.field_name] = this.forms.formatDate(this.entityForm, group);
        }
        if (group.ionic_type === 'enddate_get') {
          Object.keys(this.entityForm.value[group.field_name])
          .map(key => {
            const value = this.entityForm.value[group.field_name][key];
            preview[group.field_name][key] = format(parseISO(value), 'yyyy-MM-dd');
          });
        }
      }
    }

    // Initialize some form values
    preview.type = this.entityBundle;
    console.log(this.session);
    if (this.session) {
      preview.author = this.session.user.uid;
    } else {
      preview.author = environment.appSettings.AppUserUID;
    }

    console.log(this.entityForm.value);
    return preview;
  }

  getNestedDateValue(field: DrupalFormControlObject, i: number) {
    let res = '';
    Object.keys(this.entityForm.controls).map(key => {
      const control = this.entityForm.get(key);
      if (Array.isArray(control['controls']) && control['controls']) {
        const arrControl = control['controls'][i].get(field.field_name);
          if (arrControl && arrControl.value) {
            return res = arrControl['formattedValue'] = format(parseISO(arrControl.value), 'EEEE, MMMM dd, yyyy');
          }
      }
    });
    return res;
  }

  checkDateField(field) {
    if (field.ionic_type === 'date' || field.ionic_type === 'enddate_get') {
      this.entityForm.get(field.field_name)['formattedValue'] = this.forms.formatDate(this.entityForm, field);
    }
  }

  showPicker(group: any, child: any, i: number) {
    console.log(group);
    child.show_picker = !child.show_picker;
  }

  conditionalField(event, dependeeField) {
    if (dependeeField.conditional_controlling) {
      let v;
      if (event.detail.checked !== undefined) {
        v = event.detail.checked;
      } else {
        v = event.detail.value;
      }
      for (const condition of dependeeField.conditional_controlling) {
        switch(condition.field_state) {
          case 'visible': {
            if (condition.key.includes(v)) {
              this.switchConditionalFields(condition, true, dependeeField);
            } else {
              this.switchConditionalFields(condition, false, dependeeField);
            }
            break;
          }
          case 'hidden': {
            if (condition.key.includes(v)) {
              this.switchConditionalFields(condition, false, dependeeField);
            } else {
              this.switchConditionalFields(condition, true, dependeeField);
            }
            break;
          }
          case 'required': {
            for (const dependentField of condition.field_names) {
              const fieldControl = this.entityForm.get(dependentField);
              for (const fieldGroup of this.entityJSON) {
                const index = fieldGroup.fields.map((o) => o.field_name).indexOf(dependentField);;
                this.conditionalControlledByField(fieldGroup.fields[index], fieldControl);
              }
            }
          }
        }
      }
    }
  }

  conditionalControlledByField(dependentField, control) {
    if (dependentField.conditional_controlled_by) {
        if (!this.entityForm.value.register_wcbc_alumni) {
          dependentField.access = false;
          if (dependentField.required !== undefined) {
            dependentField.required = false;
          }
          control.removeValidators(Validators.required);
        } else {
          dependentField.access = true;
          if (!control.hasValidator(Validators.required)) {
            if (dependentField.required !== undefined) {
              dependentField.required = true;
              control.addValidators(Validators.required);
            }
          }
        }
    }
  }

  switchConditionalFields(condition, newState, dependeeField) {
    for (const dependentField of condition.field_names) {
      for (const fieldGroup of this.entityJSON) {
        const index = fieldGroup.fields.map((o) => o.field_name).indexOf(dependentField);;
        if (index !== -1 && index !== undefined) {
          fieldGroup.fields[index].access = newState;
          const fieldControl = this.entityForm.get(dependentField);
          if (!newState) {
            if (fieldControl.hasValidator(Validators.required)) {
              if (fieldGroup.fields[index].required !== undefined) {
                fieldGroup.fields[index].required = false;
                if (fieldGroup.fields[index].fields) {
                  for (const field of fieldGroup.fields[index].fields) {
                    if (field.required !== undefined) {
                      field.required = false;
                      if (fieldControl['controls']) {
                        fieldControl['controls'][field.field_name].removeValidators(Validators.required);
                        if (fieldControl['controls'][field.field_name].errors) {
                          fieldControl['controls'][field.field_name].errors.required = undefined;
                        }
                      }
                    }
                  }
                }
              }
              fieldControl.removeValidators(Validators.required);
            }
          } else if (fieldGroup.fields[index].required !== undefined) {
            fieldGroup.fields[index].required = true;
            if (fieldGroup.fields[index].fields) {
              for (const field of fieldGroup.fields[index].fields) {
                if (field.required !== undefined) {
                  field.required = true;
                  if (fieldControl['controls']) {
                    fieldControl['controls'][field.field_name].addValidators(Validators.required);
                    if (fieldControl['controls'][field.field_name].errors) {
                      fieldControl['controls'][field.field_name].errors.required = true;
                    }
                  }
                }
              }
            }
            fieldControl.addValidators(Validators.required);
          }
          if (fieldGroup.fields[index].conditional_controlled_by) {
            this.conditionalControlledByField(fieldGroup.fields[index], fieldControl);
          }
        }
      }
    }

  }

  updateAdministrativeAreas(country, options, addressField) {
    options = this.administrativeArea.filter(administrativeArea => administrativeArea.key === country.detail.value);
    if (options[0].value) {
      let newOptions = {};
      newOptions = Object.keys(options[0].value)
      .map(key => {
        const value = options[0].value[key];
        newOptions = {key, value};
        return newOptions;
      });
      this.dynamicAdministrativeArea = newOptions;
    } else {
      this.dynamicAdministrativeArea = null;
    }
  }

  prepareFormValues() {
    for (const group of this.entityJSON) {
      if (group.ionic_type === 'field_group') {
        for (const field of group.fields) {
          this.formatNodeFormFieldValues(field);
        }
      } else {
        this.formatNodeFormFieldValues(group);
      }
    }
    return this.entityForm.value;
  }

  formatNodeFormFieldValues(field) {
    if (!field.required && this.entityForm.value[field.field_name] === null) {
      delete this.entityForm.value[field.field_name];
    }
    switch(field.ionic_type) {
      case 'markup':
        delete this.entityForm.value[field.field_name];
        break;
      case 'date':
        const unixTime = new Date(this.entityForm.value[field.field_name]).valueOf().toString().substring(0, 10);
        // const formattedString = format(parseISO(this.entityForm.value[field.field_name]), 'yyyy-MM-dd HH:mm:ss');
        this.entityForm.value[field.field_name] = unixTime;
        break;
      case 'enddate_get':
        Object.keys(this.entityForm.value[field.field_name])
        .map(key => {
          const value = this.entityForm.value[field.field_name][key];
          this.entityForm.value[field.field_name][key] = format(parseISO(value), 'yyyy-MM-dd HH:mm:ss');
        });
        break;
      default:
        break;
    }
    if (field.type === 'commerce_price') {
      this.entityForm.value[field.field_name] = {amount: this.entityForm.value[field.field_name] * 100, currency_code: 'USD'};
    }
    if (field.text_processing) {
      this.entityForm.value[field.field_name] = {value: this.entityForm.value[field.field_name]};
    }
  }

  prepareUserRegisterValues() {
    console.log(this.entityForm.value);
    const newUser = new UserAccount(
      this.entityForm.value.name,
      this.entityForm.value.mail,
      this.entityForm.value.pass
    );
    // Remove null values from the body since the api will return errors
    Object.keys(newUser)
    .map(key => {
      const value = this.entityForm.value[key];
      if (value === null) {
        delete newUser[key];
      }
    });
    return newUser;
  }

  async previewSubmission() {
    const alert = await this.alertCtrl.create({
      header: 'Preview',
      message: JSON.stringify(this.entityForm.value),
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Cancel');
          }
        }, {
          text: 'Submit',
          cssClass: 'primary',
          handler: () => {
            this.create(this.entityType, this.entityBundle);
          }
        }
      ]
    });
    await alert.present();
  }

  submit() {
    if (this.loadedEntity) {
      this.prepareFormValues();
      const body = this.entityForm.value;
      console.log(body);
      console.log(this.loadedEntity);

      // this.update();
    } else {
      // this.create(this.entityType, this.entityBundle)
    }
  }

  async create(entityType: string, entityBundle: string) {
      switch(entityType) {
        case 'user': {
          if (entityBundle === 'user') {
            const body = this.prepareUserRegisterValues();
            const newAccount = await this.user.register(body);
            if (newAccount) {
              const loginBody = {
                username: this.entityForm.value.name,
                password: this.entityForm.value.pass
              };
              await this.user.login('session', loginBody, true, environment.checkInType.entityType, environment.checkInType.bundle);
            }
          }
          if (entityBundle === 'login') {
            await this.user.login('session', this.entityForm.value, true, environment.checkInType.entityType, environment.checkInType.bundle);
          }
          break;
        }
        case 'entityform':
        case 'node': {
          this.session = await this.storage.get('session');
          if (this.session) {
            this.entityForm.value.author = this.session.user.uid;
          } else {
            this.entityForm.value.author = environment.appSettings.AppUserUID;
          }
          this.entityForm.value.type = entityBundle;
          this.prepareFormValues();
          const options =  this.auth.setupRequestOptions('csrf', this.session);
          this.entity.createEntity(this.entityType, this.entityForm.value, options).subscribe(async res => {
            this.continueToPayment();
            this.resetForm();
          }, error => {
            console.log(error);
            this.message.presentAlert('Error', 'There was a problem. Error Code: ' + error.message);
          });
          break;
        }
        case 'commerce_customer_profile': {
          this.entityForm.value.type = entityBundle;
          this.entityForm.value.status = 1;
          const options = this.auth.setupRequestOptions('csrf', this.session);
          this.commerce.createCommerceCustomerProfile(this.entityForm.value, options, entityType, entityBundle).subscribe(res => {
            this.resetForm();
          }, error => {
            console.log(error);
            this.message.presentAlert('Error', 'There was a problem. Error Code: ' + error.message);
          });
          break;
        }
        default: {
          break;
        }
    }
  }

  async update() {
    // const updatedEntity = await this.entity.updateField(this.entityType, this.loadedEntity.id, body, this.requestOptions).toPromise();
    // return updatedEntity;
  }

  async getCart() {
    if (this.session) {
      const options = this.auth.setupRequestOptions('csrf', this.session);
      this.commerce.getCommerceCart(options).subscribe(async res => {
        if (res === undefined) {
          this.commerce.createCommerceCart(options).subscribe(newCart => this.buildOrder(newCart));
        } else {
          await this.buildOrder(res);
        }
      }, error => {
        console.log(error);
        this.message.presentAlert('Error', 'There was a problem. Error Code: ' + error.message);
      });
    }
  }

  async getCommerceOrder() {
    if (this.session) {
      const options = this.auth.setupRequestOptions('csrf', this.session);
      this.commerce.getCommerceOrder(this.order.order_id, options).subscribe(async res => {
        console.log(res);
        if (res.status === 'invoiced') {
          this.logoutAndContinue(res);
        } else {
          await this.getCart();
          this.message.presentAlert('Payment successful',
          'Your payment was successful, however it looks like you still have a balance on order ' + this.order.order_id);
        }
      });
    }
  }

  async logoutAndContinue(res) {
    const alert = await this.alertCtrl.create({
      header: 'Success!',
      backdropDismiss: false,
      message: 'Your payment was successful. An invoice has been sent to ' + res.mail,
      buttons: [
        {
          text: 'Complete',
          cssClass: 'primary',
          handler: async () => {
            this.appLogout();
          }
        }
      ]
    });
    await alert.present();
  }

  async continueToPayment() {
    const alert = await this.alertCtrl.create({
      header: 'Success!',
      backdropDismiss: false,
      message: 'Would you like to pay the application fee now or logout?',
      buttons: [
        {
          text: 'Logout',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Logging out');
            this.appLogout();
          }
        }, {
          text: 'Pay Now',
          cssClass: 'primary',
          handler: async () => {
            this.paymentModal();
          }
        }
      ]
    });
    await alert.present();
  }

  buildOrder(res) {
    if (res.status !== 'invoiced') {
      if (res.commerce_line_items_entities) {
        res.commerce_line_items_entities = this.forms.convertObjectToArray(res.commerce_line_items_entities);
      }
      if (res.commerce_customer_billing_entities) {
        res.commerce_customer_billing_entities = this.forms.convertObjectToArray(res.commerce_customer_billing_entities);
      }
      this.order = res;
      console.log(this.order);
      return this.order;
    }
  }

  resetForm() {
    this.entityForm = this.fb.group({});
    this.entityJSON = null;
  }

  async paymentModal() {
    const modal = await this.modalController.create({
      component: PaymentPage,
      cssClass: 'payment-method',
      backdropDismiss: false,
      componentProps: {
        order: this.order,
        user: this.session,
        // appUser: this.appUser,
        adminRole: this.adminRole,
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data !== undefined) {
      console.log(data);
      await this.getCommerceOrder();
      console.log(this.order);
    }
  }

  appLogout() {
    this.resetForm();
    this.user.logout().then(() => {
      this.session = null;
      // this.appUser = null;
      this.order = null;
    });
    this.getForm('user', 'user', 'Create new account');
  }

  async delete() {
    if (this.session.user.uid === 1) {
      const invalid = await this.alertCtrl.create({
        header: 'Access denied',
        message: 'This user account cannot be removed.',
        buttons: [{
          text: 'OK',
          role: 'cancel',
          cssClass: 'secondary'
        }]
      });
      await invalid.present();
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Confirm Deleting Your Account',
        message: '<p>Are you sure you want to delete your account? This cannot be undone.</p><p>Type the word DELETE to confirm.</p>',
        inputs: [
          {
            name: 'delete',
            type: 'text',
            id: 'delete',
            placeholder: '',
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: 'DELETE ACCOUNT',
            cssClass: 'primary',
            handler: async form => {
              if (form.delete === 'DELETE') {
                const removedAccount = await this.user.cancel(this.session.user.uid, this.session);
                console.log(removedAccount);
              } else {
                const retry = await this.alertCtrl.create({
                  header: 'Invalid response',
                  message: 'The word you entered was incorrect. Please try again.',
                  buttons: [{
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                      console.log('Confirm Cancel');
                    }
                  }, {
                    text: 'Go back',
                    cssClass: 'primary',
                    handler: () => {
                      this.delete();
                    }
                  }]
                });
                await retry.present();
              }
            }
          }
        ]
      });
      await alert.present();

    }
  }

  cancelModal() {
    this.modalController.dismiss();
  }

}
