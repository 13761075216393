/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { MessagesService } from './messages.service';
import { AlertController } from '@ionic/angular';
import { StorageService } from './storage/storage.service';


export interface DrupalEntityType {
  bundle_info: DrupalEntityBundleInfo;
  flag_permissions: Array<any>;
  form_fields: Array<DrupalFormControlObject>;
}

export interface DrupalEntityBundleInfo {
  type: string;
  name: string;
  base: string;
  module: string;
  description?: string;
  help?: string;
  has_title?: string;
  title_label?: string;
  custom?: string;
  modified?: string;
  locked?: string;
  disabled?: string;
  orig_type?: string;
  disabled_changed?: boolean;
  entity_type: string;
  multipage?: boolean;
}
export interface DrupalFormControlObject {
  // Required properties for all fields
  field_name: string;
  label: string;
  type: string;
  ionic_type: string;
  status: boolean;
  access: boolean;

  // Optional properties
  placeholder?: string;
  required?: boolean;
  description?: string;
  default_value?: any;
  visible?: boolean;

  // Multiple value fields
  multiple?: boolean;
  array_label?: string;

  // Field type properties
  options?: Array<Options> | Array<CommerceProductSKUS>; // Lists
  fields?: Array<DrupalFormControlObject>; // Field groups, field collections, link field
  text_processing?: boolean; // Plain text or Filtered text
  presentation?: string; // Date field
  show_picker?: boolean; // Date field
  markup_value?: string; // Markup field

  // Field permissions
  field_permissions_settings?: string;
  create?: boolean;
  edit?: boolean;
  edit_own?: boolean;
  view?: boolean;
  view_own?: boolean;
  field_permissions_role_access?: object;

  // Additional properties
  module?: string;
  widget_type?: string;
  display?: object;

  // Field dependencies
  conditional_controlled_by?: Array<DependentField>;
  conditional_controlling?: Array<DependeeField>;

}

export interface WebformFieldControlObject extends DrupalFormControlObject {
  cid: string;
  form_key: string;
  extra: WebformExtra;
  pid: string;
  nid: string;
  weight: string;
  name: string;
  page_num: number;
  value: string;
  min?: number; // Date field
  max?: number; // Date field
  minute_values?: Array<number>; // Time field
  quantity?: number;
  options?: Array<CommerceProductSKUS>;
}

export interface WebformExtra {
  product_type: any[];
  items: CommerceProductSKUS;
  multiple: number;
  choose_quantity: number;
  choose_quantity_min: string;
  choose_quantity_max: string;
  hide_price: string;
  title_display: string;
  description_above: number;
  private: number;
  wrapper_classes: string;
  css_classes: string;
  aslist: number;
  optrand: number;
  other_option?: any;
  other_text: string;
  description: string;
  custom_keys: boolean;
  options_source: string;
  exclude: string[];
  start_date: string;
  end_date: string;
  timezone: string;
  year_textfield: number;
  datepicker: number;
  analysis: boolean;
  format: string;
  width: string;
  unique: number;
  disabled: number;
  placeholder: string;
  attributes: any[];
  webform_select_limit: WebformSelectLimit;
  empty_option: string;
}

export interface WebformSelectLimit {
  enable_webform_select_limit: number;
  webform_select_limit_value: string;
  webform_select_limit_copy: string;
  webform_select_limit_error: string;
  webform_select_limit_disable_options: number;
}

export interface CommerceProductSKUS extends Options {
  type: string;
  title: string;
  sku: string;
  product_id: string;
  price: string;
  formatted_price: string;
  currency_code: string;
}

export interface FieldPermissionsRoleType {
  name: string;
  perms: Array<string>;
}

export interface Options {
  key: string;
  value: string | Array<Options>;
}
export interface DependentField {
  field_state: string;
  field_names: Array<string>;
  key: Array<string | boolean>;
}

export interface DependeeField {
  field_state: string;
  field_names: Array<string>;
  key: Array<string | boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private alertCtrl: AlertController,
    public message: MessagesService,
    public storage: StorageService
    ) {}

  async setupFormSchema(entityType: string, entityBundle: string, viewMode: string, extra: string, session: any): Promise<Observable<any>> {
    const headersCookie = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Cookie': session.session_name + '=' + session.sessid,
    };
    const requestOptions = {headers: new HttpHeaders(headersCookie)};
    console.log(session);
    return this.http.get(environment.FormSchemaURL + entityType + '/' + entityBundle + '/' + viewMode + '/' + session.user.uid + '/' + extra, requestOptions).pipe(
    map(data => data,
    error => {
      console.log('Error Message: ', error);
    }));
  }

  // createEntity(body, options): Observable<any> {
  //   this.message.showLoading('Submitting data...', false, 2000);
  //   return this.auth.post(node.create, body, options).pipe(
  //     map(data => {
  //       console.log(data);
  //       return data;
  //     }),
  //     catchError(err => {
  //       console.error('Error Message: ', err);
  //       return throwError(err);
  //     })
  //   );
  // }

  loadAvailableAuthors(options) {
    return this.auth.get(environment.user.indexUsers, options).toPromise();
  }

  findUser(searchTerm: string, options: any) {
    if (searchTerm) {
      return this.auth.get(environment.user.findUser + searchTerm, options).toPromise();
    }
  }

  setAuthor(entity, uid) {
    entity.author = uid;
  }

  // loadAvailableDelegates(uid: string, options: any) {
  //   return this.auth.get(environment.ApiURL + 'available-delegates/' + uid, options).subscribe(availableEntities => {
  //     return availableEntities;
  //   });
  // }
  // loadAvailableDelegatesAdmin(event: any, uid: string, options: any) {
  //   console.log(event);
  //   if (event.detail.checked !== undefined) {
  //     if (event.detail.checked === false) {
  //       this.loadAvailableDelegates(uid, options);
  //     } else {
  //       return this.auth.get(environment.ApiURL + 'available-delegates/admin', options).subscribe(availableEntities => {
  //         return availableEntities;
  //       });
  //     }
  //   }
  // }

  loadAvailableEntities(options: any, key: string, contextualFilter?: string) {
    let url = environment.ApiURL + 'available/' + key;
    if (contextualFilter) {
      url += '/' + contextualFilter;
    }
    return this.auth.get(url, options).toPromise().then(res => this.convertSelectOptions(res));
  }

  convertSelectOptions(options) {
    for (const option of options) {
      const dataToArray = option.value.split('|').map(item => item.trim());
      option.value = dataToArray.join('<br>');
    }
    return options;
  }

  createEntity(entityType: string, body: any, options: any): Observable<any> {
    this.message.showLoading('Submitting data...', false, 2000);
    console.log(entityType);
    console.log(body);
    console.log(options);
    return this.auth.post(environment[entityType].create, body, options).pipe(
      map(async (data) => {
        console.log(data);
        return data;
      }),
      catchError(err => {
        console.error('Error Message: ', err);
        return throwError(err);
      })
    );
  }

  async createFileEntity(entityType: string, body: any, options: any) {
    console.log(body);
    return this.auth.post(environment.file.create, JSON.stringify(body), options).toPromise()
    .then(res => res, err => err)
    .catch(err => {
      console.error('Error Message: ', err);
      return throwError(err);
    });
  }

  async createFileAndAttachToField(entityType: string, body: any, options: any) {
    console.log(body);
    return this.auth.post(environment.file.attach, JSON.stringify(body), options).toPromise()
    .then(res => res, err => err)
    .catch(err => {
      console.error('Error Message: ', err);
      return throwError(err);
    });
  }

  async createFieldCollectionItem(body: any, options: any): Promise<any> {
    return this.auth.post(environment.field_collection_item.create, body, options).toPromise().then(res => res).catch(err => {
      this.message.presentAlert('Error', 'There was a problem. Error Code: ' + err.status + ':' + err.statusText);
      return throwError(err);
    });
  }

  async presentUpdateFieldAlert(entityType: string, entity: any, formField: DrupalFormControlObject, field: any, inputs: Array<any>, options: any) {
    console.log(inputs);
    console.log(formField);
    const alert = await this.alertCtrl.create({
      header: formField.label,
      backdropDismiss: true,
      message: 'Enter the ' + field + ' for ' +  entity.register_first_name + ' ' + entity.register_last_name,
      inputs,
      buttons: [
        {
          text: 'Update',
          cssClass: 'primary',
          role: 'submit'
        }, {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });
    await alert.present();
    return await alert.onDidDismiss().then(async (form: any) => {
      console.log(form);
      console.log(formField);
      if (form.role === 'submit') {
        const body = {};
        body[field] = form.data.values[field];
        if (form.data.values[field] === undefined) {
          body[field] = form.data.values;
        }
        console.log(body);
        this.message.showLoading('Updating ' + formField.label + ' from ' + entity[field] + ' to ' + body[field], false, 3000);
        return await this.updateField(entityType, entity.id, body, options).toPromise().then(res => res[field]);
      }
    }, err => throwError(err));
  }

  updateField(entityType: string, id: string, field: any, options: any) {
    return this.http.put(environment[entityType].get + id, JSON.stringify(field), options)
    .pipe(map(res => {
      this.message.presentToast('Updated', 2000);
      return res;
    }),
    catchError(err => {
      console.error('Error Message: ', err);
      this.message.presentAlert('Error updating', 'There was a problem updating' + id + '. Error Code: ' + err.status + ':' + err.statusText);
      return throwError(err);
    }));
  }

  deleteEntity(entityType: string, id: string, options: any) {
    this.message.showLoading('Deleting...', true, 2000);
    return this.http.delete(environment[entityType].get + id, options).pipe(
      map(data => data,
      error => {
        console.log('error: ', error);
    }));
  }
}
export class FieldGroup {
  field_name: string;
  label: string;
  description: string;
  type: string;
  ionic_type: string;
  status: boolean;
  access: boolean;
  visible: boolean;
  fields: Array<DrupalFormControlObject>;
  constructor(name, label, description, type, ionic_type, status, access, visible, fields) {
    this.field_name = name;
    this.label = label;
    this.description = description;
    this.type = type;
    this.ionic_type = ionic_type;
    this.status = status;
    this.access = access;
    this.visible = visible;
    this.fields = fields;
  }
}
