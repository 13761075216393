import { CommonModule, formatCurrency } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { IonicModule, ModalController, Platform } from '@ionic/angular';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment.prod';
import { PaymentMethodsPage } from '../payment-methods/payment-methods.page';
import { AuthService } from '../../services/auth.service';
import { CommerceCustomerProfile, CommercePaymentTransactionStatus, CommerceService } from '../../services/commerce.service';
import { ElavonService, ElavonTransaction } from '../../services/elavon.service';
import { EventsService } from '../../services/events.service';
import { MessagesService } from '../../services/messages.service';
import { PaymentService } from '../../services/payment.service';
import { StorageService } from '../../services/storage/storage.service';
import { StripeCard, StripeCharge, StripePaymentIntent, StripeService } from '../../services/stripe.service';
import { Stripe, ApplePayEventsEnum, GooglePayEventsEnum } from '@capacitor-community/stripe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckoutFormPage } from '../checkout-form/checkout-form.page';
import { UserServiceCustom } from '../../services/user.service';
import { SystemConnection } from '../../services/drupal7/public_api';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.page.html',
  styleUrls: ['./checkout.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    CheckoutFormPage
  ],
})
export class CheckoutPage implements OnInit {

  @Input() event: any;
  @Input() webformSubmissionData: any;
  @Input() webform: any;
  orderDetails: any;
  customer: any;
  paymentMethod: any;
  paymentID: string;
  eventCost: any;
  appUser: any;
  // appUserOptions: any;


  applePayment = false;
  googlePayment = false;
  paymentIntent: StripePaymentIntent;
  devicePay = false;
  currency = 'usd';
  country = 'US';
  stripePK = '';
  stripeSK = '';

  constructor(
    public auth: AuthService,
    public user: UserServiceCustom,
    public message: MessagesService,
    private router: Router,
    public storage: StorageService,
    public eventService: EventsService,
    public elavon: ElavonService,
    public platform: Platform,
    public payment: PaymentService,
    public commerce: CommerceService,
    public modalController: ModalController,
    public stripeService: StripeService,
    public elavonService: ElavonService) {

    const nav: Navigation = this.router.getCurrentNavigation();
    if (nav?.extras && nav.extras.state) {
      this.event = nav.extras.state.event;
      this.webformSubmissionData = nav.extras.state.webformSubmission;
      this.webform = nav.extras.state.webform;
      this.customer = nav.extras.state.customer;
      this.eventCost = formatCurrency(parseFloat(this.webformSubmissionData.event_cost) / 100, 'en-US', '$');
    }
    console.log(nav.extras);
    this.getCardData();
    this.setupDevicePay();
  }

  async ngOnInit() {
    this.appUser = await this.user.getAppUser();
  }

  navigateToPage(url: string, extras: any = null) {
    this.router.navigate([url], {state: {
      event: this.event,
      webformSubmissionData: this.webformSubmissionData,
      orderDetails: this.orderDetails,
      webform: this.webform,
      result: extras
    }});
  }

  async managePaymentMethods() {
    const modal = await this.modalController.create({
      component: PaymentMethodsPage,
      cssClass: 'edit-entity fullscreen',
      componentProps: {
        event: this.event,
        webform: this.webformSubmissionData,
        customer: this.customer,
        modalMode: true
      }
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    if (res.role === 'submit') {
      this.customer = res.data.customer;
      this.getCardData();
    }
  }

  async getCardData() {
    // this.appUser = await this.storage.get('appUser');
    // if (!this.appUser) {
    //   this.appUser = await this.user.login('appUser', environment.appSettings.AppUser, false, null, null);
    //   this.appUserOptions = this.auth.setupRequestOptions('csrf', this.appUser);
    // } else {
    //   this.appUserOptions = this.auth.setupRequestOptions('csrf', this.appUser);
    // }

    const user = await this.storage.get('session');
    if (user) {
      if (!this.customer) {
        this.customer = await this.stripeService.getCardData(user);
      }
      if (this.customer?.default_source) {
        const index = this.customer.sources.data.map(source => source.id).indexOf(this.customer.default_source);
        if (index > -1) {
          this.paymentMethod = this.customer.sources.data[index];
          this.paymentID = this.customer.default_source;
        }
      } else if (this.customer?.sources?.data?.length) {
        this.paymentMethod = this.customer.sources.data[0];
        this.paymentID = this.customer.sources.data[0].id;
      }
    }
  }

  async setupDevicePay() {
    const session = await this.storage.get('session');
    const customer = session?.user.field_stripe?.und?.length ? session.user.field_stripe?.und[0].value : '';
    this.stripePK = await this.stripeService.getStripeTokenByValue(session, environment.commerce.payment_methods.stripe.stripePKKey);
    this.stripeSK = await this.stripeService.getStripeTokenByValue(session, environment.commerce.payment_methods.stripe.stripeSKKey);
    this.setupWebDevicePay();
    Stripe.initialize({publishableKey: this.stripePK});

    if (this.platform.is('ios')) {
      // Check to be able to use Apple Pay on device
      const isApplePayAvailable = await Stripe.isApplePayAvailable().then(() => true).catch(() => false);
      if (isApplePayAvailable) {
        this.applePayment = true;
        Stripe.addListener(ApplePayEventsEnum.Completed, () => {
          console.log('ApplePayEventsEnum.Completed');
        });
        const payment: StripePaymentIntent = {
          amount: +this.webformSubmissionData.event_cost,
          currency: this.currency,
          customer,
          receipt_email: session.user.mail,
          description: this.event.title,
          automatic_payment_methods: {enabled: true},
        };

        this.paymentIntent = await this.stripeService.createPaymentIntent(payment, this.stripeSK);
      }
    }

    if (this.platform.is('android')) {
      // Check to be able to use Google Pay on device
      const isGooglePayAvailable = await Stripe.isGooglePayAvailable().then(() => true).catch(() => false);
      console.log(isGooglePayAvailable);
      if (isGooglePayAvailable) {
        this.googlePayment = true;
        Stripe.addListener(GooglePayEventsEnum.Completed, () => {
          console.log('GooglePayEventsEnum.Completed');
        });
        const payment: StripePaymentIntent = {
          amount: +this.webformSubmissionData.event_cost,
          currency: this.currency,
          customer,
          receipt_email: session.user.mail,
          description: this.event.title,
          automatic_payment_methods: {enabled: true},
        };

        this.paymentIntent = await this.stripeService.createPaymentIntent(payment, this.stripeSK);
      }
    }
  }

  async setupWebDevicePay() {
    const devicePay = await loadStripe(this.stripePK, {
      apiVersion: '2022-08-01',
    });

    const paymentRequest = devicePay.paymentRequest({
      country: this.country,
      currency: this.currency,
      total: {
        label: `${environment.siteName} for ${this.event?.title}`,
        amount: +this.webformSubmissionData?.event_cost ? +this.webformSubmissionData?.event_cost : 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    const elements = devicePay.elements();
    const prButton = elements.create('paymentRequestButton', {
      paymentRequest,
    });

    (async () => {
      // Check the availability of the Payment Request API first.
      this.devicePay = true;
      const result = await paymentRequest.canMakePayment();
      if (result) {
        prButton.mount('#payment-request-button');
      } else {
        this.devicePay = false;
      }
    })();

    paymentRequest.on('paymentmethod', async (ev) => {
      const session = await this.storage.get('session');
      this.checkoutDevicePay(ev, devicePay, session, this.stripeSK);
    });
  }

  async presentApplePay() {
    try {
      await Stripe.createApplePay({
        paymentIntentClientSecret: this.paymentIntent.client_secret,
        paymentSummaryItems: [{
          label: `${environment.siteName} for ${this.event.title}`,
          amount: +this.webformSubmissionData.event_cost / 100
        }],
        merchantIdentifier: environment.commerce.payment_methods.stripe.merchantID,
        countryCode: this.country,
        currency: this.currency.toUpperCase(),
      });

      // Present Apple Pay
      const result = await Stripe.presentApplePay();
      if (result.paymentResult === ApplePayEventsEnum.Completed) {
        const chargeData = await this.stripeService.getPaymentIntent(this.paymentIntent.id, this.stripeSK);
        console.log(chargeData);
        this.checkoutDevicePaySubmit(chargeData);
      }
      if (result.paymentResult === ApplePayEventsEnum.Failed) {
        this.message.presentAlert('Your transaction has not been successful.', 'Please Check that your payment information is correct and has not expired.');
      }
    } catch (error) {
      console.error(error);
    }
  }

  async presentGooglePay() {
    try {
      await Stripe.createGooglePay({
        paymentIntentClientSecret: this.paymentIntent.client_secret,
        // Web only. Google Pay on Android App doesn't need
        paymentSummaryItems: [{
          label: `${environment.siteName} for ${this.event.title}`,
          amount: +this.webformSubmissionData.event_cost / 100
        }],
        merchantIdentifier: environment.commerce.payment_methods.stripe.merchantID,
        countryCode: this.country,
        currency: this.currency.toUpperCase(),
      });

      // Present Google Pay
      const result = await Stripe.presentGooglePay();
      if (result.paymentResult === GooglePayEventsEnum.Completed) {
        const chargeData = await this.stripeService.getPaymentIntent(this.paymentIntent.id, this.stripeSK);
        this.message.showLoading('Submitting your payment...', false, 1500);
        this.checkoutDevicePaySubmit(chargeData);
      }
      if (result.paymentResult === GooglePayEventsEnum.Failed) {
        this.message.presentAlert('Your transaction has not been successful.', 'Please Check that your payment information is correct and has not expired.');
      }
    } catch (error) {
      console.error(error);
    }
  }

  setPaymentMethod(source: any) {
    this.paymentMethod = source;
  }

  async checkoutDevicePay(ev: any, devicePay: any, session: SystemConnection, stripeSK: string) {
      const customer = session?.user.field_stripe?.und?.length ? session.user.field_stripe?.und[0].value : '';
      const payment: StripePaymentIntent = {
        amount: +this.webformSubmissionData.event_cost,
        currency: this.currency,
        customer,
        receipt_email: session.user.mail,
        description: this.event.title,
        automatic_payment_methods: {enabled: true},
      };
      const newPaymentIntent = await this.stripeService.createPaymentIntent(payment, stripeSK);
      const clientSecret = newPaymentIntent.client_secret;

      const {paymentIntent, error: confirmError} = await devicePay.confirmCardPayment(
        clientSecret,
        {payment_method: ev.paymentMethod.id,
        receipt_email: session.user.mail},
        {handleActions: false}
      );

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');
        this.message.presentAlert('Your transaction has not been successful.', 'Please Check that your payment information is correct and has not expired.');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          const {error} = await devicePay.confirmCardPayment(clientSecret);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
            this.message.presentAlert('Your transaction has not been successful.', 'Please Check that your payment information is correct and has not expired.');
          } else {
            // The payment has succeeded.
            const chargeData = await this.stripeService.getPaymentIntent(paymentIntent.id, stripeSK);
            this.checkoutDevicePaySubmit(chargeData);
          }
        } else {
          // The payment has succeeded.
          const chargeData = await this.stripeService.getPaymentIntent(paymentIntent.id, stripeSK);
          console.log(chargeData);
          this.checkoutDevicePaySubmit(chargeData);
        }
      }
    }

  async checkoutDevicePaySubmit(chargeData: any) {
    this.message.showLoading('Submitting Your Registration Data...', false, 1000);
    const session = await this.storage.get('session');
    const options = this.auth.setupRequestOptions('csrf', session);
    const appUser = await this.user.getAppUser();
    const appUserOptions = this.auth.setupRequestOptions('csrf', appUser);
    this.eventService.createWebformSubmission(this.webformSubmissionData, options).then(async (res) => {
      if (res) {
        // Initialize the Drupal Payment Transaction from the Charge Data
        const drupalTransaction = {
          payment_method: environment.commerce.payment_methods.church_app.id,
          status: CommercePaymentTransactionStatus.success,
          uid: session.user.uid,
          order_id: 0,
          amount: this.webformSubmissionData.event_cost,
          currency_code: this.currency.toUpperCase(),
          message: JSON.stringify(chargeData),
          remote_id: chargeData.id,
          remote_status: chargeData.status,
          created: new Date().valueOf().toString().substring(0, 10)
        };
        let productfieldValue: any;
        let values = [];
        values = Object.keys(res.data).map(key => {
          values = res.data[key];
          return values;
        });

        for (const value of values) {
          if (value.type === 'productfield') {
              if (value.values.length === 1) {
              productfieldValue = JSON.parse(value.values);
              if (productfieldValue.order_id !== 'FALSE') {
                drupalTransaction.order_id = productfieldValue.order_id;
              }
            } else {
              for (const product of value.values) {
                const productJSON = JSON.parse(product);
                if (productJSON !== 'FALSE') {
                  drupalTransaction.order_id = productJSON.order_id;
                }
              }
            }
          }
        }
        // Get the billing details from the charge
        const chargedBillingDetails = chargeData.charges.data[0].billing_details;
        // Setup the billing profile for Drupal
        const billingDetails = new CommerceCustomerProfile(environment.appSettings.AppUserUID);
        billingDetails.commerce_customer_address.country = chargedBillingDetails.address.country;
        billingDetails.commerce_customer_address.locality = chargedBillingDetails.address.city;
        billingDetails.commerce_customer_address.thoroughfare = chargedBillingDetails.address.line1.includes('\n') ? chargedBillingDetails.address.line1.split('\n')[0] : chargedBillingDetails.address.line1;
        billingDetails.commerce_customer_address.premise = chargedBillingDetails.address.line1.includes('\n') && !chargedBillingDetails.address.line2 ? chargedBillingDetails.address.line1.split('\n')[1] : chargedBillingDetails.address.line2;
        billingDetails.commerce_customer_address.postal_code = chargedBillingDetails.address.postal_code;
        billingDetails.commerce_customer_address.administrative_area = chargedBillingDetails.address.state;
        billingDetails.commerce_customer_address.name_line = chargedBillingDetails.name;

        // Create the Commerce Customer Profile on Drupal
        this.commerce.createCommerceCustomerProfile(billingDetails, appUserOptions, environment.commerce.commerce_customer_profile.entityType, environment.commerce.commerce_customer_profile.entityBundle).subscribe(customerProfile => {
          const order_id = drupalTransaction.order_id;

          const body = {
            mail: session.user.mail,
            commerce_customer_billing: customerProfile.profile_id
          };

          // Attach the new Commerce Customer Profile to the Commerce Order
          this.commerce.updateCommerceOrder(order_id, body, appUserOptions).subscribe();
        }, err => err);

        // Create the payment transaction on Drupal
        await this.payment.createDrupalPayment(drupalTransaction, appUser, false).toPromise();

        this.message.presentAlert('Registration Complete', 'Looking forward to seeing you at ' + this.event.title);
        this.orderDetails = chargeData;
        this.navigateToPage('confirmation');
      } else {
        this.message.presentAlert('Sorry', 'We had an issue registering you for ' + this.event.title + ' please try again.');
      }
    }, err => {
      this.message.presentAlert('Fail', err);
    });
  }

  async checkoutManual(cardDetails: StripeCard) {
    this.message.showLoading('Preparing to submit...', false, 1500);
    const customer = await this.storage.get('session');
    const stripeKey = await this.stripeService.getStripeTokenByValue(customer, environment.commerce.payment_methods.stripe.stripePKKey);
    cardDetails.address_country = this.country;
    const token = await this.stripeService.createCardToken(cardDetails, stripeKey);
    if (token) {
      this.paymentMethod = token;
      this.checkout();
    }
  }

  async checkout() {
    this.message.showLoading('Submitting Your Registration Data...', false, 1000);
    const sessionD7 = await this.storage.get('session');
    const options = this.auth.setupRequestOptions('csrf', sessionD7);
    const appUser = await this.user.getAppUser();
    const appUserOptions = this.auth.setupRequestOptions('csrf', appUser);

    const charge: StripeCharge = {
      amount: this.webformSubmissionData.event_cost,
      currency: this.currency,
      customer: sessionD7.user.field_stripe?.und?.length ? sessionD7.user.field_stripe?.und[0].value : '',
      source: this.paymentMethod.id,
      description: this.event.title,
      receipt_email: sessionD7.user.mail,
      statement_descriptor: 'LBC - ' + this.event.title.substring(0,15)
    };

    if (sessionD7.user.uid) {
      const stripeToken = await this.stripeService.getStripeTokenByValue(sessionD7, environment.commerce.payment_methods.stripe.stripeSKKey);
      const chargeData = await this.stripeService.createCharge(charge, stripeToken);
        if (chargeData?.status !== 'failed') {
            this.eventService.createWebformSubmission(this.webformSubmissionData, options).then(res => {
              if (res) {
                const drupalTransaction = {
                  payment_method: environment.commerce.payment_methods.church_app.id,
                  status: CommercePaymentTransactionStatus.success,
                  uid: sessionD7.user.uid ? sessionD7.user.uid : environment.appSettings.AppUserUID,
                  order_id: 0,
                  amount: this.webformSubmissionData.event_cost,
                  currency_code: this.currency.toUpperCase(),
                  message: JSON.stringify(chargeData),
                  remote_id: chargeData.id,
                  remote_status: chargeData.status,
                  created: new Date().valueOf().toString().substring(0, 10)
                };
                let productfieldValue: any;
                let values = [];
                values = Object.keys(res.data).map(key => {
                  values = res.data[key];
                  return values;
                });

                for (const value of values) {
                  if (value.type === 'productfield') {
                      if (value.values.length === 1) {
                      productfieldValue = JSON.parse(value.values);
                      if (productfieldValue.order_id !== 'FALSE') {
                        drupalTransaction.order_id = productfieldValue.order_id;
                      }
                    } else {
                      for (const product of value.values) {
                        const productJSON = JSON.parse(product);
                        if (productJSON !== 'FALSE') {
                          drupalTransaction.order_id = productJSON.order_id;
                        }
                      }
                    }
                  }
                }

                // Setup the billing profile for Drupal
                const billingDetails = new CommerceCustomerProfile(environment.appSettings.AppUserUID);
                billingDetails.commerce_customer_address.country = chargeData.billing_details.address.country;
                billingDetails.commerce_customer_address.locality = chargeData.billing_details.address.city;
                billingDetails.commerce_customer_address.thoroughfare = chargeData.billing_details.address.line1.includes('\n') ? chargeData.billing_details.address.line1.split('\n')[0] : chargeData.billing_details.address.line1;
                billingDetails.commerce_customer_address.premise = chargeData.billing_details.address.line1.includes('\n') && !chargeData.billing_details.address.line2 ? chargeData.billing_details.address.line1.split('\n')[1] : chargeData.billing_details.address.line2;
                billingDetails.commerce_customer_address.postal_code = chargeData.billing_details.address.postal_code;
                billingDetails.commerce_customer_address.administrative_area = chargeData.billing_details.address.state;
                billingDetails.commerce_customer_address.name_line = chargeData.billing_details.name;

                this.commerce.createCommerceCustomerProfile(billingDetails, appUserOptions, environment.commerce.commerce_customer_profile.entityType, environment.commerce.commerce_customer_profile.entityBundle).subscribe(customerProfile => {
                  const order_id = drupalTransaction.order_id;

                  const body = {
                    mail: sessionD7.user.mail,
                    commerce_customer_billing: customerProfile.profile_id
                  };

                  this.commerce.updateCommerceOrder(order_id, body, appUserOptions).subscribe();
                });

                this.payment.createDrupalPayment(drupalTransaction, appUser, false).subscribe(payment => payment);

                this.message.presentAlert('Registration Complete', 'Looking forward to seeing you at ' + this.event.title);
                this.orderDetails = chargeData;
                this.navigateToPage('confirmation');
              } else {
                this.message.presentAlert('Sorry', 'We had an issue registering you for ' + this.event.title + ' please try again.');
              }
            }, err => {
              this.message.presentAlert('Fail', err);
            });
        } else {
          this.message.presentAlert('Your transaction has not been successful.', 'Please Check that your payment information is correct and has not expired.');
        }
    }
}

checkoutElavaon(drupalTransaction: any, session: SystemConnection) {
  // This is if we are going to process with Elavon
  const cardDetails: any = {};
  const payment: any = {};
  this.elavon.elavonPaymentTransactionProcess(drupalTransaction, cardDetails, payment, session, session).subscribe(async (transaction: ElavonTransaction) => transaction);
}

}
