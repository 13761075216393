import { Component, Input, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { BiometryType } from 'capacitor-native-biometric';
import { MessagesService } from '../services/messages.service';
import { StorageService } from '../services/storage/storage.service';
import { CommonModule } from '@angular/common';
import { UserServiceCustom } from '../services/user.service';

@Component({
  selector: 'app-login-options',
  templateUrl: './login-options.component.html',
  styleUrls: ['./login-options.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class LoginOptionsComponent implements OnInit {

  @Input() biometricType: BiometryType;
  hasBiometricCredentials = false;
  loginOptions = [{
    key: BiometryType.TOUCH_ID,
    label: 'Login with Touch ID',
    checked: false,
    hidden: true,
    platform: 'ios'
  }, {
    key: BiometryType.FACE_ID,
    label: 'Login with Face ID',
    checked: false,
    hidden: true,
    platform: 'ios'
  }, {
    key: BiometryType.FINGERPRINT,
    label: 'Login with Fingerprint',
    checked: false,
    hidden: true,
    platform: 'android'
  }, {
    key: BiometryType.FACE_AUTHENTICATION,
    label: 'Login with Face Authentication',
    checked: false,
    hidden: true,
    platform: 'android'

  }, {
    key: BiometryType.IRIS_AUTHENTICATION,
    label: 'Login with Iris ID',
    checked: false,
    hidden: true,
    platform: 'android'
  }];

  constructor(
    private storage: StorageService,
    private messages: MessagesService,
    private modalController: ModalController,
    private user: UserServiceCustom
    ) { }

  ngOnInit() {
    this.setupLoginOptions();
  }

  async checkCredentials() {
    this.hasBiometricCredentials = await this.user.biometricHasCredentials();
    if (!this.hasBiometricCredentials) {
      const validatedUser = await this.user.biometricAskCredentials(this.biometricType);
      if (validatedUser?.status === 401) {
        this.messages.presentAlert('Error', validatedUser.statusText);
      }
      return this.hasBiometricCredentials = await this.user.biometricHasCredentials();
    }
    return this.hasBiometricCredentials;
  }

  async setupLoginOptions() {
    await this.checkCredentials();
      if (this.hasBiometricCredentials && this.biometricType) {
        this.loginOptions.map(o => o.key === this.biometricType ? o.hidden = false : o.hidden = true);
        if (this.biometricType === BiometryType.MULTIPLE) {
          this.loginOptions.map(o => {
            if (o.platform === 'android') {
              o.hidden = false;
            }
          });
        }
      }
      if (this.hasBiometricCredentials && this.biometricType !== BiometryType.MULTIPLE) {
        this.storage.get(`login_BiometryType_${this.biometricType}`).then(res => {
          if (res !== null) {
            this.loginOptions.map(o => {
              if (o.key === this.biometricType) {
                o.checked = res;
              }
            });
          }
        });
      }
      if (this.hasBiometricCredentials && this.biometricType === BiometryType.MULTIPLE) {
        this.loginOptions.map(o => {
          if (o.platform === 'android') {
            this.storage.get(`login_BiometryType_${o.key}`).then(res => {
              if (res !== null) {
                o.checked = res;
              }
            });
          }
        });
      }
  }

  storeLoginOption(key: string, e: any) {
    this.storage.set('login_BiometryType_'+ key, e.srcElement.checked);
  }

  biometricDeleteCredentials() {
    this.user.biometricDeleteCredentials().then(async (res) => {
      if (res) {
        this.hasBiometricCredentials = await this.user.biometricHasCredentials();
        this.storage.clearBiometrics();
      }
    });
  }

  closeModal() {
    this.modalController.dismiss(null, 'cancel');
  }


}
