import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment.prod';
import { HttpHeaders } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { MessagesService } from '../services/messages.service';
import BibleBooks from '../../assets/form-filters/bible_books.json';
import { format, getDayOfYear, parseISO } from 'date-fns';
import { StorageService } from '../services/storage/storage.service';
import { IonModal, IonRange, IonicModule, ModalController } from '@ionic/angular';
import { MediaPlayerComponent } from '../media-player/media-player.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-media',
  templateUrl: 'media.page.html',
  styleUrls: ['media.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
  ],
})
export class MediaPage {
  @ViewChild('range', {static: false}) range: IonRange;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  @ViewChild(IonModal) modal: IonModal;

  progress = 0;
  isPlaying = false;
  isTouched = false;
  openModal = false;
  currSecsText;
  durationText;
  currRangeTime;
  maxRangeValue;
  mediaType = 'audio';
  currAudio: HTMLAudioElement = new Audio('');

  currTitle;
  currSpeaker;
  currImg;
  currVideoSource;
  upNextImg;
  upNextTitle;
  upNextSpeaker;



  sermons: Sermon[];
  speakers: any;
  allSeries: any;
  books = BibleBooks;
  input = null;
  speaker = null;
  book = null;
  dateValue = new Date().toISOString();
  series = null;
  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  selectedFilter: string = null;

  constructor(public router: Router,
    public auth: AuthService,
    public modalController: ModalController,
    private storage: StorageService,
    public messages: MessagesService) {
      this.storage.get('sermons').then(res => {
        this.storage.get('speakers').then(speakers => {
          this.storage.get('allSeries').then(series => {
            this.sermons = res;
            this.speakers = speakers;
            this.allSeries = series;
            if (!this.sermons || !this.speakers || !this.allSeries) {
              this.getData();
            }
          });
        });
      });
      const dayOfWeek = Number(format(new Date().valueOf(), 'i'));
      if (dayOfWeek === 1 || dayOfWeek === 4) {
        this.getData(false);
      }
    }

  segmentChanged(val: string) {
    this.selectedFilter = val;
  }

  search() {
    let message = '';
    let url = '';
    switch (this.selectedFilter) {
      case 'input':
        if (this.input) {
          message = 'Searching for message titles that contain ' + this.input;
          url = `${environment.churchURLS.SermonUrl}?id=${this.input}`;
          this.messages.showLoading(message, false, 1500);
          this.auth.get(url, this.options).subscribe(res => {
            console.log(res);
            for (const sermon of res) {
              sermon.embedded_video = this.getVideoEmbeds(sermon);
              if (sermon.tags) {
                sermon.tags = sermon.tags.split(',');
              }
            }
            this.sermons = res;
          });
        }
        break;
      case 'series':
        if (this.series) {
          message = this.series !== 'all' ? 'Searching for messages in the series ' + this.series : 'Showing all series';
          url = `${environment.churchURLS.SermonUrl}?series=${this.series}`;
          this.messages.showLoading(message, false, 1500);
          this.auth.get(url, this.options).subscribe(res => {
            console.log(res);
            for (const sermon of res) {
              sermon.embedded_video = this.getVideoEmbeds(sermon);
              if (sermon.tags) {
                sermon.tags = sermon.tags.split(',');
              }
            }
            this.sermons = res;
          });
        }
        break;
      case 'speaker':
        if (this.speaker) {
          const currentSpeaker = this.speakers.filter(x => x.key === this.speaker)[0];
          message = this.speaker !== 'all' ? 'Searching for messages by ' + currentSpeaker.value : 'Showing all speakers';
           url = `${environment.churchURLS.SermonUrl}all/${this.speaker}`;
           this.messages.showLoading(message, false, 1500);
           this.auth.get(url, this.options).subscribe(res => {
             console.log(res);
             for (const sermon of res) {
              sermon.embedded_video = this.getVideoEmbeds(sermon);
              if (sermon.tags) {
                sermon.tags = sermon.tags.split(',');
              }
            }
            this.sermons = res;
           });
        }
        break;
      case 'book':
        if (this.book) {
          const currentBook = this.books.filter(x => x.key === this.book)[0];
          message = this.book !== 'all' ? 'Searching for messages from the book of ' + currentBook.value : 'Showing all speakers';
          url = `${environment.churchURLS.SermonUrl}all/all/${this.book}`;
          this.messages.showLoading(message, false, 1500);
          this.auth.get(url, this.options).subscribe(res => {
            console.log(res);
            for (const sermon of res) {
              sermon.embedded_video = this.getVideoEmbeds(sermon);
              if (sermon.tags) {
                sermon.tags = sermon.tags.split(',');
              }
            }
            this.sermons = res;
          });
        }
        break;
      case 'date':
        if (this.dateValue) {
          const date = format(parseISO(this.dateValue), 'EEEE, MMMM dd, yyyy');
          message = 'Searching for messages preached on ' + date;
          url = `${environment.churchURLS.SermonUrl}all/all/all/${this.dateValue}`;
          this.messages.showLoading(message, false, 1500);
          this.auth.get(url, this.options).subscribe(res => {
            console.log(res);
            for (const sermon of res) {
              sermon.embedded_video = this.getVideoEmbeds(sermon);
              if (sermon.tags) {
                sermon.tags = sermon.tags.split(',');
              }
            }
            this.sermons = res;
          });
        }
        break;
      default:
        break;
    }
  }

  resetSearch() {
    this.input = null;
    this.series = null;
    this.speaker = null;
    this.book = null;
    this.dateValue = new Date().toISOString();
    this.getData(false);
  }

  getData(showLoading: boolean = true, event: any = null) {
    if (showLoading) {
      this.messages.showLoading('Loading the sermons...', false, 1500);
    }
    this.auth.get(environment.churchURLS.SermonUrl, this.options).subscribe((data: Sermon[]) => {
      for (const sermon of data) {
        sermon.embedded_video = this.getVideoEmbeds(sermon);
        // this.getSoundCloudEmbed(sermon);
        if (sermon.tags) {
          sermon.tags = sermon.tags.split(',');
        }
      }
      this.sermons = data;
      this.storage.set('sermons', this.sermons);
    });
    this.auth.get(environment.churchURLS.Speakers, this.options).subscribe(res => {
      this.speakers = res;
      this.storage.set('speakers', this.speakers);
    });
    this.auth.get(environment.churchURLS.SermonSeries, this.options).subscribe(res => {
      this.allSeries = res;
      this.storage.set('allSeries', this.allSeries);
    });
    if (event) {
      setTimeout(() => {
        console.log('Async operation has ended');
        event.target.complete();
      }, 2000);
    }
  }

  getVideoEmbeds(sermon: Sermon) {
    if (sermon.video) {
      if (sermon.video.includes('v=')) {
        sermon.youtube_video_id = sermon.video.split('v=')[1].split('&')[0];
        return `https://www.youtube.com/embed/${sermon.video.split('v=')[1].split('&')[0]}`;
      }
      if (sermon.video.includes('tu.be/')) {
        sermon.youtube_video_id = sermon.video.split('tu.be/')[1];
        return `https://www.youtube.com/embed/${sermon.video.split('tu.be/')[1]}`;
      }
    }
    if (sermon.sermon_video_vimeo) {
      return sermon.sermon_video_vimeo;
    }
  }

  getSoundCloudEmbed(sermon: Sermon) {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };
    const data = `format=json&url=${sermon.audio}`;
    return this.auth.post(`https://soundcloud.com/oembed/`, data, options).toPromise().then(res => {
      sermon.embedded_soundcloud = res;
    }, err => err);
  }

  lbcLiveLink() {
    Browser.open({url: 'https://lancasterbaptist.online.church'});
  }

  async playMediaModal(sermon: Sermon) {
    const index = this.sermons.findIndex(x => x.title === sermon.title);
    const nextSermon = index + 1 === this.sermons.length ? this.sermons[0] : this.sermons[index +1];
    const prevSermon = index === 0 ? this.sermons[this.sermons.length - 1] : this.sermons[index - 1];

    const modal = await this.modalController.create({
      component: MediaPlayerComponent,
      cssClass: 'media-player',
      canDismiss: true,
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      componentProps: {
        sermon,
        sermons: this.sermons,
        nextSermon,
        prevSermon
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data !== undefined) {
      console.log(data);
      console.log(sermon);
    }
  }

  navigateToPage(sermon: Sermon) {
    this.router.navigate(['media/' + sermon.nid], {state: {
        sermon,
        sermons: this.sermons
    }});
  }

  navigateToMediaPlayer(sermon: Sermon) {
    this.router.navigate(['media-player'], {state: {
        sermon,
        sermons: this.sermons
    }});
  }


}

export interface Sermon {
  sermon_thumbnail: string;
  sermon_image: string;
  title: string;
  sermon_speaker: string;
  sermon_date: string;
  sermon_series: string;
  outline: string;
  bible_reference: string;
  video: string;
  video_fallback: string;
  audio: string;
  audio_fallback: string;
  nid: string;
  path: string;
  sermon_series_id: string;
  sermon_video_vimeo?: string;
  sermon_transcript?: string;
  transcript_file?: string;
  sermon_video_fallback?: string;
  tags?: any;
  sermon_type?: string;
  youtube_video?: string;
  youtube_video_id?: string;
  youtube_video_embed?: any;
  embedded_video?: string;
  embedded_soundcloud?: any;
}
