
import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { DrupalConstants } from '../application/drupal-constants';
import { SystemConnection } from '../models';


/**
 * system service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class SystemService extends MainService {
  override entityType = 'system';


  /**
   * if there is already a token in the browser cookies and it is not expired it will return it
   * this will fetch a new token before trying to connect
   *
   * @param refreshToken boolean to force the application to request new token
   * @return promise of the connect method
   * the promise data is an object of SystemConnection interface
   */
  async connect(refreshToken: boolean = false): Promise<SystemConnection> {
    if (await this.isConnectionExpired()) {
      this.removeSession();
    }
  
    if (!DrupalConstants.Connection || !DrupalConstants.Connection.token || refreshToken) {

      const connection = await this.post({}, 'connect');
      const token = await this.getSessionToken();
      connection.token = token ? token : '';
      this.saveSessionToken(connection);
      return connection;
    }
  
    const connection = await this.post({}, 'connect');
    this.saveSessionToken(connection);
    return connection;
  }
  

  /**
   * implement get_variable resource
   *
   * @param variableName the name of the variable
   * @return the value of the variable
   */
  getVariable(variableName: string): Promise<any[]> {
    return this.post({ name: variableName }, 'get_variable');
  }

  /**
   * implement set_variable resource
   *
   * @param variableName the name of the variable
   * @param value the value to set for the variable
   * @return always null, take care of overriding old variables with same name
   */
  setVariable(variableName: string, value: any): Promise<null> {
    const variable = {
      name: variableName,
      value
    };
    return this.post(variable, 'set_variable');
  }

  /**
   * implement del_variable resource
   *
   * @param variableName variable name to delete
   * @return null if variable found or not.
   */
  delVariable(variableName: string): Promise<null> {
    return this.post({ name: variableName }, 'del_variable');
  }
}
