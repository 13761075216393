import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { CreateWebformSubmission, WebformSubmission} from '../models';

/**
 * webform submission service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class WebformSubmissionService extends MainService {
  override entityType = 'submission';

  /**
   * implement retrieve resource
   *
   * @param uuid uuid of the submission to get
   * @return object of the submission entity
   */
  getWebformSubmissionsByUuid(uuid: string): Promise<WebformSubmission> {
    return this.get(uuid);
  }

  /**
   * implement create resource
   *
   * @param webform submission entity to create
   * @return object of the created submission details
   */
  createWebformSubmission(submission: CreateWebformSubmission): Promise<WebformSubmission> {
    return this.post(submission);
  }

  /**
   * implement update resource
   *
   * @param submission submission to update
   * @return object of the updated submission details
   */
  updateWebformSubmission(submission: {uuid: string}): Promise<WebformSubmission> {
    return this.put(submission, submission.uuid);
  }

  /**
   * implement delete resource
   *
   * @param uuid uuid to delete
   * @return array true if submission deleted, otherwise return false array
   */
  deleteWebformSubmission(uuid: string): Promise<boolean[]> {
    return this.delete(uuid);
  }


}
